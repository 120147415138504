import { Checkbox, ICheckboxState } from 'common-front/src/designSystem/components/checkbox';
import { closeOtherDropdowns } from 'common-front/src/designSystem/components/dropdown/dropdown';
import { Tooltip } from 'common-front/src/designSystem/components/tooltip/tooltip';
import { Trigger } from 'common-front/src/designSystem/components/tooltip/trigger';
import { useSlugToCustomField } from 'common-front/src/hooks/useSlugToCustomField';
import { Box } from 'common/src/designSystem/components/box';
import { I } from 'common/src/designSystem/components/i';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { CellControls } from 'common/src/designSystem/components/table/cellControls';
import { Row } from 'common/src/designSystem/components/table/row';
import {
    DelegationId,
    DelegationsDelegationFragment,
    EventId,
    OrganizationId,
    SegmentCustomFieldFragment
} from 'common/src/generated/types';
import { Emptyable } from 'common/src/util/emptyable';
import { PossibleColumn } from 'common/src/vo/segment';
import * as React from 'react';
import { DelegationCells } from './delegationCells';
import { DelegationRowDropdown } from './delegationRowDropdown';
import { useDelegationsContext } from './delegationsContext';

interface IDelegationRowProps {
    columns: PossibleColumn[];
    customFields: SegmentCustomFieldFragment[];
    delegation: DelegationsDelegationFragment;
    eventId: Emptyable<EventId>;
    organizationId: OrganizationId;
    state: ICheckboxState;

    reload(): void;
    reloadDelegation(id: DelegationId): void;
    toggleRow(key: string, newState: ICheckboxState): void;
}

export const DelegationRow = ({
    columns,
    customFields,
    delegation,
    eventId,
    organizationId,
    state,
    reload,
    reloadDelegation: reloadDelegationProps,
    toggleRow
}: IDelegationRowProps) => {
    const { getEditPath } = useDelegationsContext();
    const slugToCustomField = useSlugToCustomField(customFields);
    const reloadDelegation = React.useCallback(() => {
        reloadDelegationProps(delegation.id);
    }, [delegation, reloadDelegationProps]);

    return (
        <Row
            css={{
                '&:hover': {
                    background: '$gray100'
                }
            }}
            onMouseLeave={(e) => {
                closeOtherDropdowns(e.target);
            }}
        >
            <Cell justify="center" width={48}>
                <Checkbox
                    state={state}
                    onClick={(newState, e) => {
                        e.nativeEvent.stopImmediatePropagation();
                        e.stopPropagation();

                        toggleRow(`d${delegation.id}`, newState);
                    }}
                />
            </Cell>

            {columns.map((field) => {
                const customField = slugToCustomField.get(field.slug);

                return (
                    <DelegationCells
                        key={field.slug}
                        column={field}
                        customField={customField}
                        eventId={eventId}
                        organizationId={organizationId}
                        reloadRow={reloadDelegation}
                        row={delegation}
                    />
                );
            })}
            <CellControls justify="center">
                <Tooltip>
                    <Trigger>
                        <Box>
                            <I icon="pen" to={getEditPath(delegation.id)} />
                        </Box>
                    </Trigger>
                </Tooltip>

                <DelegationRowDropdown
                    delegation={delegation}
                    eventId={eventId}
                    organizationId={organizationId}
                    reload={reload}
                />
            </CellControls>
        </Row>
    );
};
