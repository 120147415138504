import { MobileOverlay } from 'common-front/src/components/mobileOverlay/mobileOverlay';
import { Tab } from 'common-front/src/designSystem/components/tabs/tab';
import { TabList } from 'common-front/src/designSystem/components/tabs/tabList';
import { TabPanel } from 'common-front/src/designSystem/components/tabs/tabPanel';
import { Tabs } from 'common-front/src/designSystem/components/tabs/tabs';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { useTitle } from 'common-front/src/hooks/useTitle';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import {
    AccreditationState,
    Delegation,
    Feature,
    UserInfoDocumentLink,
    UserInfoOverlayFragment,
    UserOverlayEventQuery,
    VolunteerRegistrationState
} from 'common/src/generated/types';
import { useParams } from 'common/src/util/dependencies/dependencies';
import { Emptyable } from 'common/src/util/emptyable';
import { commonSlugs } from 'common/src/util/paths/commonPaths';
import { UserInfoIdPathParam } from 'common/src/util/pathsTypes';
import * as React from 'react';
import { createPortal } from 'react-dom';
import { useEventContext } from '../../events/show/eventContext';
import {
    useUserOverlayEventQuery,
    useUserOverlayOrganizationQuery
} from '../../generated/graphqlHooks';
import { useOrganizationContext } from '../../organization/organizationContext';
import { Comments } from '../show/comments/comments';
import { UserOverlayAccreditations } from './accreditations/userOverlayAccreditations';
import { UserOverlayActionsEvent } from './actions/userOverlayActionsEvent';
import { UserOverlayActionsOrganization } from './actions/userOverlayActionsOrganization';
import { UserOverlayCampaigns } from './campaigns/userOverlayCampaigns';
import { UserOverlayEvents } from './events/userOverlayEvents';
import {
    UserOverlayInformationsEvent,
    UserOverlayInformationsOrganization
} from './informations/userOverlayInformations';
import { UserOverlayIntegrations } from './integrations/userOverlayIntegrations';
import { UserOverlayPositions } from './positions/userOverlayPositions';
import { UserOverlaySlots } from './slots/userOverlaySlots';
import { UserOverlayHeader } from './userOverlayHeader';
import { UserOverlayHeaderSkeleton } from './userOverlayHeaderSkeleton';

interface IUserOverlayProps {
    accreditationState?: AccreditationState;
    canEditEmail: boolean;
    customDocuments: UserOverlayEventQuery['event']['customDocuments'];
    delegation?: Emptyable<Pick<Delegation, 'id' | 'name'>>;
    deletePath?: string;
    isEvent: boolean;
    isLoading: boolean;
    isWeezeventConnected: boolean;
    numberOfComments: number;
    links: Emptyable<UserInfoDocumentLink>;
    showAccreditations: boolean;
    showActions: boolean;
    showComments: boolean;
    state?: VolunteerRegistrationState;
    userInfo: UserInfoOverlayFragment;

    getBasePath(userInfoId: UserInfoIdPathParam, isRouteComponent?: boolean): string;
    reload(): void;
}

const UserOverlay = (props: IUserOverlayProps) => {
    const {
        params: { organizationId, eventId, userInfoId },
        translate
    } = useHeavent();
    useTitle(props.userInfo?.nameOrEmail);
    const baseRoutePath = React.useMemo(
        () => props.getBasePath(':userInfoId', true),
        [props.getBasePath]
    );
    const basePath = React.useMemo(
        () => props.getBasePath(userInfoId),
        [userInfoId, props.getBasePath]
    );

    return (
        <MobileOverlay>
            <Flex css={{ margin: 'auto' }} direction="column" width="$container">
                {props.isLoading ? (
                    <UserOverlayHeaderSkeleton />
                ) : (
                    <UserOverlayHeader
                        canEditEmail={props.canEditEmail}
                        eventId={eventId}
                        organizationId={organizationId}
                        reload={props.reload}
                        state={props.state}
                        userInfo={props.userInfo}
                    />
                )}

                <Spacer height="7" />

                {!props.isLoading && (
                    <Tabs>
                        <TabList center={true}>
                            <Tab path={`${basePath}/${commonSlugs.SECTIONS.INFORMATION}`}>
                                {translate('informations_22954')}
                            </Tab>

                            {!props.isEvent && (
                                <Tab path={`${basePath}/events`}>
                                    {translate('_v_nements_53672')}
                                </Tab>
                            )}

                            {eventId && (
                                <Tab path={`${basePath}/slots`}>
                                    {translate('disponibilit_s_49923')}
                                </Tab>
                            )}

                            {eventId && (
                                <Tab path={`${basePath}/positions`}>
                                    {translate('missions_63972')}
                                </Tab>
                            )}

                            {props.showAccreditations && (
                                <Tab path={`${basePath}/accreditations`}>
                                    {translate('accr_ditations_39450')}
                                </Tab>
                            )}

                            {eventId && props.isWeezeventConnected && (
                                <Tab path={`${basePath}/integrations`}>
                                    {translate('int_grations_88619')}
                                </Tab>
                            )}

                            {props.showActions && (
                                <Tab path={`${basePath}/actions`}>{translate('actions_21689')}</Tab>
                            )}

                            {props.showComments && (
                                <Tab path={`${basePath}/comments`}>
                                    {translate('commentaires_97857', props.numberOfComments)}
                                </Tab>
                            )}

                            <Tab path={`${basePath}/campaigns`}>{translate('campagnes_31372')}</Tab>

                            {eventId && (
                                <Tab path={`${basePath}/events`}>
                                    {translate('autres_v_nemen_48137')}
                                </Tab>
                            )}
                        </TabList>

                        <TabPanel path={`${baseRoutePath}/${commonSlugs.SECTIONS.INFORMATION}`}>
                            {props.isEvent ? (
                                <UserOverlayInformationsEvent userInfo={props.userInfo} />
                            ) : (
                                <UserOverlayInformationsOrganization userInfo={props.userInfo} />
                            )}
                        </TabPanel>

                        {!props.isEvent && (
                            <TabPanel path={`${baseRoutePath}/events`}>
                                <UserOverlayEvents />
                            </TabPanel>
                        )}

                        {eventId && (
                            <TabPanel path={`${baseRoutePath}/slots`}>
                                <UserOverlaySlots />
                            </TabPanel>
                        )}

                        {eventId && (
                            <TabPanel path={`${baseRoutePath}/positions`}>
                                <UserOverlayPositions />
                            </TabPanel>
                        )}

                        {props.showAccreditations && (
                            <TabPanel path={`${baseRoutePath}/accreditations`}>
                                <UserOverlayAccreditations />
                            </TabPanel>
                        )}

                        {eventId && props.isWeezeventConnected && (
                            <TabPanel path={`${baseRoutePath}/integrations`}>
                                <UserOverlayIntegrations />
                            </TabPanel>
                        )}

                        {props.showActions && (
                            <TabPanel path={`${baseRoutePath}/actions`}>
                                {props.isEvent ? (
                                    <UserOverlayActionsEvent
                                        accreditationState={props.accreditationState!}
                                        customDocuments={props.customDocuments}
                                        delegation={props.delegation}
                                        deletePath={props.deletePath}
                                        eventId={eventId}
                                        isWeezeventConnected={props.isWeezeventConnected}
                                        links={props.links}
                                        organizationId={organizationId}
                                        reload={props.reload}
                                        state={props.state!}
                                        userInfo={props.userInfo}
                                    />
                                ) : (
                                    <UserOverlayActionsOrganization
                                        deletePath={props.deletePath}
                                        reload={props.reload}
                                        userInfo={props.userInfo}
                                    />
                                )}
                            </TabPanel>
                        )}

                        {props.showComments && (
                            <TabPanel path={`${baseRoutePath}/comments`}>
                                <Comments showEvent={true} />
                            </TabPanel>
                        )}

                        <TabPanel path={`${baseRoutePath}/campaigns`}>
                            <UserOverlayCampaigns />
                        </TabPanel>

                        {eventId && (
                            <TabPanel path={`${baseRoutePath}/events`}>
                                <UserOverlayEvents />
                            </TabPanel>
                        )}
                    </Tabs>
                )}
            </Flex>
        </MobileOverlay>
    );
};

interface IUserOverlayEventProps {
    deletePath?: string;

    getBasePath(userInfoId: UserInfoIdPathParam, isRouteComponent?: boolean): string;
    reload(): void;
}

export const UserOverlayEvent = (props: IUserOverlayEventProps) => {
    const { eventId, userInfoId } = useParams();
    const { hasFeature } = useOrganizationContext();
    const {
        event: {
            permissions: { isEventAdmin }
        },
        isPositionAdmin
    } = useEventContext();
    const {
        data,
        isLoading,
        reload: reloadUserOverlay
    } = useUserOverlayEventQuery({ eventId, userInfoId });
    const reload = () => {
        props.reload();
        reloadUserOverlay();
    };

    return createPortal(
        <Box
            css={{
                background: 'white',
                left: '0',
                position: 'absolute',
                top: '0',
                zIndex: '200'
            }}
            height={1}
            width={1}
        >
            <UserOverlay
                accreditationState={data.event?.volunteerRegistration.accreditationState}
                canEditEmail={isEventAdmin}
                customDocuments={data.event?.customDocuments ?? []}
                delegation={data.event?.volunteerRegistration.delegation}
                deletePath={props.deletePath}
                getBasePath={props.getBasePath}
                isEvent={true}
                isLoading={isLoading}
                isWeezeventConnected={data.event?.isWeezeventConnected}
                links={data.event?.volunteerRegistration.userInfo.links}
                numberOfComments={data.event?.volunteerRegistration.userInfo.numberOfComments ?? 0}
                reload={reload}
                showAccreditations={hasFeature(Feature.Accreditation) && isEventAdmin}
                showActions={isPositionAdmin()}
                showComments={isEventAdmin}
                state={data.event?.volunteerRegistration.state}
                userInfo={data.event?.volunteerRegistration.userInfo}
            />
        </Box>,
        document.body
    );
};

interface IUserOverlayOrganizationProps {
    deletePath?: string;

    getBasePath(userInfoId: UserInfoIdPathParam, isRouteComponent?: boolean): string;
    reload(): void;
}

export const UserOverlayOrganization = (props: IUserOverlayOrganizationProps) => {
    const { organizationId, userInfoId } = useParams();
    const {
        data,
        isLoading,
        reload: reloadUserOverlay
    } = useUserOverlayOrganizationQuery({
        organizationId,
        userInfoId
    });
    const reload = () => {
        props.reload();
        reloadUserOverlay();
    };

    return createPortal(
        <Box
            css={{
                background: 'white',
                left: '0',
                position: 'absolute',
                top: '0',
                zIndex: '200'
            }}
            height={1}
            width={1}
        >
            <UserOverlay
                canEditEmail={true}
                customDocuments={[]}
                deletePath={props.deletePath}
                getBasePath={props.getBasePath}
                isEvent={false}
                isLoading={isLoading}
                isWeezeventConnected={false}
                links={null}
                numberOfComments={data.organization?.userInfo.numberOfComments ?? 0}
                reload={reload}
                showAccreditations={false}
                showActions={true}
                showComments={true}
                userInfo={data.organization?.userInfo}
            />
        </Box>,
        document.body
    );
};
