import { Button } from 'common-front/src/designSystem/components/button';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { copy } from 'common-front/src/util/commands';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { LinkText } from 'common/src/designSystem/components/linkText';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { CommonEnvVars } from 'common/src/envVars';
import { OrganizationId } from 'common/src/generated/types';
import {
    CLUB_FRANCE_2024,
    displayClubFrance2024Links,
    displayPartagetapassiongLinks,
    displayStaffInsatiablesFrLinks,
    STAFF_INSATIABLES_FR,
    WWW_PARTAGETAPASSION_COM
} from 'common/src/thirdPartyDomains';
import * as React from 'react';

const getLink = (organizationId: OrganizationId) => {
    const path = `v2/organization/${organizationId}/auth`;

    if (displayPartagetapassiongLinks(organizationId)) {
        return `https://${WWW_PARTAGETAPASSION_COM}/${path}`;
    } else if (displayStaffInsatiablesFrLinks(organizationId)) {
        return `https://${STAFF_INSATIABLES_FR}/${path}`;
    } else if (displayClubFrance2024Links(organizationId)) {
        return `https://${CLUB_FRANCE_2024}/${path}`;
    } else {
        return `https://event.${CommonEnvVars.HEAVENT_EXTERNAL_DOMAIN}/${path}`;
    }
};

export const OrganizationMembersSpaceIntegration = () => {
    const {
        params: { organizationId },
        translate
    } = useHeavent();
    const [isLoading, setIsLoading] = React.useState(false);
    const link = getLink(organizationId);
    const iframe = `<iframe
        height="100%"
        width="100%"
        src="${link}"
        style="border: none;"></iframe>`.replace(/\s+/g, ' ');

    return (
        <>
            <Spacer height="7" />

            <Box color="gray700" fontWeight="medium">
                {translate('lien_de_l_espac_08307')}
            </Box>

            <Spacer height="1" />

            <LinkText css={{ color: '$primary700' }} to={link}>
                {link}
            </LinkText>

            <Spacer height="6" />

            <Box color="gray700" fontWeight="medium">
                {translate('code_html_pour_79518')}
            </Box>

            <Spacer height="1" />

            <Box
                css={{
                    '& pre': {
                        border: '1px solid $gray300',
                        borderRadius: '$2',
                        padding: '$3'
                    }
                }}
            >
                <pre>{iframe}</pre>
            </Box>

            <Spacer height="2" />

            <Flex>
                <Button
                    isLoading={isLoading}
                    leftIcon="copy"
                    onClick={() => {
                        setIsLoading(true);
                        copy(iframe);

                        setTimeout(() => {
                            setIsLoading(false);
                        }, 500);
                    }}
                >
                    {translate('copier_le_code_99588')}
                </Button>
            </Flex>

            <Spacer height="6" />

            <Box color="gray700" fontWeight="medium">
                {translate('qr_code_du_lien_34278')}
            </Box>

            <Spacer height="1" />

            <Box height={100} width={100}>
                <img
                    height="100%"
                    src={`https://api.${CommonEnvVars.HEAVENT_EXTERNAL_DOMAIN}/barcode/qr?text=${encodeURIComponent(link)}&color=000000&height=100&width=100`}
                    width="100%"
                />
            </Box>
        </>
    );
};
