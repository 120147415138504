import { Blank } from 'common-front/src/components/blank/blank';
import { FieldSpacer } from 'common-front/src/components/users/show/fieldSpacer';
import { UserField } from 'common-front/src/components/users/show/userField';
import { Button } from 'common-front/src/designSystem/components/button';
import { ButtonDropdown } from 'common-front/src/designSystem/components/buttonDropdown';
import { CheckboxText } from 'common-front/src/designSystem/components/checkbox';
import { ItemIcon } from 'common-front/src/designSystem/components/dropdown/item';
import { OrderRightPanel } from 'common-front/src/designSystem/components/orderRightPanel';
import { TextInput } from 'common-front/src/designSystem/components/textInput';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import {
    FormUserOverlay,
    UserInformationForm
} from 'common-front/src/users/information/userInformationForm';
import { Svgs } from 'common-front/src/util/assets';
import { toFormElements } from 'common-front/src/vo/customField';
import { Badge } from 'common/src/designSystem/components/badge';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { Spacer } from 'common/src/designSystem/components/spacer';
import {
    CustomFieldSlug,
    CustomFieldWithConditionFragment,
    DelegationUserProfileFragment,
    EventId,
    FormId,
    FormUserInfoUserProfileFragment,
    OrganizationId,
    UserInfoOverlayFragment
} from 'common/src/generated/types';
import { isNonEmptyArray } from 'common/src/util/array';
import { useParams } from 'common/src/util/dependencies/dependencies';
import { Emptyable } from 'common/src/util/emptyable';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import { DelegationsPaths } from 'common/src/util/paths/delegationsPaths';
import { isNonEmptyString } from 'common/src/util/string';
import { shouldDisplay } from 'common/src/vo/field';
import { compact, sortBy } from 'lodash-es';
import * as React from 'react';
import { useEventContext } from '../../../events/show/eventContext';
import {
    useEventProfilePrivateFieldsUpdateMutation,
    useOrganizationProfilePrivateFieldsUpdateMutation,
    useUserOverlayInformationsEventQuery,
    useUserOverlayInformationsOrganizationQuery
} from '../../../generated/graphqlHooks';
import { useOrganizationContext } from '../../../organization/organizationContext';
import { UserOverlayInformationsTeam } from './userOverlayInformationsTeam';

interface IUserOverlayInformationsProps {
    customFields: CustomFieldWithConditionFragment[];
    delegations: DelegationUserProfileFragment[];
    eventId: Emptyable<EventId>;
    forms: FormUserOverlay[];
    formsUsersInfos: FormUserInfoUserProfileFragment[];
    initialShowNotFilled: boolean;
    organizationId: OrganizationId;
    // privateCustomFields: CustomFieldWithConditionFragment[];
    profilePrivateFields: CustomFieldSlug[];
    userInfo: UserInfoOverlayFragment;

    getEditUserFormPath(formId: FormId | 'all' | 'private'): string;
    profilePrivateFieldsUpdate(profilePrivateFields: CustomFieldSlug[]): Promise<any>;
    reload(): void;
    setShowNotFilled(showNotFilled: boolean): void;
}

const UserOverlayInformations = (props: IUserOverlayInformationsProps) => {
    const { history, translate } = useHeavent();
    const [name, setName] = React.useState('');
    const [showNotFilled, _setShowNotFilled] = React.useState(props.initialShowNotFilled);
    const [isOrderPrivateFieldsOpen, setIsOrderPrivateFieldsOpen] = React.useState(false);
    const setShowNotFilled = React.useCallback(
        (newShowNotFilled) => {
            _setShowNotFilled(newShowNotFilled);
            props.setShowNotFilled(newShowNotFilled);
        },
        [props.setShowNotFilled, _setShowNotFilled]
    );
    const formIdToFilledAt = React.useMemo(
        () => Object.fromEntries(props.formsUsersInfos.map((fui) => [fui.formId, fui.insertedAt])),
        [props.formsUsersInfos]
    );
    const forms = React.useMemo(
        () =>
            sortBy(
                props.forms.filter((form) => {
                    if (!showNotFilled && formIdToFilledAt[form.id] === undefined) {
                        return false;
                    } else if (isNonEmptyString(name)) {
                        return form.name.toLowerCase().includes(name.toLowerCase().trim());
                    } else {
                        return true;
                    }
                }),
                [(f) => (formIdToFilledAt[f.id] !== undefined ? 1 : 2), (f) => f.name]
            ),
        [props.forms, formIdToFilledAt, name, showNotFilled]
    );
    const teams = React.useMemo(
        () =>
            props.formsUsersInfos.filter(
                (
                    fui
                ): fui is FormUserInfoUserProfileFragment &
                    Required<Pick<FormUserInfoUserProfileFragment, 'teamCode'>> =>
                    isNonEmptyString(fui.teamCode)
            ),
        [props.formsUsersInfos]
    );
    const privateCustomFields = React.useMemo(
        () => props.customFields.filter((c) => c.isPrivate),
        [props.customFields]
    );
    const profilePrivateFields = React.useMemo(
        () =>
            isNonEmptyArray(props.profilePrivateFields)
                ? props.profilePrivateFields
                : privateCustomFields.map((cf) => cf.slug),
        [privateCustomFields, props.profilePrivateFields]
    );
    const displayedPrivateFields = React.useMemo(
        () =>
            sortBy(
                privateCustomFields.filter(
                    (cf) =>
                        profilePrivateFields.includes(cf.slug) &&
                        shouldDisplay(cf, props.userInfo.fields, props.customFields)
                ),
                ({ slug }) => profilePrivateFields.indexOf(slug)
            ),
        [props.customFields, privateCustomFields, profilePrivateFields]
    );
    const orderOnSave = React.useCallback(
        async (profilePrivateFields: CustomFieldSlug[]) => {
            await props.profilePrivateFieldsUpdate(profilePrivateFields);

            props.reload();
        },
        [props.profilePrivateFieldsUpdate, props.reload]
    );
    return (
        <>
            <Spacer height="6" />

            <Flex
                align="center"
                css={{
                    background: '$gray100',
                    borderRadius: '$1',
                    padding: '$4'
                }}
                gap="2"
            >
                <Box css={{ flex: '1' }}>
                    <TextInput
                        icon="magnifying-glass"
                        placeholder={translate('rechercher_un_f_28561')}
                        value={name}
                        onChange={setName}
                    />
                </Box>

                <CheckboxText
                    state={showNotFilled ? 'checked' : 'unchecked'}
                    onClick={(state) => {
                        setShowNotFilled(state === 'checked');
                    }}
                >
                    {translate('afficher_les_fo_84832')}
                </CheckboxText>
            </Flex>

            <Spacer height="6" />

            <Flex gap="7">
                <Flex css={{ flex: '1' }} direction="column" gap="7">
                    {forms.length === 0 ? (
                        <Flex
                            css={{
                                border: '1px solid $gray200',
                                borderRadius: '$2',
                                boxShadow: '$xs'
                            }}
                            height={400}
                        >
                            <Blank
                                button={
                                    <Button to={props.getEditUserFormPath('all')}>
                                        {translate('_diter_les_cham_66020')}
                                    </Button>
                                }
                                imageSrc={Svgs.FormsElementsBlank}
                                title={translate('aucun_formulair_41857')}
                            />
                        </Flex>
                    ) : (
                        forms.map((form) => (
                            <UserInformationForm
                                key={form.id}
                                filledAt={formIdToFilledAt[form.id]}
                                form={form}
                                getEditUserFormPath={props.getEditUserFormPath}
                                showIsFilled={form.id !== 'all'}
                                userInfo={props.userInfo}
                            />
                        ))
                    )}
                </Flex>

                <Flex direction="column" gap="4" width={276}>
                    {teams.map((team, index) => (
                        <UserOverlayInformationsTeam key={index} team={team} />
                    ))}

                    {isNonEmptyArray(props.delegations) && (
                        <Flex
                            css={{
                                background: '$gray100',
                                borderRadius: '$2',
                                padding: '$4'
                            }}
                            direction="column"
                            gap="1"
                        >
                            <Flex align="center" gap="3">
                                <I icon="people-group" />

                                <Box font="gray800 textMd semiBold">
                                    {translate('d_l_gations_78318')}
                                </Box>
                            </Flex>

                            <Flex gap="2" wrap="wrap">
                                {props.delegations.map((delegation) => {
                                    const name = props.eventId
                                        ? delegation.name
                                        : delegation.event
                                          ? `${delegation.event.name} : ${delegation.name}`
                                          : delegation.name;

                                    return (
                                        <Badge
                                            key={delegation.id}
                                            color="white"
                                            cursor="pointer"
                                            size="md"
                                            onClick={() => {
                                                history.push(
                                                    delegation.event
                                                        ? DelegationsPaths.DELEGATION({
                                                              organizationId: props.organizationId,
                                                              eventId: delegation.event.id,
                                                              delegationId: delegation.id
                                                          })
                                                        : HeaventPaths.COMMUNITY_DELEGATION(
                                                              props.organizationId,
                                                              delegation.id
                                                          )
                                                );
                                            }}
                                        >
                                            {name}
                                        </Badge>
                                    );
                                })}
                            </Flex>
                        </Flex>
                    )}

                    <Flex
                        css={{
                            background: '$gray100',
                            borderRadius: '$2',
                            height: 'fit-content',
                            padding: '$4'
                        }}
                        direction="column"
                    >
                        <Flex align="center" gap="3">
                            <I icon="lock-keyhole" />

                            <Box font="gray800 textMd semiBold">
                                {translate('informations_pr_15873')}
                            </Box>
                        </Flex>

                        <Spacer height="5" />

                        {displayedPrivateFields.map((customField, index) => (
                            <React.Fragment key={customField.slug}>
                                {index !== 0 && <FieldSpacer />}

                                <UserField field={customField} userInfo={props.userInfo} />
                            </React.Fragment>
                        ))}

                        <Spacer height="4" />

                        <ButtonDropdown
                            color="white"
                            menu={
                                <>
                                    <ItemIcon
                                        icon="gear"
                                        onClick={() => {
                                            setIsOrderPrivateFieldsOpen(true);
                                        }}
                                    >
                                        {translate('g_rer_les_champ_00431')}
                                    </ItemIcon>
                                </>
                            }
                            onClick={() => history.push(props.getEditUserFormPath('private'))}
                        >
                            {translate('_diter_62574')}
                        </ButtonDropdown>
                    </Flex>
                </Flex>
            </Flex>

            <Spacer height="8" />

            {isOrderPrivateFieldsOpen && (
                <OrderRightPanel
                    columns={privateCustomFields}
                    css={{ zIndex: '210' }}
                    initialSelectedColumns={profilePrivateFields}
                    saveButtonText={translate('mettre_jour_85141')}
                    searchPlaceholder={translate('rechercher_un_c_86475')}
                    subtitle={translate('personnaliser_l_69296')}
                    title={translate('gestion_des_cha_66775')}
                    onClose={() => {
                        setIsOrderPrivateFieldsOpen(false);
                    }}
                    onSave={orderOnSave}
                />
            )}
        </>
    );
};

interface IUserOverlayInformationsEventProps {
    userInfo: UserInfoOverlayFragment;
}

export const UserOverlayInformationsEvent = (props: IUserOverlayInformationsEventProps) => {
    const { organizationId, eventId } = useParams();
    const { localEvent, updateLocalEvent } = useEventContext();
    const { mutate } = useEventProfilePrivateFieldsUpdateMutation();
    const { data, loader, reload } = useUserOverlayInformationsEventQuery({
        organizationId,
        eventId,
        userInfoId: props.userInfo.id
    });
    const forms = React.useMemo(() => sortBy(data.event?.forms ?? [], (f) => f.name), [data.event]);
    const delegations = React.useMemo(
        () => compact([data.event?.volunteerRegistration.delegation]),
        [data.event]
    );

    return (
        loader || (
            <UserOverlayInformations
                customFields={data.organization.customFields.nodes}
                delegations={delegations}
                eventId={eventId}
                forms={forms}
                formsUsersInfos={data.organization?.userInfo.formsUsersInfos ?? []}
                getEditUserFormPath={(formId) =>
                    HeaventPaths.EDIT_USER_FORM(organizationId, eventId, props.userInfo.id, formId)
                }
                initialShowNotFilled={localEvent?.showNotFilled ?? false}
                organizationId={organizationId}
                profilePrivateFields={data.event.profilePrivateFields}
                profilePrivateFieldsUpdate={(profilePrivateFields) =>
                    mutate({
                        eventId,
                        profilePrivateFields
                    })
                }
                reload={reload}
                setShowNotFilled={(showNotFilled) => {
                    updateLocalEvent({ showNotFilled });
                }}
                userInfo={props.userInfo}
            />
        )
    );
};

interface IUserOverlayInformationsOrganizationProps {
    userInfo: UserInfoOverlayFragment;
}

export const UserOverlayInformationsOrganization = (
    props: IUserOverlayInformationsOrganizationProps
) => {
    const {
        params: { organizationId },
        translate
    } = useHeavent();
    const { localOrganization, updateLocalOrganization } = useOrganizationContext();
    const { mutate } = useOrganizationProfilePrivateFieldsUpdateMutation();
    const { data, loader, reload } = useUserOverlayInformationsOrganizationQuery({
        organizationId,
        userInfoId: props.userInfo.id
    });
    const forms = React.useMemo(() => {
        if (data.organization) {
            const allElements = toFormElements(data.organization.customFields.nodes);
            const form: FormUserOverlay = {
                id: 'all',
                name: translate('tous_les_champs_57939'),
                elements: allElements
            };

            return sortBy(data.organization.forms as FormUserOverlay[], (f) => f.name).concat([
                form
            ]);
        } else {
            return [];
        }
    }, [data.organization, translate]);

    return (
        loader || (
            <UserOverlayInformations
                customFields={data.organization.customFields.nodes}
                delegations={data.organization.userInfo.delegations}
                eventId={null}
                forms={forms}
                formsUsersInfos={data.organization?.userInfo.formsUsersInfos ?? []}
                getEditUserFormPath={(formId) =>
                    HeaventPaths.COMMUNITY_EDIT_USER_FORM(organizationId, props.userInfo.id, formId)
                }
                initialShowNotFilled={localOrganization?.showNotFilled ?? false}
                organizationId={organizationId}
                profilePrivateFields={data.organization.profilePrivateFields}
                profilePrivateFieldsUpdate={(profilePrivateFields) =>
                    mutate({
                        organizationId,
                        profilePrivateFields
                    })
                }
                reload={reload}
                setShowNotFilled={(showNotFilled) => {
                    updateLocalOrganization({ showNotFilled });
                }}
                userInfo={props.userInfo}
            />
        )
    );
};
