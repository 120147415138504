import { DelegationAccreditationsBlank } from 'common-front/src/delegations/show/accreditations/delegationAccreditationsBlank';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Flex } from 'common/src/designSystem/components/flex';
import { DelegationReportingQuery } from 'common/src/generated/types';
import { isNonEmptyArray } from 'common/src/util/array';
import { sortBy, uniqBy } from 'lodash-es';
import * as React from 'react';
import { useDelegationReportingQuery } from '../../../generated/graphqlHooks';
import { DelegationReportingAccreditation } from './delegationReportingAccreditation';

interface IDelegationReportingComponentProps {
    event: DelegationReportingQuery['event'];
}

const DelegationReportingComponent = (props: IDelegationReportingComponentProps) => {
    const accreditations = React.useMemo(
        () =>
            sortBy(
                uniqBy(
                    props.event.delegation.accreditationsSlots.map((das) => das.accreditation),
                    (a) => a.id
                ),
                (a) => a.name.toLowerCase()
            ),
        [props.event]
    );

    return (
        <Flex direction="column" gap="4">
            {isNonEmptyArray(accreditations) ? (
                accreditations.map((accreditation) => (
                    <DelegationReportingAccreditation
                        key={accreditation.id}
                        accreditation={accreditation}
                        event={props.event}
                    />
                ))
            ) : (
                <DelegationAccreditationsBlank />
            )}
        </Flex>
    );
};

export const DelegationReporting = () => {
    const {
        params: { eventId, delegationId }
    } = useHeavent();
    const { data, loader } = useDelegationReportingQuery({ eventId, delegationId });

    return loader || <DelegationReportingComponent event={data.event} />;
};
