import { useLoadMore } from 'common-front/src/components/graphql/useLoadMore';
import { FullPageContent } from 'common-front/src/components/mobileFullPage/fullPageContent';
import { MobileFullPage } from 'common-front/src/components/mobileFullPage/mobileFullPage';
import { LoadMoreControl } from 'common-front/src/components/pagination/mobile/loadMoreControl';
import { VolunteerRowMobile } from 'common-front/src/components/volunteerRowMobile/volunteerRowMobile';
import { Flex } from 'common/src/designSystem/components/flex';
import { useHistory, useParams, useTranslate } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import { DEFAULT_PAGINATION_LIMIT } from 'common/src/vo/pagination';
import * as React from 'react';
import { executePositionSlotMembersMobileQuery } from '../../../../generated/graphqlHooks';
import { PositionSlotMembersBlankMobile } from './positionSlotMembersBlankMobile';
import { PositionSlotMembersHeaderMobile } from './positionSlotMembersHeaderMobile';

const getNodes = (result: Awaited<ReturnType<typeof executePositionSlotMembersMobileQuery>>) =>
    result.event.volunteersRegistrations.nodes;

export const PositionSlotMembersMobile = () => {
    const { organizationId, eventId, positionId, positionSlotId } = useParams();
    const translate = useTranslate();
    const history = useHistory();

    const variables = {
        eventId,
        positionSlotId,
        predicates: [],
        limit: DEFAULT_PAGINATION_LIMIT,
        offset: 0
    };
    const { data, loader, loadMore, xs } = useLoadMore(
        executePositionSlotMembersMobileQuery,
        variables,
        getNodes
    );

    if (loader && data === undefined) {
        return loader;
    } else {
        return (
            <MobileFullPage>
                <PositionSlotMembersHeaderMobile slot={data.event.positionSlot} />
                <FullPageContent>
                    <Flex css={{ flex: 1, margin: '$7 0' }} direction="column" gap="7">
                        {xs.length === 0 ? (
                            <PositionSlotMembersBlankMobile />
                        ) : (
                            <Flex direction="column" gap="6">
                                {xs.map((registration) => {
                                    const openVolunteer = () => {
                                        history.push(
                                            HeaventPaths.POSITION_SLOT_MEMBERS_USER_INFORMATIONS(
                                                organizationId,
                                                eventId,
                                                positionId,
                                                positionSlotId,
                                                registration.userInfo.id
                                            )
                                        );
                                    };

                                    return (
                                        <VolunteerRowMobile
                                            key={registration.id}
                                            checkInState={registration.positionSlotUserInfo!.state}
                                            openVolunteer={openVolunteer}
                                            userInfo={registration.userInfo}
                                        />
                                    );
                                })}
                                <LoadMoreControl
                                    buttonText={translate('afficher_plus_d_41906')}
                                    isLoading={loader !== undefined}
                                    loadMore={loadMore}
                                    pageInfo={data.event.volunteersRegistrations.pageInfo}
                                    statusText={translate(
                                        '_1_sur_2_mem_14323',
                                        xs.length,
                                        data.event.volunteersRegistrations.totalCount
                                    )}
                                />
                            </Flex>
                        )}
                    </Flex>
                </FullPageContent>
            </MobileFullPage>
        );
    }
};
