import { UpdateLinkTextCell } from 'common-front/src/components/cells/updateLinkTextCell';
import { TextInput } from 'common-front/src/designSystem/components/textInput';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { useSegmentsContext } from 'common-front/src/segments/segmentsContext';
import { LinkText } from 'common/src/designSystem/components/linkText';
import { Position, PositionProperty } from 'common/src/generated/types';
import { PositionsPaths } from 'common/src/util/paths/positionsPaths';
import * as React from 'react';
import { PositionsUpdateCell } from './positionsUpdateCell';

interface IPositionsNameCellProps {
    position: Pick<Position, 'id' | 'name'> & { numberOfSlots?: number };

    reload(): void;
}

export const PositionsNameCell = (props: IPositionsNameCellProps) => {
    const {
        translate,
        params: { organizationId, eventId }
    } = useHeavent();
    const { isEditMode } = useSegmentsContext();

    return (
        <PositionsUpdateCell
            initialValue={props.position.name}
            positionId={props.position.id}
            property={PositionProperty.Name}
            reload={props.reload}
            renderInput={(value, setValue) => (
                <TextInput
                    label={translate('nom_de_la_missi_64605')}
                    value={value}
                    onChange={setValue}
                />
            )}
            renderValue={() => (
                <UpdateLinkTextCell isEditMode={isEditMode} title={props.position.name}>
                    <LinkText
                        to={PositionsPaths.POSITION({
                            organizationId,
                            eventId,
                            positionId: props.position.id
                        })}
                    >
                        {props.position.name}
                    </LinkText>
                </UpdateLinkTextCell>
            )}
        />
    );
};
