import { NoFeature } from 'common-front/src/components/noFeature';
import { Alert } from 'common-front/src/designSystem/components/alert';
import { Tab } from 'common-front/src/designSystem/components/tabs/tab';
import { TabList } from 'common-front/src/designSystem/components/tabs/tabList';
import { TabPanel } from 'common-front/src/designSystem/components/tabs/tabPanel';
import { Tabs } from 'common-front/src/designSystem/components/tabs/tabs';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { useTitle } from 'common-front/src/hooks/useTitle';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { Feature } from 'common/src/generated/types';
import { heaventEnvDisplayName } from 'common/src/heaventEnv';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';
import { useEventContext } from '../events/show/eventContext';
import { useEventKeyQuery } from '../generated/graphqlHooks';
import { useOrganizationContext } from '../organization/organizationContext';
import { ApiIntegration } from './integration/apiIntegration';
import { Keys } from './keys';
import { WeezeventV2 } from './weezeventV2/weezeventV2';

export const Api = () => {
    const {
        params: { organizationId, eventId },
        translate
    } = useHeavent();
    const {
        event: { name: eventName }
    } = useEventContext();
    useTitle(`${eventName} ${translate('api_et_int_grat_54084')}`);
    const { hasFeature } = useOrganizationContext();
    const { data, loader } = useEventKeyQuery({ eventId });
    const hasWeezeventV1 = isNonEmptyString(data.event?.weezeventConnectionInfos?.idEvent);

    return (
        loader || (
            <Flex css={{ padding: '$7' }} direction="column" height={1} width={1}>
                <Box color="gray900" fontSize="displayXs" fontWeight="semiBold">
                    {translate('api_et_int_grat_54084')}
                </Box>

                <Spacer height="7" />

                <Tabs>
                    <TabList>
                        <Tab path={HeaventPaths.API_API(organizationId, eventId)}>API</Tab>

                        {hasWeezeventV1 && (
                            <Tab path={HeaventPaths.API_INTEGRATION(organizationId, eventId)}>
                                {translate('int_grations_88619')}
                            </Tab>
                        )}

                        {!hasWeezeventV1 && (
                            <Tab path={HeaventPaths.API_WEEZEVENT_V2(organizationId, eventId)}>
                                Weezevent
                            </Tab>
                        )}
                    </TabList>

                    <TabPanel path={HeaventPaths.API_API(':organizationId', ':eventId')}>
                        <Spacer height="8" />

                        <Alert leftIcon="circle-exclamation">
                            {translate(`l_api_permet_de_66672`, heaventEnvDisplayName())}
                        </Alert>

                        <Spacer height="7" />

                        {hasFeature(Feature.Api) ? (
                            <Keys eventKey={data.event.key} />
                        ) : (
                            <Flex
                                align="center"
                                css={{ flex: '1' }}
                                height={1}
                                justify="center"
                                width={1}
                            >
                                <NoFeature icon="code-branch" />
                            </Flex>
                        )}
                    </TabPanel>

                    <TabPanel path={HeaventPaths.API_INTEGRATION(':organizationId', ':eventId')}>
                        <Spacer height="8" />

                        {hasFeature(Feature.Weezevent) ? (
                            <ApiIntegration />
                        ) : (
                            <Flex
                                align="center"
                                css={{ flex: '1' }}
                                height={1}
                                justify="center"
                                width={1}
                            >
                                <NoFeature icon="code-branch" />
                            </Flex>
                        )}
                    </TabPanel>

                    <TabPanel path={HeaventPaths.API_WEEZEVENT_V2(':organizationId', ':eventId')}>
                        <Spacer height="8" />

                        {hasFeature(Feature.Weezevent) ? (
                            <WeezeventV2 />
                        ) : (
                            <Flex
                                align="center"
                                css={{ flex: '1' }}
                                height={1}
                                justify="center"
                                width={1}
                            >
                                <NoFeature icon="code-branch" />
                            </Flex>
                        )}
                    </TabPanel>
                </Tabs>
            </Flex>
        )
    );
};
