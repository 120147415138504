import { Button } from 'common-front/src/designSystem/components/button';
import { Dropdown } from 'common-front/src/designSystem/components/dropdown/dropdown';
import { ItemIcon } from 'common-front/src/designSystem/components/dropdown/item';
import { Menu } from 'common-front/src/designSystem/components/dropdown/menu';
import { Trigger } from 'common-front/src/designSystem/components/dropdown/trigger';
import { Content } from 'common-front/src/designSystem/components/tooltip/content';
import { Tooltip } from 'common-front/src/designSystem/components/tooltip/tooltip';
import { Trigger as TooltipTrigger } from 'common-front/src/designSystem/components/tooltip/trigger';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { useSegmentsContext } from 'common-front/src/segments/segmentsContext';
import { PositionsRendering, positionsRenderingIcon } from 'common-front/src/vo/positionsRendering';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { Feature, PositionsSegmentsQuery } from 'common/src/generated/types';
import { getDocumentFullPath } from 'common/src/util/documentsPaths';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import { PositionsPaths } from 'common/src/util/paths/positionsPaths';
import * as React from 'react';
import { useEventContext } from '../../events/show/eventContext';
import { usePositionsHeaderQuery } from '../../generated/graphqlHooks';
import { useOrganizationContext } from '../../organization/organizationContext';
import { CreatePositionCategory } from '../../positionsCategories/create/createUpdatePositionCategory';
import { KmlImport } from '../import/kml/kmlImport';
import { usePositionsContext } from './positionsContext';

interface IPositionsHeaderProps {
    name: string;
    numberOfCategories: number;
    segment: PositionsSegmentsQuery['event']['segmentsFolders'][0]['segments'][0];
}

export const PositionsHeader = ({ name, numberOfCategories, segment }: IPositionsHeaderProps) => {
    const {
        history,
        params: { organizationId, eventId },
        translate
    } = useHeavent();
    const { hasFeature } = useOrganizationContext();
    const { isEventAdmin } = useEventContext();
    const { rendering, setRendering } = useSegmentsContext();
    const { newPositionLatLng } = usePositionsContext();
    const { data } = usePositionsHeaderQuery({
        eventId,
        segmentId: segment.id
    });
    const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);
    const [isCreatePositionCategoryOpen, setIsCreatePositionCategoryOpen] = React.useState(false);
    const [isKmlImportOpen, setIsKmlImportOpen] = React.useState(false);

    return (
        <Flex gap="4" width={1}>
            <Flex align="center" css={{ flex: '1', position: 'relative' }} gap="2">
                <Box color="gray900" fontSize="displaySm" fontWeight="semiBold">
                    {name}
                </Box>

                <Tooltip>
                    <TooltipTrigger>
                        <Box color="gray500">
                            <I icon="circle-exclamation" />
                        </Box>
                    </TooltipTrigger>

                    <Content placement="bottom-start">
                        {translate('nombre_de_missi_06171', data.event?.segment.count ?? 0)}
                        <br />
                        {translate(
                            'nombre_de_membr_16367',
                            data.event?.segment.stats?.assignedResources ?? 0
                        )}
                        <br />
                        {translate(
                            'nombre_de_membr_29724',
                            data.event?.segment.stats?.resources ?? 0
                        )}
                    </Content>
                </Tooltip>
            </Flex>

            <Dropdown>
                <Trigger>
                    <Button
                        color="white"
                        leftIcon={positionsRenderingIcon(rendering as PositionsRendering)}
                    >
                        {rendering === PositionsRendering.Map
                            ? translate('vue_carte_79064')
                            : rendering === PositionsRendering.Calendar
                              ? translate('vue_calendrier_58086')
                              : rendering === PositionsRendering.SlotsList
                                ? translate('vue_liste_cr_n_47406')
                                : translate('vue_liste_56882')}
                    </Button>
                </Trigger>

                <Menu placement="bottom-end">
                    <ItemIcon
                        icon="table"
                        onClick={() => {
                            setRendering(PositionsRendering.List);
                        }}
                    >
                        {translate('vue_liste_56882')}
                    </ItemIcon>

                    <ItemIcon
                        icon="calendar"
                        onClick={() => {
                            setRendering(PositionsRendering.Calendar);
                        }}
                    >
                        {translate('vue_calendrier_58086')}
                    </ItemIcon>

                    <ItemIcon
                        icon="map-location"
                        onClick={() => {
                            setRendering(PositionsRendering.Map);
                        }}
                    >
                        {translate('vue_carte_79064')}
                    </ItemIcon>

                    <ItemIcon
                        icon="table"
                        onClick={() => {
                            setRendering(PositionsRendering.SlotsList);
                        }}
                    >
                        {translate('vue_liste_cr_n_47406')}
                    </ItemIcon>
                </Menu>
            </Dropdown>

            {isEventAdmin && (
                <>
                    <Button
                        onClick={() => {
                            let positionParam: string | undefined;

                            if (newPositionLatLng) {
                                positionParam = `${newPositionLatLng.latitude},${newPositionLatLng.longitude}`;
                            }

                            history.push(
                                HeaventPaths.CREATE_POSITION(organizationId, eventId, positionParam)
                            );
                        }}
                    >
                        {translate('cr_er_une_missi_66818')}
                    </Button>

                    <Dropdown isOpen={isDropdownOpen} onStateChange={setIsDropdownOpen}>
                        <Trigger>
                            <Button color="white" leftIcon="ellipsis-vertical" />
                        </Trigger>

                        <Menu placement="bottom-end">
                            <ItemIcon
                                icon="plus"
                                onClick={() => {
                                    setIsDropdownOpen(false);
                                    setIsCreatePositionCategoryOpen(true);
                                }}
                            >
                                {translate('cr_er_une_cat_g_20051')}
                            </ItemIcon>

                            <ItemIcon
                                icon="calendar"
                                to={getDocumentFullPath(
                                    data.event?.segment.links.positionsPlanningLink,
                                    'html'
                                )}
                            >
                                {translate('voir_le_plannin_85187')}
                            </ItemIcon>

                            <ItemIcon
                                icon="calendar"
                                to={getDocumentFullPath(
                                    data.event?.segment.links.positionsVolunteersPlanningLink,
                                    'html'
                                )}
                            >
                                {translate('voir_le_plannin_47650')}
                            </ItemIcon>

                            <ItemIcon
                                download={`${segment.name}-positions-planning`}
                                icon="arrow-down-to-bracket"
                                to={getDocumentFullPath(
                                    data.event?.segment.links.positionsPlanningLink,
                                    'pdf'
                                )}
                            >
                                {translate('t_l_charger_le_06050')}
                            </ItemIcon>

                            <ItemIcon
                                download={`${segment.name}-positions-volunteers-planning`}
                                icon="arrow-down-to-bracket"
                                to={getDocumentFullPath(
                                    data.event?.segment.links.positionsVolunteersPlanningLink,
                                    'pdf'
                                )}
                            >
                                {translate('t_l_charger_le_02952')}
                            </ItemIcon>

                            {hasFeature(Feature.KmlImport) && (
                                <ItemIcon
                                    color={numberOfCategories === 0 ? 'disabled' : 'default'}
                                    icon="file-import"
                                    onClick={() => {
                                        setIsDropdownOpen(false);
                                        setIsKmlImportOpen(true);
                                    }}
                                >
                                    {translate('importer_des_mi_32264')}
                                </ItemIcon>
                            )}

                            <ItemIcon
                                icon="file-import"
                                to={PositionsPaths.POSITION_IMPORT_CSV({
                                    organizationId,
                                    eventId
                                })}
                            >
                                {translate('import_tasks_fr_52548')}
                            </ItemIcon>
                        </Menu>
                    </Dropdown>
                </>
            )}

            {isCreatePositionCategoryOpen && (
                <CreatePositionCategory
                    eventId={eventId}
                    onClose={() => {
                        setIsCreatePositionCategoryOpen(false);
                    }}
                    onSuccess={({ id }) => {
                        history.push(
                            PositionsPaths.POSITION_CATEGORY({
                                organizationId,
                                eventId,
                                positionCategoryId: id
                            })
                        );
                    }}
                />
            )}

            {isKmlImportOpen && (
                <KmlImport
                    eventId={eventId}
                    onClose={() => {
                        setIsKmlImportOpen(false);
                    }}
                />
            )}
        </Flex>
    );
};
