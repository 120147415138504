import { Empty } from 'common-front/src/components/empty/empty';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { useMobileQuery } from 'common-front/src/hooks/useMobileQuery';
import { useTitle } from 'common-front/src/hooks/useTitle';
import { Box } from 'common/src/designSystem/components/box';
import { Flex, SectioningElement } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { EventQuery, Feature } from 'common/src/generated/types';
import { useParams } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import { AccreditationsPaths } from 'common/src/util/paths/accreditationsPaths';
import { DelegationsPaths } from 'common/src/util/paths/delegationsPaths';
import { PositionsPaths } from 'common/src/util/paths/positionsPaths';
import * as React from 'react';
import { Route, Switch } from 'react-router';
import { Redirect } from 'react-router-dom';
import { AccreditationsAccredit } from '../../accreditations/accredit/accreditationsAccredit';
import {
    CreateAccreditation,
    UpdateAccreditation
} from '../../accreditations/create/createUpdateAccreditation';
import { AccreditationsHasFeature } from '../../accreditations/list/accreditationsHasFeature';
import { AccreditationLoader } from '../../accreditations/show/accreditationLoader';
import { Accredit } from '../../accreditationsAssignments/accredit/accredit';
import { AccreditationsAssignments } from '../../accreditationsAssignments/list/accreditationsAssignments';
import { AccreditationCategoryLoader } from '../../accreditationsCategories/show/accreditationCategoryLoader';
import { AccreditationsSlot } from '../../accreditationsSlots/show/accreditationsSlot';
import { Api } from '../../api/api';
import { Weezevent } from '../../api/integration/weezevent/weezevent';
import { Assignment } from '../../assignments/assign/assignment';
import { Assignments } from '../../assignments/list/assignments';
import { TeamAssignment } from '../../assignments/teamAssignment/teamAssignment';
import { CreateCampaign, UpdateCampaign } from '../../campaigns/create/createUpdateCampaign';
import { Campaigns } from '../../campaigns/list/campaigns';
import { EventCampaign } from '../../campaigns/show/campaign';
import { Dashboard } from '../../dashboard/dashboard';
import {
    CreateEventDelegation,
    UpdateEventDelegation
} from '../../delegations/create/createUpdateDelegation';
import { DelegationsImport } from '../../delegations/import/delegationsImport';
import { DelegationsHasFeature } from '../../delegations/list/delegationsHasFeature';
import { DelegationLoader } from '../../delegations/show/delegationLoader';
import { DocumentBuilder } from '../../documentBuilder/documentBuilder';
import { DocumentsLoader } from '../../documents/list/documentsLoader';
import { PositionPlanningDocument } from '../../documents/show/positionPlanningDocument';
import { PositionSheetDocument } from '../../documents/show/positionSheetDocument';
import { PositionsPlanningDocument } from '../../documents/show/positionsPlanningDocument';
import { PositionsVolunteersPlanningDocument } from '../../documents/show/positionsVolunteersPlanningDocument';
import { UserAssignmentsDocument } from '../../documents/show/userAssignmentsDocument';
import { UserBadgeDocument } from '../../documents/show/userBadgeDocument';
import { UserCertificateDocument } from '../../documents/show/userCertificateDocument';
import { UserCustomBadge } from '../../documents/show/userCustomBadge';
import { UserCustomDocument } from '../../documents/show/userCustomDocument';
import { UserPlanningDaysDocument } from '../../documents/show/userPlanningDaysDocument';
import { UserPlanningDocument } from '../../documents/show/userPlanningDocument';
import { UserPlanningListDocument } from '../../documents/show/userPlanningListDocument';
import { UsersListingDocument } from '../../documents/show/usersListingDocument';
import { Emails } from '../../emails/emails';
import { FilesImport } from '../../files/import/filesImport';
import { EventForms } from '../../forms/list/forms';
import { EventForm } from '../../forms/show/form';
import { useEventQuery } from '../../generated/graphqlHooks';
import { OrganizationComponent } from '../../organization/organizationComponent';
import { useOrganizationContext } from '../../organization/organizationContext';
import { CreatePosition, UpdatePosition } from '../../positions/create/createUpdatePosition';
import { PositionsCsvImport } from '../../positions/import/csv/positionsCsvImport';
import { PositionsLoader } from '../../positions/list/positionsLoader';
import { PositionLoader } from '../../positions/show/positionLoader';
import { PositionCategoryLoader } from '../../positionsCategories/show/positionCategoryLoader';
import { PositionsSlotsAssignVolunteers } from '../../positionsSlots/assignVolunteers/positionsSlotsAssignVolunteers';
import { UserProfile } from '../../users/profile/userProfile';
import { UpdateUserLoaderEvent } from '../../users/update/updateUserLoader';
import { UserUpdateAccreditationsLoader } from '../../users/updateAccreditations/userUpdateAccreditationsLoader';
import { UserUpdateMissionsLoader } from '../../users/updateMissions/userUpdateMissionsLoader';
import { UserUpdateSlotsLoader } from '../../users/updateSlots/userUpdateSlotsLoader';
import { VolunteersImport } from '../../volunteers/import/volunteersImport';
import { VolunteersLoader } from '../../volunteers/list/volunteersLoader';
import { EventContextProvider, useEventContext } from './eventContext';
import { EventMobile } from './eventMobile';
import { MenuItem } from './menuItem';
import * as Rules from './rules';

interface IEventComponentProps {
    event: EventQuery['event'];
}

const EventComponent = (props: IEventComponentProps) => {
    const {
        params: { organizationId, eventId },
        translate
    } = useHeavent();
    useTitle(props.event.name);
    const { hasFeature } = useOrganizationContext();
    const { numberOfWaitingAssigments, numberOfWaitingAccreditations, updateLocalEvent } =
        useEventContext();
    const isEventAdmin = props.event.permissions.isEventAdmin;
    const isPositionAdmin = Rules.isPositionAdmin(props.event);

    return (
        <Flex height={1} width={1}>
            <Flex
                align="center"
                aria-label={translate('menu_de_l_v_ne_05526')}
                as={SectioningElement.Nav}
                css={{
                    background: '$gray100',
                    borderRight: '1px solid $gray200',
                    height: '100%',
                    width: '64px',
                    overflowY: 'auto'
                }}
                direction="column"
                gap="1"
            >
                <Spacer height="10" />

                <MenuItem
                    icon="house"
                    title={translate('tableau_de_bord_24627')}
                    to={HeaventPaths.DASHBOARD(organizationId, eventId)}
                />

                <MenuItem
                    icon="sitemap"
                    isActive={(pathname: string) =>
                        (pathname.endsWith('/positions') ||
                            pathname.match(/\/positions\/\d+/) !== null ||
                            pathname.match(/\/position\/\d+/) !== null ||
                            pathname.match(/\/category\/\d+/) !== null) &&
                        !pathname.includes('documents')
                    }
                    title={translate('missions_63972')}
                    to={PositionsPaths.POSITIONS({
                        organizationId,
                        eventId
                    })}
                    onClick={() => updateLocalEvent({ positionsSegmentId: null })}
                />

                <MenuItem
                    icon="user-group"
                    isActive={(pathname: string) =>
                        pathname.endsWith('/volunteers') ||
                        pathname.match(/\/volunteers\/\d+/) !== null
                    }
                    title={translate('membres_11310')}
                    to={HeaventPaths.VOLUNTEERS(organizationId, eventId)}
                    onClick={() => updateLocalEvent({ volunteersSegmentId: null })}
                />

                {hasFeature(Feature.Delegation) && isEventAdmin && (
                    <MenuItem
                        icon="people-group"
                        isActive={(pathname) =>
                            pathname.match(/\/delegations\/\d+/) !== null ||
                            pathname.match(/\/delegation\/\d+/) !== null
                        }
                        title={translate('d_l_gations_78318')}
                        to={DelegationsPaths.DELEGATIONS({ organizationId, eventId })}
                        onClick={() => updateLocalEvent({ delegationsSegmentId: null })}
                    />
                )}

                {isPositionAdmin && (
                    <MenuItem
                        icon="wand-magic-sparkles"
                        isActive={(pathname: string) =>
                            pathname.endsWith('/assignments') ||
                            pathname.endsWith('/assignments/blank') ||
                            pathname.match(/\/assignment\/\d+/) !== null ||
                            pathname.match(/\/pre-assignment\/\d+/) !== null
                        }
                        title={`${translate(
                            'affectations_de_07531'
                        )} (${numberOfWaitingAssigments})`}
                        to={HeaventPaths.ASSIGNMENTS(organizationId, eventId)}
                    />
                )}

                {isEventAdmin && hasFeature(Feature.Accreditation) && (
                    <MenuItem
                        icon="sparkles"
                        isActive={(pathname: string) =>
                            pathname.endsWith('/accreditations-assignments') ||
                            pathname.match(/\/accredit\/\d+/) !== null
                        }
                        title={`${translate(
                            'affectations_de_99700'
                        )} (${numberOfWaitingAccreditations})`}
                        to={HeaventPaths.ACCREDITATIONS_ASSIGNMENTS(organizationId, eventId)}
                    />
                )}

                {isEventAdmin && (
                    <MenuItem
                        icon="file-lines"
                        title={translate('Form_plural')}
                        to={HeaventPaths.FORMS(organizationId, eventId)}
                    />
                )}

                {isPositionAdmin && (
                    <MenuItem
                        icon="paper-plane"
                        isActive={(pathname: string) =>
                            pathname.endsWith('/campaigns') ||
                            pathname.match(/\/campaign\/\d+/) !== null
                        }
                        title={translate('campagnes_31372')}
                        to={HeaventPaths.CAMPAIGNS(organizationId, eventId)}
                    />
                )}

                {isEventAdmin && (
                    <MenuItem
                        icon="badge-check"
                        isActive={(pathname: string) =>
                            pathname.match(/\/accreditations\/\d+/) !== null ||
                            pathname.match(/\/accreditation\/\d+/) !== null ||
                            pathname.match(/\/accreditation-category\/\d+/) !== null
                        }
                        title={translate('accr_ditations_39450')}
                        to={HeaventPaths.ACCREDITATIONS(organizationId, eventId)}
                        onClick={() => updateLocalEvent({ accreditationsSegmentId: null })}
                    />
                )}

                {isEventAdmin && (
                    <MenuItem
                        icon="at"
                        isActive={(pathname: string) =>
                            pathname.endsWith('/emails/parameters') ||
                            pathname.endsWith('/emails/templates') ||
                            pathname.endsWith('/emails/dns')
                        }
                        title={translate('e_mails_09850')}
                        to={HeaventPaths.EVENT_EMAILS_PARAMETERS(organizationId, eventId)}
                    />
                )}

                {isEventAdmin && (
                    <MenuItem
                        icon="folders"
                        isActive={(pathname: string) =>
                            pathname.endsWith('/documents') ||
                            pathname.match(/\/documents\//) !== null
                        }
                        title={translate('documents_87028')}
                        to={HeaventPaths.DOCUMENTS(organizationId, eventId)}
                    />
                )}

                {isEventAdmin && (
                    <MenuItem
                        icon="code-branch"
                        isActive={(pathname: string) =>
                            pathname.includes('api/api') ||
                            pathname.includes('api/integration') ||
                            pathname.includes('api/weezevent')
                        }
                        title={translate('api_et_int_grat_54084')}
                        to={HeaventPaths.API_API(organizationId, eventId)}
                    />
                )}
            </Flex>

            <Box
                css={{
                    flex: '1',
                    height: '100%',
                    overflowX: 'hidden'
                }}
                tabIndex={-1}
            >
                <Switch>
                    <Route exact path={HeaventPaths.DASHBOARD(':organizationId', ':eventId')}>
                        <Dashboard />
                    </Route>

                    <Route
                        path={PositionsPaths.POSITIONS({
                            organizationId: ':organizationId',
                            eventId: ':eventId'
                        })}
                    >
                        <PositionsLoader />
                    </Route>

                    <Redirect
                        from={PositionsPaths.__Deprecated.POSITION_CATEGORY({
                            organizationId: ':organizationId',
                            eventId: ':eventId',
                            positionCategoryId: ':positionCategoryId'
                        })}
                        to={PositionsPaths.POSITION_CATEGORY({
                            organizationId: ':organizationId',
                            eventId: ':eventId',
                            positionCategoryId: ':positionCategoryId'
                        })}
                    />
                    <Route
                        path={PositionsPaths.POSITION_CATEGORY({
                            organizationId: ':organizationId',
                            eventId: ':eventId',
                            positionCategoryId: ':positionCategoryId'
                        })}
                    >
                        <PositionCategoryLoader />
                    </Route>

                    <Route
                        path={PositionsPaths.POSITION({
                            organizationId: ':organizationId',
                            eventId: ':eventId',
                            positionId: ':positionId'
                        })}
                    >
                        <PositionLoader />
                    </Route>

                    <Route path={HeaventPaths.VOLUNTEERS(':organizationId', ':eventId')}>
                        <VolunteersLoader />
                    </Route>

                    {isEventAdmin && (
                        <Route
                            path={[
                                HeaventPaths.EDIT_USER(
                                    ':organizationId',
                                    ':eventId',
                                    ':userInfoId'
                                ),
                                HeaventPaths.EDIT_USER_DELEGATION(
                                    ':organizationId',
                                    ':eventId',
                                    ':delegationId',
                                    ':userInfoId'
                                )
                            ]}
                        >
                            <UpdateUserLoaderEvent />
                        </Route>
                    )}

                    {isEventAdmin && (
                        <Route
                            path={HeaventPaths.EDIT_USER_ACCREDITATIONS(
                                ':organizationId',
                                ':eventId',
                                ':userInfoId'
                            )}
                        >
                            <UserUpdateAccreditationsLoader />
                        </Route>
                    )}

                    {isEventAdmin && (
                        <Route
                            path={HeaventPaths.EDIT_USER_MISSIONS(
                                ':organizationId',
                                ':eventId',
                                ':userInfoId'
                            )}
                        >
                            <UserUpdateMissionsLoader />
                        </Route>
                    )}

                    {isEventAdmin && (
                        <Route
                            path={HeaventPaths.EDIT_USER_SLOTS(
                                ':organizationId',
                                ':eventId',
                                ':userInfoId'
                            )}
                        >
                            <UserUpdateSlotsLoader />
                        </Route>
                    )}

                    {hasFeature(Feature.Delegation) && isEventAdmin && (
                        <Route
                            path={DelegationsPaths.DELEGATIONS({
                                organizationId: ':organizationId',
                                eventId: ':eventId'
                            })}
                        >
                            <DelegationsHasFeature />
                        </Route>
                    )}

                    {hasFeature(Feature.Delegation) && isEventAdmin && (
                        <Route
                            path={DelegationsPaths.DELEGATION({
                                organizationId: ':organizationId',
                                eventId: ':eventId',
                                delegationId: ':delegationId'
                            })}
                        >
                            <DelegationLoader />
                        </Route>
                    )}

                    {isPositionAdmin && (
                        <Route path={HeaventPaths.ASSIGNMENTS(':organizationId', ':eventId')}>
                            <Assignments />
                        </Route>
                    )}

                    {isPositionAdmin && (
                        <Route
                            path={HeaventPaths.ASSIGNMENT(
                                ':organizationId',
                                ':eventId',
                                ':userInfoId'
                            )}
                        >
                            <Assignment isEdit={false} />
                        </Route>
                    )}

                    {isPositionAdmin && (
                        <Route
                            path={HeaventPaths.EDIT_ASSIGNMENT(
                                ':organizationId',
                                ':eventId',
                                ':userInfoId'
                            )}
                        >
                            <Assignment isEdit={true} />
                        </Route>
                    )}

                    {isEventAdmin && (
                        <Route
                            path={HeaventPaths.TEAM_ASSIGNMENT(
                                ':organizationId',
                                ':eventId',
                                ':teamCode'
                            )}
                        >
                            <TeamAssignment />
                        </Route>
                    )}

                    {isPositionAdmin && (
                        <Route
                            path={HeaventPaths.PRE_ASSIGNMENT(
                                ':organizationId',
                                ':eventId',
                                ':userInfoId'
                            )}
                        >
                            <Assignment isEdit={false} />
                        </Route>
                    )}

                    {isEventAdmin && (
                        <Route
                            path={HeaventPaths.ACCREDITATIONS_ASSIGNMENTS(
                                ':organizationId',
                                ':eventId'
                            )}
                        >
                            <AccreditationsAssignments />
                        </Route>
                    )}

                    {isEventAdmin && (
                        <Route
                            path={HeaventPaths.ACCREDIT(
                                ':organizationId',
                                ':eventId',
                                ':userInfoId'
                            )}
                        >
                            <Accredit isEdit={false} />
                        </Route>
                    )}

                    {isEventAdmin && (
                        <Route
                            path={HeaventPaths.EDIT_ACCREDIT(
                                ':organizationId',
                                ':eventId',
                                ':userInfoId'
                            )}
                        >
                            <Accredit isEdit={true} />
                        </Route>
                    )}

                    {isEventAdmin && (
                        <Route path={HeaventPaths.FORMS(':organizationId', ':eventId')}>
                            <EventForms />
                        </Route>
                    )}

                    {isPositionAdmin && (
                        <Route path={HeaventPaths.CAMPAIGNS(':organizationId', ':eventId')}>
                            <Campaigns />
                        </Route>
                    )}

                    {isPositionAdmin && (
                        <Route
                            path={HeaventPaths.CAMPAIGN(
                                ':organizationId',
                                ':eventId',
                                ':campaignId'
                            )}
                        >
                            <EventCampaign />
                        </Route>
                    )}

                    {isEventAdmin && (
                        <Route path={HeaventPaths.EVENT_EMAILS(':organizationId', ':eventId')}>
                            <Emails />
                        </Route>
                    )}

                    {isEventAdmin && (
                        <Route
                            exact
                            path={HeaventPaths.DOCUMENTS_USER_PLANNING(
                                ':organizationId',
                                ':eventId',
                                ':userInfoId'
                            )}
                        >
                            <UserPlanningDocument />
                        </Route>
                    )}

                    {isEventAdmin && (
                        <Route
                            exact
                            path={HeaventPaths.DOCUMENTS_USER_PLANNING_LIST(
                                ':organizationId',
                                ':eventId',
                                ':userInfoId'
                            )}
                        >
                            <UserPlanningListDocument />
                        </Route>
                    )}

                    {isEventAdmin && (
                        <Route
                            exact
                            path={HeaventPaths.DOCUMENTS_USER_PLANNING_DAYS(
                                ':organizationId',
                                ':eventId',
                                ':userInfoId'
                            )}
                        >
                            <UserPlanningDaysDocument />
                        </Route>
                    )}

                    {isEventAdmin && (
                        <Route
                            exact
                            path={HeaventPaths.DOCUMENTS_ASSIGNMENTS(
                                ':organizationId',
                                ':eventId',
                                ':userInfoId'
                            )}
                        >
                            <UserAssignmentsDocument />
                        </Route>
                    )}

                    <Route
                        exact
                        path={HeaventPaths.DOCUMENTS_VOLUNTEERS_LISTING(
                            ':organizationId',
                            ':eventId',
                            ':segmentId'
                        )}
                    >
                        <UsersListingDocument />
                    </Route>

                    {isEventAdmin && (
                        <Route
                            exact
                            path={HeaventPaths.DOCUMENTS_BADGE(
                                ':organizationId',
                                ':eventId',
                                ':userInfoId',
                                'generic'
                            )}
                        >
                            <UserBadgeDocument />
                        </Route>
                    )}

                    {isEventAdmin && (
                        <Route
                            exact
                            path={HeaventPaths.DOCUMENTS_BADGE(
                                ':organizationId',
                                ':eventId',
                                ':userInfoId',
                                ':badgeType'
                            )}
                        >
                            <UserCustomBadge />
                        </Route>
                    )}

                    {isEventAdmin && (
                        <Route
                            exact
                            path={HeaventPaths.DOCUMENTS_CERTIFICATE(
                                ':organizationId',
                                ':eventId',
                                ':userInfoId'
                            )}
                        >
                            <UserCertificateDocument />
                        </Route>
                    )}

                    {isEventAdmin && (
                        <Route
                            exact
                            path={HeaventPaths.DOCUMENTS_POSITION_PLANNING(
                                ':organizationId',
                                ':eventId',
                                ':positionId'
                            )}
                        >
                            <PositionPlanningDocument />
                        </Route>
                    )}

                    {isEventAdmin && (
                        <Route
                            exact
                            path={HeaventPaths.DOCUMENTS_POSITIONS_VOLUNTEERS_PLANNING(
                                ':organizationId',
                                ':eventId',
                                ':segmentId'
                            )}
                        >
                            <PositionsVolunteersPlanningDocument />
                        </Route>
                    )}

                    {isEventAdmin && (
                        <Route
                            exact
                            path={HeaventPaths.DOCUMENTS_POSITION(
                                ':organizationId',
                                ':eventId',
                                ':positionId'
                            )}
                        >
                            <PositionSheetDocument />
                        </Route>
                    )}

                    {isEventAdmin && (
                        <Route
                            exact
                            path={HeaventPaths.DOCUMENTS_PLANNING(
                                ':organizationId',
                                ':eventId',
                                ':segmentId'
                            )}
                        >
                            <PositionsPlanningDocument />
                        </Route>
                    )}

                    {isEventAdmin && (
                        <Route
                            exact
                            path={HeaventPaths.DOCUMENTS_USER_CUSTOM_DOCUMENT(
                                ':organizationId',
                                ':eventId',
                                ':userInfoId',
                                ':customDocumentId'
                            )}
                        >
                            <UserCustomDocument />
                        </Route>
                    )}

                    {isEventAdmin && (
                        <Route exact path={HeaventPaths.DOCUMENTS(':organizationId', ':eventId')}>
                            <DocumentsLoader />
                        </Route>
                    )}

                    {isEventAdmin && (
                        <Route path={HeaventPaths.API_WEEZEVENT(':organizationId', ':eventId')}>
                            <Weezevent />
                        </Route>
                    )}

                    {isEventAdmin && (
                        <Route path={HeaventPaths.API(':organizationId', ':eventId')}>
                            <Api />
                        </Route>
                    )}

                    {isEventAdmin && (
                        <Route path={HeaventPaths.ACCREDITATIONS(':organizationId', ':eventId')}>
                            <AccreditationsHasFeature />
                        </Route>
                    )}

                    {hasFeature(Feature.Accreditation) && isEventAdmin && (
                        <Route
                            path={AccreditationsPaths.ACCREDITATION_SLOT({
                                organizationId: ':organizationId',
                                eventId: ':eventId',
                                accreditationId: ':accreditationId',
                                accreditationSlotId: ':accreditationsSlotId'
                            })}
                        >
                            <AccreditationsSlot />
                        </Route>
                    )}

                    {hasFeature(Feature.Accreditation) && isEventAdmin && (
                        <Route
                            path={AccreditationsPaths.ACCREDITATION({
                                organizationId: ':organizationId',
                                eventId: ':eventId',
                                accreditationId: ':accreditationId'
                            })}
                        >
                            <AccreditationLoader />
                        </Route>
                    )}

                    {hasFeature(Feature.Accreditation) && isEventAdmin && (
                        <Route
                            path={AccreditationsPaths.ACCREDITATION_CATEGORY({
                                organizationId: ':organizationId',
                                eventId: ':eventId',
                                accreditationCategoryId: ':accreditationsCategoryId'
                            })}
                        >
                            <AccreditationCategoryLoader />
                        </Route>
                    )}

                    <Route
                        path={HeaventPaths.EVENT_USER_PROFILE(
                            ':organizationId',
                            ':eventId',
                            ':userInfoId'
                        )}
                    >
                        <UserProfile />
                    </Route>

                    <Route>
                        <Empty
                            path={HeaventPaths.DASHBOARD(organizationId, eventId)}
                            replace={true}
                        />
                    </Route>
                </Switch>
            </Box>
        </Flex>
    );
};

interface IEventRoutesProps {
    event: EventQuery['event'];
}

const EventRoutes = (props: IEventRoutesProps) => {
    const isEventAdmin = props.event.permissions.isEventAdmin;
    const isPositionCategoryAdmin = Rules.isPositionCategoryAdmin(props.event);
    const isPositionAdmin = Rules.isPositionAdmin(props.event);

    return (
        <Switch>
            {isPositionCategoryAdmin && (
                <Route
                    exact
                    path={HeaventPaths.CREATE_POSITION(':organizationId', ':eventId', ':options?')}
                >
                    <CreatePosition />
                </Route>
            )}

            {isPositionAdmin && (
                <Route
                    exact
                    path={HeaventPaths.EDIT_POSITION(':organizationId', ':eventId', ':positionId')}
                >
                    <UpdatePosition />
                </Route>
            )}

            {isPositionAdmin && (
                <Route
                    path={HeaventPaths.POSITION_SLOT_ASSIGN_VOLUNTEERS(
                        ':organizationId',
                        ':eventId',
                        ':positionId',
                        ':positionSlotId'
                    )}
                >
                    <PositionsSlotsAssignVolunteers />
                </Route>
            )}

            {isPositionAdmin && (
                <Route
                    exact
                    path={HeaventPaths.CREATE_CAMPAIGN(':organizationId', ':eventId', ':options?')}
                >
                    <CreateCampaign />
                </Route>
            )}

            {isPositionAdmin && (
                <Route
                    exact
                    path={HeaventPaths.EDIT_CAMPAIGN(':organizationId', ':eventId', ':campaignId')}
                >
                    <UpdateCampaign />
                </Route>
            )}

            {isEventAdmin && (
                <Route
                    exact
                    path={HeaventPaths.CREATE_ACCREDITATION(
                        ':organizationId',
                        ':eventId',
                        ':options?'
                    )}
                >
                    <CreateAccreditation />
                </Route>
            )}

            {isEventAdmin && (
                <Route
                    exact
                    path={HeaventPaths.EDIT_ACCREDITATION(
                        ':organizationId',
                        ':eventId',
                        ':accreditationId'
                    )}
                >
                    <UpdateAccreditation />
                </Route>
            )}

            {isEventAdmin && (
                <Route
                    path={HeaventPaths.ACCREDITATION_ACCREDIT(
                        ':organizationId',
                        ':eventId',
                        ':accreditationId'
                    )}
                >
                    <AccreditationsAccredit />
                </Route>
            )}

            {isEventAdmin && (
                <Route
                    path={HeaventPaths.EDIT_CUSTOM_DOCUMENT(
                        ':organizationId',
                        ':eventId',
                        ':customDocumentId'
                    )}
                >
                    <DocumentBuilder />
                </Route>
            )}

            {isEventAdmin && (
                <Route
                    exact
                    path={HeaventPaths.CREATE_DELEGATION(
                        ':organizationId',
                        ':eventId',
                        ':options?'
                    )}
                >
                    <CreateEventDelegation />
                </Route>
            )}

            {isEventAdmin && (
                <Route
                    exact
                    path={HeaventPaths.EDIT_DELEGATION(
                        ':organizationId',
                        ':eventId',
                        ':delegationId'
                    )}
                >
                    <UpdateEventDelegation />
                </Route>
            )}

            {isEventAdmin && (
                <Route path={HeaventPaths.FORM(':organizationId', ':eventId', ':formId')}>
                    <EventForm />
                </Route>
            )}

            {isEventAdmin && (
                <Route path={HeaventPaths.EVENT_DELEGATIONS_IMPORT(':organizationId', ':eventId')}>
                    <DelegationsImport />
                </Route>
            )}

            {isEventAdmin && (
                <Route path={HeaventPaths.EVENT_VOLUNTEERS_IMPORT(':organizationId', ':eventId')}>
                    <VolunteersImport />
                </Route>
            )}

            {isEventAdmin && (
                <Route
                    path={PositionsPaths.POSITION_IMPORT_CSV({
                        organizationId: ':organizationId',
                        eventId: ':eventId'
                    })}
                >
                    <PositionsCsvImport />
                </Route>
            )}

            {isEventAdmin && (
                <Route path={HeaventPaths.EVENT_FILES_IMPORT(':organizationId', ':eventId')}>
                    <FilesImport />
                </Route>
            )}

            <Route>
                <OrganizationComponent>
                    <EventComponent event={props.event} />
                </OrganizationComponent>
            </Route>
        </Switch>
    );
};

export const Event = () => {
    const { eventId } = useParams();
    const { data, loader } = useEventQuery({ eventId });
    const { setSelectedEventId } = useOrganizationContext();
    const onMobile = useMobileQuery();

    React.useEffect(() => {
        setSelectedEventId(eventId);

        return () => {
            setSelectedEventId();
        };
    }, [eventId]);

    if (loader) {
        return loader;
    } else {
        const assignmentsStats = data.event.assignmentsStats;

        return (
            <EventContextProvider
                event={data.event}
                numberOfWaitingAccreditations={
                    data.event.accreditationsStats.numberOfWaitingAccreditation
                }
                numberOfWaitingAssigments={
                    data.event.permissions.isEventAdmin
                        ? assignmentsStats.numberOfWaitingAssignment +
                          assignmentsStats.numberOfPreAssigned
                        : assignmentsStats.numberOfPreAssigned
                }
            >
                {onMobile ? <EventMobile /> : <EventRoutes event={data.event} />}
            </EventContextProvider>
        );
    }
};
