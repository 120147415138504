import { Flex } from 'common/src/designSystem/components/flex';
import { Skeleton } from 'common/src/designSystem/components/skeleton';
import { Cell } from 'common/src/designSystem/components/table/cell';
import {
    AccreditationsSlotId,
    AccreditationState,
    EventId,
    MassAssignStrategy,
    VolunteerRegistrationFragment
} from 'common/src/generated/types';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { isNonEmptyArray } from 'common/src/util/array';
import { useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import { getAccreditationsBadges } from 'common/src/vo/accreditation';
import { fullName } from 'common/src/vo/accreditationSlot';
import { groupBy, sortBy } from 'lodash-es';
import * as React from 'react';
import { BadgesPlus } from '../../../components/badgesPlus/badgesPlus';
import { UpdateCellDropdown } from '../../../components/cells/updateCellDropdown';
import { RichSelect } from '../../../designSystem/components/richSelect/richSelect';
import {
    executeUpdateCellAccreditationsSlotsQuery,
    useVolunteersRegistrationsMassAccreditationStateUpdateMutation,
    useVolunteersRegistrationsMassAccreditMutation
} from '../../../generated/graphqlHooks';
import { useRichTableContext } from '../../../richTable/richTableContext';
import { useSegmentsContext } from '../../../segments/segmentsContext';
import { getToken } from '../../../util/aws/cognito';

interface IVolunteerRegistrationAccreditationsProps {
    eventId: EventId;
    volunteerRegistration: VolunteerRegistrationFragment;

    onClick(): void;
    reload(): void;
}

export const VolunteerRegistrationAccreditations = ({
    eventId,
    onClick,
    reload,
    volunteerRegistration
}: IVolunteerRegistrationAccreditationsProps) => {
    const translate = useTranslate();
    const { mutate: massAccredit } = useVolunteersRegistrationsMassAccreditMutation();
    const { mutate: stateUpdate } =
        useVolunteersRegistrationsMassAccreditationStateUpdateMutation();
    const dateTimeService = useService(DateTimeService);
    // TODO: temporary solution
    // TODO: use only useRichTableContext() once refacto is done
    const { isEditMode: segmentsIsEditMode } = useSegmentsContext();
    const { isEditMode: richTableIsEditMode } = useRichTableContext();
    const isEditMode = segmentsIsEditMode || richTableIsEditMode;
    const [isFirstTime, setIsFirstTime] = React.useState(true);
    const [accreditations, setAccreditations] = React.useState<any[]>([]);
    const auis = React.useMemo(
        () => volunteerRegistration?.accreditationsUsersInfos || [],
        [volunteerRegistration]
    );
    const badges = React.useMemo(() => getAccreditationsBadges(auis), [auis]);
    const initialValue = React.useMemo(() => auis.map((aui) => aui.accreditationSlot.id), [auis]);
    const onStateChange = React.useCallback(
        async (isOpen) => {
            if (isOpen && isFirstTime) {
                const {
                    event: { accreditationsSlots }
                } = await executeUpdateCellAccreditationsSlotsQuery(
                    { eventId: eventId },
                    await getToken()
                );
                const slots = sortBy(accreditationsSlots.nodes, ['date', 'name']).map((s) => ({
                    id: s.id,
                    accreditationName: s.accreditation.name,
                    name: fullName(dateTimeService, s, s.accreditation.name, {
                        includeAccreditationNameAtEnd: true
                    })
                }));

                setAccreditations(
                    sortBy(
                        Object.entries(groupBy(slots, (s) => s.accreditationName)),
                        ([accreditationName]) => accreditationName
                    )
                );
                setIsFirstTime(false);
            }
        },
        [isFirstTime]
    );
    const onSave = React.useCallback(
        async (value: AccreditationsSlotId[]) => {
            if (isNonEmptyArray(value)) {
                await massAccredit({
                    eventId,
                    massAccredit: {
                        accreditationsSlotsIds: value,
                        selecteds: { ids: [volunteerRegistration.userInfo.id] },
                        strategy: MassAssignStrategy.Replace
                    }
                });
            } else {
                await stateUpdate({
                    eventId,
                    massStateUpdate: {
                        selecteds: { ids: [volunteerRegistration.userInfo.id] },
                        state: AccreditationState.WaitingAccreditation
                    }
                });
            }

            reload();
        },
        [eventId, volunteerRegistration, massAccredit, reload]
    );

    if (isEditMode) {
        return (
            <UpdateCellDropdown
                css={{ gap: '$2' }}
                initialValue={initialValue}
                renderInput={(value, setValue) =>
                    isFirstTime ? (
                        <Flex direction="column" gap="1" width={1}>
                            <Skeleton borderRadius="$1" height={20} width={1} />
                            <Skeleton borderRadius="$1" height={40} width={1} />
                        </Flex>
                    ) : (
                        <RichSelect
                            isSearchVisible={true}
                            label={translate('accr_ditations_39450')}
                            multiple={true}
                            renderOnPortal={true}
                            values={value}
                            onChange={setValue}
                        >
                            {accreditations.map(([accreditationName, slots], index) => (
                                <optgroup key={index} label={accreditationName}>
                                    {slots.map((slot: any) => (
                                        <option key={slot.id} value={slot.id}>
                                            {slot.name}
                                        </option>
                                    ))}
                                </optgroup>
                            ))}
                        </RichSelect>
                    )
                }
                renderValue={() => <BadgesPlus badges={badges} doNotSort={true} />}
                onSave={onSave}
                onStateChange={onStateChange}
            />
        );
    } else {
        return (
            <Cell css={{ border: '2px solid transparent' }}>
                <BadgesPlus badges={badges} doNotSort={true} onClick={onClick} />
            </Cell>
        );
    }
};
