import { Select } from 'common-front/src/designSystem/components/select/select';
import { AvatarNameEmail } from 'common/src/components/avatarNameEmail';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { LeaderInfosFragment, Role, RoleId } from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';

interface ILeaderProps {
    leader: LeaderInfosFragment;
    roles: Array<Pick<Role, 'id' | 'name' | 'type'>>;

    onRoleChange(id: RoleId): void;
    onDelete(): void;
}

export const Leader = (props: ILeaderProps) => {
    const translate = useTranslate();

    return (
        <Flex align="center" gap="5">
            <AvatarNameEmail
                hasName={isNonEmptyString(props.leader.userInfo.name)}
                userInfo={props.leader.userInfo}
            />

            <Box>
                <Select
                    shouldParseAsInt={true}
                    value={props.leader.role.id}
                    onChange={(id: RoleId) => {
                        props.onRoleChange(id);
                    }}
                >
                    {props.roles.map((role, i) => (
                        <option key={i} value={role.id}>
                            {translate(role.name)}
                        </option>
                    ))}
                </Select>
            </Box>

            <Box color="gray500" css={{ cursor: 'pointer' }} onClick={props.onDelete}>
                <I icon="trash-can" />
            </Box>
        </Flex>
    );
};
