import { FullScreenPopup } from 'common-front/src/components/fullScreenPopup/fullScreenPopup';
import { Button } from 'common-front/src/designSystem/components/button';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { CustomDocumentInputService } from 'common/src/input/customDocumentInput';
import { useService } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';
import { useCustomDocumentQuery, useCustomDocumentUpdateMutation } from '../generated/graphqlHooks';
import {
    DocumentBuilderContextProvider,
    useDocumentBuilderContext
} from './documentBuilderContext';
import { DocumentBuilderLeftPanel } from './leftPanel/documentBuilderLeftPanel';
import { NotSavedModal } from './notSavedModal';
import { DocumentBuilderPages } from './pages/documentBuilderPages';
import { DocumentBuilderRightPanel } from './rightPanel/documentBuilderRightPanel';

const DocumentBuilderComponent = () => {
    const {
        history,
        params: { organizationId, eventId, customDocumentId },
        translate
    } = useHeavent();
    const { mutate: customDocumentUpdate, isLoading: isCustomDocumentUpdateLoading } =
        useCustomDocumentUpdateMutation();
    const { customDocument } = useDocumentBuilderContext();
    const initialDocumentString = React.useMemo(
        () => JSON.stringify(customDocument),
        [isCustomDocumentUpdateLoading]
    );
    const [isNotSavedModalOpen, setIsNotSavedModalOpen] = React.useState(false);
    const onClose = React.useCallback(() => {
        const documentString = JSON.stringify(customDocument);

        if (documentString === initialDocumentString) {
            history.goBack(HeaventPaths.DOCUMENTS(organizationId, eventId));
        } else {
            setIsNotSavedModalOpen(true);
        }
    }, [customDocument, initialDocumentString]);

    return (
        <>
            <FullScreenPopup
                button={
                    <Button
                        isLoading={isCustomDocumentUpdateLoading}
                        onClick={async () => {
                            await customDocumentUpdate({
                                eventId,
                                customDocumentId,
                                customDocument
                            });
                        }}
                    >
                        {translate('enregistrer_06519')}
                    </Button>
                }
                category={translate('document_person_69340')}
                color="dark"
                title={customDocument.name}
                onClose={onClose}
            >
                <Flex height={1} width={1}>
                    <Box height={1} width={420}>
                        <DocumentBuilderLeftPanel />
                    </Box>

                    <Box css={{ flex: '1' }} height={1}>
                        <DocumentBuilderPages />
                    </Box>

                    <Box height={1} width={340}>
                        <DocumentBuilderRightPanel />
                    </Box>
                </Flex>
            </FullScreenPopup>

            <NotSavedModal
                isOpen={isNotSavedModalOpen}
                onClose={() => setIsNotSavedModalOpen(false)}
            />
        </>
    );
};

export const DocumentBuilder = () => {
    const {
        history,
        params: { organizationId, eventId, customDocumentId }
    } = useHeavent();
    const { data, loader } = useCustomDocumentQuery({ organizationId, eventId, customDocumentId });
    const customDocumentInput = useService(CustomDocumentInputService);

    if (!loader && !data.event?.customDocument) {
        history.replace(HeaventPaths.DOCUMENTS(organizationId, eventId));
    }

    return (
        loader || (
            <DocumentBuilderContextProvider
                customFields={data.organization.customFields.nodes}
                initialCustomDocument={customDocumentInput.customDocumentInputDefault(
                    data.event.customDocument
                )}
                population={data.organization.population}
            >
                <DocumentBuilderComponent />
            </DocumentBuilderContextProvider>
        )
    );
};
