import { BackButton } from 'common-front/src/components/buttons/backButton';
import { Button } from 'common-front/src/designSystem/components/button';
import { useEventDocumentsExportMutation } from 'common-front/src/generated/graphqlHooks';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import {
    CustomBadge,
    DocumentType,
    EventId,
    EventVolunteersExportFragment,
    OrganizationId,
    SupportedLanguage
} from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import { DateTime } from 'luxon';
import * as React from 'react';
import { ExportAllModal } from './exportAllModal';

interface IDocumentHeaderProps {
    badgeType?: CustomBadge;
    customDocumentSlug?: string;
    extraButton?: React.ReactNode;
    documentType: DocumentType;
    endAt?: DateTime;
    eventId: EventId;
    languages: SupportedLanguage[];
    organizationId: OrganizationId;
    segmentsFolders: EventVolunteersExportFragment['segmentsFolders'];
    showDate?: boolean;
    showStrategy?: boolean;
    startAt?: DateTime;
    title: string;
}

export const DocumentHeader = (props: IDocumentHeaderProps) => {
    const translate = useTranslate();
    const { mutate, isLoading } = useEventDocumentsExportMutation();
    const [isExportAllOpen, setIsExportAllOpen] = React.useState(false);

    return (
        <Flex align="center" gap="3">
            <BackButton
                returnPathFallback={HeaventPaths.DOCUMENTS(props.organizationId, props.eventId)}
            />

            <Box color="gray900" css={{ flex: '1' }} fontSize="displayXs" fontWeight="medium">
                {props.title}
            </Box>

            {props.extraButton}

            <Button
                isLoading={isLoading}
                onClick={() => {
                    setIsExportAllOpen(true);
                }}
            >
                {translate('param_trer_l_ex_83342')}
            </Button>

            {isExportAllOpen && (
                <ExportAllModal
                    badgeType={props.badgeType}
                    endAt={props.endAt}
                    isSendLoading={isLoading}
                    languages={props.languages}
                    segmentsFolders={props.segmentsFolders}
                    showDate={props.showDate}
                    showStrategy={props.showStrategy}
                    startAt={props.startAt}
                    title={translate('envoi_de_vos_do_32953')}
                    onClose={() => {
                        setIsExportAllOpen(false);
                    }}
                    onSend={async (segmentsIds, language, strategy, date) => {
                        const { eventDocumentsExport } = await mutate({
                            eventId: props.eventId,
                            documentsExport: {
                                documentType: props.documentType,
                                badgeType: props.badgeType,
                                customDocumentSlug: props.customDocumentSlug,
                                segmentsIds,
                                language,
                                strategy,
                                date
                            }
                        });

                        return eventDocumentsExport;
                    }}
                />
            )}
        </Flex>
    );
};
