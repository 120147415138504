import { Badge } from 'common/src/designSystem/components/badge';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { AccreditationState, EventId, VolunteersRegistrationId } from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { PossibleColumn } from 'common/src/vo/segment';
import { getAccreditationStateBadgeColorIcon } from 'common/src/vo/volunteerRegistration';
import * as React from 'react';
import { Dropdown } from '../../../designSystem/components/dropdown/dropdown';
import { Menu } from '../../../designSystem/components/dropdown/menu';
import { Trigger } from '../../../designSystem/components/dropdown/trigger';
import { useVolunteerRegistrationUpdateFieldMutation } from '../../../generated/graphqlHooks';
import { useRichTableContext } from '../../../richTable/richTableContext';
import { useSegmentsContext } from '../../../segments/segmentsContext';

interface IDropdownCellProps {
    eventId: EventId;
    state: AccreditationState;
    volunteerRegistrationId: VolunteersRegistrationId;

    close(): void;
    reload(): void;
}

const DropdownCell = (props: IDropdownCellProps) => {
    const translate = useTranslate();
    const { mutate } = useVolunteerRegistrationUpdateFieldMutation();

    return (
        <Flex
            css={{
                cursor: 'pointer',
                padding: '$3',
                '&:hover': {
                    background: '$gray100'
                }
            }}
            onClick={async () => {
                await mutate({
                    eventId: props.eventId,
                    volunteerRegistrationId: props.volunteerRegistrationId,
                    slug: 'accreditation_state',
                    value: props.state
                });

                props.reload();
                props.close();
            }}
        >
            <Badge {...getAccreditationStateBadgeColorIcon(props.state)}>
                {translate(props.state)}
            </Badge>
        </Flex>
    );
};

interface IVolunteerRegistrationAccreditationStateCellProps {
    eventId: EventId;
    field: PossibleColumn;
    state: AccreditationState;
    volunteerRegistrationId: VolunteersRegistrationId;

    reload(): void;
}

export const VolunteerRegistrationAccreditationStateCell = (
    props: IVolunteerRegistrationAccreditationStateCellProps
) => {
    const translate = useTranslate();
    // TODO: temporary solution
    // TODO: use only useRichTableContext() once refacto is done
    const { isEditMode: segmentsIsEditMode } = useSegmentsContext();
    const { isEditMode: richTableIsEditMode } = useRichTableContext();
    const isEditMode = segmentsIsEditMode || richTableIsEditMode;
    const [isOpen, _setIsOpen] = React.useState(false);
    const setIsOpen = React.useCallback(
        (newIsOpen: boolean) => {
            if (isEditMode) {
                _setIsOpen(newIsOpen);
            }
        },
        [isEditMode, _setIsOpen]
    );
    const close = React.useCallback(() => {
        setIsOpen(false);
    }, [setIsOpen]);

    if (isEditMode) {
        return (
            <Cell
                css={{
                    border: '2px solid transparent',
                    cursor: 'text',
                    '&:hover': {
                        border: '2px solid $primary700'
                    }
                }}
                width={240}
                onClick={(e) => {
                    e.stopPropagation();
                    e.nativeEvent.stopImmediatePropagation();
                }}
            >
                <Dropdown isOpen={isOpen} onStateChange={setIsOpen}>
                    <Trigger>
                        <Flex align="center" gap="3" height={1} justify="between" width={1}>
                            <Badge
                                {...getAccreditationStateBadgeColorIcon(props.state)}
                                cursor="text"
                            >
                                {translate(props.state)}
                            </Badge>

                            {isOpen && (
                                <Box>
                                    <I icon="chevron-down" />
                                </Box>
                            )}
                        </Flex>
                    </Trigger>

                    <Menu placement="bottom" width={240}>
                        {props.state !== AccreditationState.WaitingAccreditation && (
                            <DropdownCell
                                close={close}
                                eventId={props.eventId}
                                reload={props.reload}
                                state={AccreditationState.WaitingAccreditation}
                                volunteerRegistrationId={props.volunteerRegistrationId}
                            />
                        )}

                        {props.state !== AccreditationState.NotApplicable && (
                            <DropdownCell
                                close={close}
                                eventId={props.eventId}
                                reload={props.reload}
                                state={AccreditationState.NotApplicable}
                                volunteerRegistrationId={props.volunteerRegistrationId}
                            />
                        )}
                    </Menu>
                </Dropdown>
            </Cell>
        );
    } else {
        return (
            <Cell
                css={{
                    border: '2px solid transparent'
                }}
                width={240}
            >
                <Badge {...getAccreditationStateBadgeColorIcon(props.state)}>
                    {translate(props.state)}
                </Badge>
            </Cell>
        );
    }
};
