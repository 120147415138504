import {
    RegisterDaysDisplay,
    VolunteeersSegmentsCustomSlotsPeriodsQuery,
    VolunteersSegmentsAccreditationsSlotsQuery,
    VolunteersSegmentsPositionsSlotsQuery
} from 'common/src/generated/types';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { IntervalService } from 'common/src/services/intervalService';
import { isNonEmptyArray } from 'common/src/util/array';
import { useService } from 'common/src/util/dependencies/dependencies';
import { LocaleFormats } from 'common/src/util/luxon';
import { fullName } from 'common/src/vo/accreditationSlot';
import { fullNameWithPositionName } from 'common/src/vo/positionSlot';
import { Filter, FilterPredicate } from 'common/src/vo/segment';
import { getIntervals, intervalFinalName } from 'common/src/vo/volunteerRegistrationSlot';
import { groupBy, sortBy } from 'lodash-es';
import * as React from 'react';
import {
    executeConditionsCustomFieldsQuery,
    executeTagsQuery,
    executeUsersSegmentsCampaignsQuery,
    executeUsersSegmentsDelegationsQuery,
    executeUsersSegmentsEventsQuery,
    executeUsersSegmentsFormsQuery,
    executeVolunteeersSegmentsCustomSlotsPeriodsQuery,
    executeVolunteersSegmentsAccreditationsCategoriesQuery,
    executeVolunteersSegmentsAccreditationsQuery,
    executeVolunteersSegmentsAccreditationsSegmentsQuery,
    executeVolunteersSegmentsAccreditationsSlotsQuery,
    executeVolunteersSegmentsCampaignsQuery,
    executeVolunteersSegmentsDelegationsQuery,
    executeVolunteersSegmentsDelegationsSegmentsQuery,
    executeVolunteersSegmentsFormsQuery,
    executeVolunteersSegmentsPositionsCategoriesQuery,
    executeVolunteersSegmentsPositionsQuery,
    executeVolunteersSegmentsPositionsSegmentsQuery,
    executeVolunteersSegmentsPositionsSlotsQuery
} from '../../../generated/graphqlHooks';
import { getToken } from '../../../util/aws/cognito';

export const getPositionsSlotsValues = (
    intervalService: IntervalService,
    positionsSlots: VolunteersSegmentsPositionsSlotsQuery['event']['positionsSlots']['nodes']
) =>
    sortBy(
        Object.values(groupBy(positionsSlots, (ps) => ps.position.name)).map((slots) => ({
            name: slots[0].position.name,
            values: sortBy(
                slots.map((slot) => ({
                    id: slot.id,
                    name: fullNameWithPositionName(intervalService, slot, slot.position.name),
                    startTime: slot.range.start!.toMillis()
                })),
                ({ startTime }) => startTime
            )
        })),
        ({ name }) => name
    );

export const getAccreditationsSlotsValues = (
    dateTimeService: DateTimeService,
    accreditationsSlots: VolunteersSegmentsAccreditationsSlotsQuery['event']['accreditationsSlots']['nodes']
) =>
    sortBy(
        Object.values(groupBy(accreditationsSlots, (as) => as.accreditation.name)).map((slots) => ({
            name: slots[0].accreditation.name,
            values: sortBy(
                slots.map((slot) => ({
                    id: slot.id,
                    name: fullName(dateTimeService, slot, slot.accreditation.name, {
                        includeAccreditationNameAtEnd: true
                    })
                })),
                (s) => s.name
            )
        })),
        ({ name }) => name
    );

const getCustomSlotsPeriodsValues = (
    dateTimeService: DateTimeService,
    event: VolunteeersSegmentsCustomSlotsPeriodsQuery['event']
) => {
    const intervals = event.customSlotsPeriods.flatMap((customSlotPeriod) =>
        getIntervals(
            customSlotPeriod.startDate ?? event.startAt,
            customSlotPeriod.endDate ?? event.endAt,
            customSlotPeriod.customSlots,
            event.ranges,
            RegisterDaysDisplay.AllDays
        )
    );

    return sortBy(
        Object.values(
            groupBy(intervals, ({ interval }) => interval.start!.startOf('day').toMillis())
        ),
        (intervals) => intervals[0].interval.start!.startOf('day').toMillis()
    ).map((intervals) => {
        const interval = intervals[0].interval;

        return {
            name: dateTimeService.toLocaleString(
                interval.start!.startOf('day'),
                LocaleFormats.DateOnly.WeekdayLongMonthLong
            ),
            values: intervals.map(({ name, interval }) => ({
                id: interval.toISO(),
                name: intervalFinalName(name, interval)
            }))
        };
    });
};

const loadData = async (
    dateTimeService: DateTimeService,
    intervalService: IntervalService,
    filter: Filter
) => {
    const token = await getToken();

    if (filter.eventId) {
        if (['preassignedPosition', 'position', 'wishedPositions'].includes(filter.slug)) {
            const { event } = await executeVolunteersSegmentsPositionsQuery(
                { eventId: filter.eventId },
                token
            );

            filter.valuesGroups = sortBy(
                Object.entries(groupBy(event.positions.nodes, (p) => p.positionCategoryName)).map(
                    ([_, positions]) => ({
                        name: positions[0].positionCategoryName,
                        values: sortBy(positions, (p) => p.name.toLowerCase())
                    })
                ),
                ({ name }) => name.toLowerCase()
            );
            filter.needLoading = false;
        } else if (['positionSlotId', 'wishedPositionsSlots'].includes(filter.slug)) {
            const { event } = await executeVolunteersSegmentsPositionsSlotsQuery(
                { eventId: filter.eventId },
                token
            );

            filter.valuesGroups = getPositionsSlotsValues(
                intervalService,
                event.positionsSlots.nodes
            );
            filter.needLoading = false;
        } else if (
            [
                'positionCategory',
                'preassignedPositionCategory',
                'wishedPositionsCategories'
            ].includes(filter.slug)
        ) {
            const { event } = await executeVolunteersSegmentsPositionsCategoriesQuery(
                { eventId: filter.eventId },
                token
            );

            filter.values = sortBy(event.positionsCategories, (c) => c.name.toLowerCase());
            filter.needLoading = false;
        } else if (filter.slug === 'wishedSlotsCustom') {
            const { event } = await executeVolunteeersSegmentsCustomSlotsPeriodsQuery(
                { eventId: filter.eventId },
                token
            );

            filter.valuesGroups = getCustomSlotsPeriodsValues(dateTimeService, event);
            filter.needLoading = false;
        } else if (filter.slug === 'accreditation') {
            const { event } = await executeVolunteersSegmentsAccreditationsQuery(
                { eventId: filter.eventId },
                token
            );

            filter.valuesGroups = sortBy(
                Object.entries(
                    groupBy(event.accreditations.nodes, (a) => a.accreditationCategoryName)
                ).map(([_, accreditations]) => ({
                    name: accreditations[0].accreditationCategoryName,
                    values: sortBy(accreditations, (a) => a.name.toLowerCase())
                })),
                ({ name }) => name.toLowerCase()
            );
            filter.needLoading = false;
        } else if (filter.slug === 'accreditationCategory') {
            const { event } = await executeVolunteersSegmentsAccreditationsCategoriesQuery(
                { eventId: filter.eventId },
                token
            );

            filter.values = sortBy(event.accreditationsCategories, (c) => c.name.toLowerCase());
            filter.needLoading = false;
        } else if (filter.slug === 'positionsSegments') {
            const { event } = await executeVolunteersSegmentsPositionsSegmentsQuery(
                { eventId: filter.eventId },
                token
            );

            filter.values = sortBy(event.segments, (s) => s.name.toLowerCase());
            filter.needLoading = false;
        } else if (filter.slug === 'accreditationsSegments') {
            const { event } = await executeVolunteersSegmentsAccreditationsSegmentsQuery(
                { eventId: filter.eventId },
                token
            );

            filter.values = sortBy(event.segments, (s) => s.name.toLowerCase());
            filter.needLoading = false;
        } else if (filter.slug === 'delegationsSegments') {
            const { event } = await executeVolunteersSegmentsDelegationsSegmentsQuery(
                { eventId: filter.eventId },
                token
            );

            filter.values = sortBy(event.segments, (s) => s.name.toLowerCase());
            filter.needLoading = false;
        } else if (
            filter.slug === 'forms' ||
            filter.slug === 'form' ||
            filter.slug === 'formsInsertedAt'
        ) {
            const { event } = await executeVolunteersSegmentsFormsQuery(
                { eventId: filter.eventId },
                token
            );

            filter.values = sortBy(event.forms, (f) => f.name.toLowerCase());
            filter.needLoading = false;
        } else if (filter.slug === 'campaign') {
            const { event } = await executeVolunteersSegmentsCampaignsQuery(
                { eventId: filter.eventId },
                token
            );

            filter.campaigns = sortBy(event.campaigns.nodes, (c) => c.name.toLowerCase());
            filter.needLoading = false;
        } else if (filter.slug === 'delegation') {
            const { event } = await executeVolunteersSegmentsDelegationsQuery(
                { eventId: filter.eventId },
                token
            );

            filter.values = sortBy(event.delegations.nodes, (d) => d.name.toLowerCase());
            filter.needLoading = false;
        } else if (['accreditationSlotId', 'wishedAccreditationsSlots'].includes(filter.slug)) {
            const { event } = await executeVolunteersSegmentsAccreditationsSlotsQuery(
                { eventId: filter.eventId },
                token
            );

            filter.valuesGroups = getAccreditationsSlotsValues(
                dateTimeService,
                event.accreditationsSlots.nodes
            );
            filter.needLoading = false;
        }
    } else if (filter.organizationId) {
        if (filter.slug === 'event') {
            const { organization } = await executeUsersSegmentsEventsQuery(
                { organizationId: filter.organizationId },
                token
            );

            filter.values = sortBy(organization.events.nodes, (e) => e.name.toLowerCase());
            filter.needLoading = false;
        } else if (filter.slug === 'forms' || filter.slug === 'formsInsertedAt') {
            const { organization } = await executeUsersSegmentsFormsQuery(
                { organizationId: filter.organizationId },
                token
            );

            filter.values = sortBy(organization.forms, (f) => f.name.toLowerCase());
            filter.needLoading = false;
        } else if (filter.slug === 'campaign') {
            const { organization } = await executeUsersSegmentsCampaignsQuery(
                { organizationId: filter.organizationId },
                token
            );

            filter.campaigns = sortBy(organization.campaigns.nodes, (c) => c.name.toLowerCase());
            filter.needLoading = false;
        } else if (filter.slug === 'tag') {
            const { organization } = await executeTagsQuery(
                { organizationId: filter.organizationId },
                token
            );

            filter.values = sortBy(organization.tags.nodes, (t) => t.name.toLowerCase());
            filter.needLoading = false;
        } else if (
            filter.slug === 'positionCustomField' ||
            filter.slug === 'accreditationCustomField'
        ) {
            const { organization } = await executeConditionsCustomFieldsQuery(
                {
                    organizationId: filter.organizationId
                },
                token
            );

            filter.values = sortBy(organization.customFields.nodes, (cf) => cf.name.toLowerCase());
            filter.needLoading = false;
        } else if (filter.slug === 'delegation') {
            const { organization } = await executeUsersSegmentsDelegationsQuery(
                { organizationId: filter.organizationId },
                token
            );

            filter.valuesGroups = sortBy(
                Object.entries(
                    groupBy(organization.delegations.nodes, (d) => d.event?.id || -1)
                ).map(([_, delegations]) => ({
                    name: delegations[0].event?.name ?? organization.name,
                    values: sortBy(delegations, (d) => d.name.toLowerCase())
                })),
                ({ name }) => name.toLowerCase()
            );
            filter.needLoading = false;
        }
    }
};

export function useFilterLoader(filter: Filter) {
    const dateTimeService = useService(DateTimeService);
    const intervalService = useService(IntervalService);
    const [isLoading, setIsLoading] = React.useState(false);

    React.useEffect(() => {
        if (filter.needLoading) {
            setIsLoading(true);

            loadData(dateTimeService, intervalService, filter).then(() => {
                setIsLoading(false);
            });
        }
    }, [filter.needLoading]);

    return isLoading;
}

export function useFiltersLoader(filtersPredicates: FilterPredicate[]) {
    const dateTimeService = useService(DateTimeService);
    const intervalService = useService(IntervalService);
    const [isLoading, setIsLoading] = React.useState(false);

    React.useEffect(() => {
        const filtersNeedLoading = filtersPredicates.filter(({ filter }) => filter.needLoading);

        if (isNonEmptyArray(filtersNeedLoading)) {
            setIsLoading(true);

            Promise.all(
                filtersNeedLoading.map(({ filter }) =>
                    loadData(dateTimeService, intervalService, filter)
                )
            ).then(() => {
                setIsLoading(false);
            });
        }
    }, [filtersPredicates]);

    return isLoading;
}
