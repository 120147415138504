import { Button } from 'common-front/src/designSystem/components/button';
import { IconBackground } from 'common-front/src/designSystem/components/iconBackground';
import { Modal } from 'common-front/src/designSystem/components/modal';
import { Select } from 'common-front/src/designSystem/components/select/select';
import { useVolunteersRegistrationsMassStateUpdateMutation } from 'common-front/src/generated/graphqlHooks';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import {
    EventId,
    MassActionSelectedsInput,
    VolunteerRegistrationState
} from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';

interface IMassStateUpdateModalProps {
    eventId: EventId;
    selecteds: MassActionSelectedsInput;

    onClose(): void;
    onSuccess(): void;
}

export const MassStateUpdateModal = (props: IMassStateUpdateModalProps) => {
    const translate = useTranslate();
    const { mutate, isLoading } = useVolunteersRegistrationsMassStateUpdateMutation();
    const [state, setState] = React.useState(VolunteerRegistrationState.WaitingAssignment);
    const onUpdate = async () => {
        await mutate({
            eventId: props.eventId,
            massStateUpdate: {
                selecteds: props.selecteds,
                state
            }
        });

        props.onSuccess();
        props.onClose();
    };

    return (
        <Modal size="md" onClose={props.onClose}>
            <Flex justify="center">
                <IconBackground color="primary" icon="sliders" />
            </Flex>

            <Spacer height="4" />

            <Box color="gray900" fontSize="textLg" fontWeight="medium" textAlign="center">
                {translate('mettre_jour_l_82143')}
            </Box>

            <Spacer height="7" />

            <Select
                label={translate('nouveau_statut_57871')}
                value={state}
                onChange={(newState) => {
                    setState(newState as VolunteerRegistrationState);
                }}
            >
                <option value={VolunteerRegistrationState.NotApplicable}>
                    {translate(VolunteerRegistrationState.NotApplicable)}
                </option>
                <option value={VolunteerRegistrationState.Refused}>
                    {translate(VolunteerRegistrationState.Refused)}
                </option>
                <option value={VolunteerRegistrationState.WaitingAssignment}>
                    {translate(VolunteerRegistrationState.WaitingAssignment)}
                </option>
            </Select>

            <Spacer height="7" />

            <Flex gap="3" justify="end">
                <Button isLoading={isLoading} onClick={onUpdate}>
                    {translate('modifier_le_sta_94473')}
                </Button>

                <Button color="white" onClick={props.onClose}>
                    {translate('annuler_48254')}
                </Button>
            </Flex>
        </Modal>
    );
};
