import { SlotsBarChart } from 'common-front/src/charts/slotsBarChart';
import { Blank } from 'common-front/src/components/blank/blank';
import { DetailBox } from 'common-front/src/designSystem/components/detailBox';
import { Select } from 'common-front/src/designSystem/components/select/select';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { AccreditationId } from 'common/src/generated/types';
import { fullName } from 'common/src/vo/accreditation';
import * as React from 'react';
import { useAccreditationsCategoryChartQuery } from '../../../../generated/graphqlHooks';

export const AccreditationsCategoryChartCard = () => {
    const {
        translate,
        params: { eventId, accreditationsCategoryId }
    } = useHeavent();
    const { data, loader } = useAccreditationsCategoryChartQuery({
        eventId,
        accreditationsCategoryId
    });
    const [selectedAccreditationId, setSelectedAccreditationId] = React.useState<AccreditationId>();
    const selectedAccreditation = React.useMemo(
        () => data.event?.accreditations?.nodes.find((acc) => acc.id == selectedAccreditationId),
        [selectedAccreditationId]
    );
    const slots = React.useMemo(
        () =>
            selectedAccreditation
                ? selectedAccreditation.hasSlots
                    ? selectedAccreditation.slots
                    : [selectedAccreditation.hiddenSlot]
                : [],
        [selectedAccreditation]
    );

    return (
        loader || (
            <DetailBox
                infoPanel={
                    <>
                        <Flex
                            css={{ alignSelf: 'stretch', alignItems: 'flex-start' }}
                            direction="column"
                        >
                            <Box
                                css={{
                                    color: '$gray500',
                                    fontSize: '$textSm',
                                    fontWeight: '$medium'
                                }}
                            >
                                {translate('nombre_d_accr_d_41526')}
                            </Box>
                            <Box
                                css={{
                                    color: '$gray900',
                                    fontSize: '$displayMd',
                                    fontWeight: '$medium'
                                }}
                            >
                                {data.event.accreditations.totalCount}
                            </Box>
                        </Flex>
                    </>
                }
                select={
                    <Flex width={0.5}>
                        <Select
                            value={selectedAccreditationId ?? -1}
                            onChange={(newAccreditationId: AccreditationId) => {
                                setSelectedAccreditationId(newAccreditationId);
                            }}
                        >
                            <option disabled value={-1}>
                                {translate('s_lectionner_un_96571')}
                            </option>

                            {data.event.accreditations.nodes.map((acc) => (
                                <option key={acc.id} value={acc.id}>
                                    {fullName(acc)}
                                </option>
                            ))}
                        </Select>
                    </Flex>
                }
                title={translate('r_partition_du_55091')}
            >
                <Flex gap="4" width={1}>
                    <Box height={380} width={1}>
                        {selectedAccreditationId && selectedAccreditation ? (
                            <SlotsBarChart
                                accreditation={selectedAccreditation}
                                slots={slots}
                                weezevent={data.event.weezevent}
                            />
                        ) : (
                            <Blank title={translate('vous_allez_retr_26787')} />
                        )}
                    </Box>
                </Flex>
            </DetailBox>
        )
    );
};
