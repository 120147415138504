import { Button } from 'common-front/src/designSystem/components/button';
import { copy } from 'common-front/src/util/commands';
import { AvatarNameEmail } from 'common/src/components/avatarNameEmail';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { Link } from 'common/src/designSystem/components/link';
import { FormUserInfoUserProfileFragment } from 'common/src/generated/types';
import { isNonEmptyArray } from 'common/src/util/array';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { getFormLink } from 'common/src/vo/form';
import * as React from 'react';
import { useState } from 'react';

interface IUserOverlayInformationsTeamProps {
    team: FormUserInfoUserProfileFragment &
        Required<Pick<FormUserInfoUserProfileFragment, 'teamCode'>>;
}

export const UserOverlayInformationsTeam = (props: IUserOverlayInformationsTeamProps) => {
    const translate = useTranslate();
    const link = getFormLink({
        organizationId: props.team.organizationId,
        eventId: props.team.eventId,
        formId: props.team.formId,
        teamCode: props.team.teamCode
    });
    const [isLoading, setIsLoading] = useState(false);
    const copyLink = React.useCallback(() => {
        setIsLoading(true);
        copy(
            getFormLink({
                organizationId: props.team.organizationId,
                eventId: props.team.eventId,
                formId: props.team.formId,
                teamCode: props.team.teamCode
            })
        );

        setTimeout(() => {
            setIsLoading(false);
        }, 500);
    }, [
        props.team.organizationId,
        props.team.eventId,
        props.team.formId,
        props.team.teamCode,
        setIsLoading
    ]);

    return (
        <Flex
            css={{
                background: '$gray100',
                borderRadius: '$2',
                padding: '$4'
            }}
            direction="column"
            gap="1"
        >
            <Flex align="center" gap="3">
                <I icon="user-group" />

                <Box font="gray800 textMd semiBold">{translate('_quipe_76857')}</Box>
            </Flex>

            {isNonEmptyArray(props.team.teamMembers) && (
                <Flex
                    css={{
                        background: 'white',
                        border: '1px solid $gray200',
                        borderRadius: '$1',
                        boxShadow: '$xs',
                        padding: '$4'
                    }}
                    direction="column"
                    gap="3"
                >
                    {props.team.teamMembers.map((teamMember) => (
                        <AvatarNameEmail key={teamMember.id} hasName={true} userInfo={teamMember} />
                    ))}
                </Flex>
            )}
            <Flex align="center" gap="3" justify="end">
                <Link to={link}>{translate('lien_d_invitati_45319')}</Link>
                <Button
                    color="white"
                    isLoading={isLoading}
                    rightIcon="copy"
                    size="sm"
                    onClick={copyLink}
                />
            </Flex>
        </Flex>
    );
};
