import { getTitle } from 'common/src/vo/documentTitle';
import * as React from 'react';

export function useTitle(title: string) {
    React.useEffect(() => {
        const prevTitle = document.title;
        document.title = getTitle(location.host, title);
        return () => {
            document.title = prevTitle;
        };
    }, [title]);
}
