import { Page } from 'common-front/src/components/page/page';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { useParams } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { CustomFieldsCard } from '../../common/cards/customFieldsCard';
import { DescriptionCard } from '../../common/cards/descriptionCard';
import { FillingRateCard } from '../../common/cards/fillingRateCard';
import { LocationCard } from '../../common/cards/locationCard';
import { PrivateNoteCard } from '../../common/cards/privateNoteCard';
import { TagsCard } from '../../common/cards/tagsCard';
import { useAccreditationsSlotQuery } from '../../generated/graphqlHooks';
import { AccreditationsSlotHeader } from './accreditationsSlotHeader';
import { AccreditationsSlotMembersCard } from './cards/accreditationsSlotMembersCard/accreditationsSlotMembersCard';
import { AccreditationOrAccreditationsSlotParametersCard } from './cards/accreditationsSlotParametersCard';

export const AccreditationsSlot = () => {
    const { organizationId, eventId, accreditationsSlotId } = useParams();
    const { data, loader } = useAccreditationsSlotQuery({
        organizationId,
        eventId,
        accreditationsSlotId
    });

    return (
        loader || (
            <Page.Root>
                <AccreditationsSlotHeader slot={data.event.accreditationSlot} />

                <Page.Content>
                    <Flex direction="column" gap="6">
                        <Flex gap="6" width={1}>
                            <FillingRateCard
                                assignedResources={data.event.accreditationSlot.assignedResources}
                                maxResources={data.event.accreditationSlot.maxResources}
                            />

                            <AccreditationOrAccreditationsSlotParametersCard
                                accreditationCategoryId={
                                    data.event.accreditationSlot.accreditation
                                        .accreditationCategoryId
                                }
                                accreditationCategoryName={
                                    data.event.accreditationSlot.accreditation
                                        .accreditationCategoryName
                                }
                                accreditationName={data.event.accreditationSlot.accreditation.name}
                                color={data.event.accreditationSlot.accreditation.color}
                                customFields={data.organization.customFields.nodes}
                                fields={data.event.accreditationSlot.accreditation.fields}
                                icon={data.event.accreditationSlot.accreditation.icon}
                            />
                        </Flex>

                        <Box css={{ flex: '1' }}>
                            <AccreditationsSlotMembersCard />
                        </Box>

                        <Flex gap="4">
                            <Flex direction="column" gap="4" width={1}>
                                <CustomFieldsCard
                                    customFields={
                                        data.event.accreditationSlot.accreditation
                                            .accreditationsCustomFields
                                    }
                                />

                                <TagsCard tags={data.event.accreditationSlot.accreditation.tags} />

                                <PrivateNoteCard
                                    privateNote={
                                        data.event.accreditationSlot.accreditation.privateNote
                                    }
                                />
                            </Flex>

                            <Flex direction={'column'} gap="4" width={1}>
                                <DescriptionCard
                                    description={
                                        data.event.accreditationSlot.accreditation.description
                                    }
                                />

                                <LocationCard
                                    location={data.event.accreditationSlot.accreditation}
                                />
                            </Flex>
                        </Flex>
                    </Flex>
                </Page.Content>
            </Page.Root>
        )
    );
};
