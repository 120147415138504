import { VolunteerEmailAndNameMobile } from 'common-front/src/components/volunteerEmailAndNameMobile/volunteerEmailAndNameMobile';
import { Button } from 'common-front/src/designSystem/components/button';
import { signOut } from 'common-front/src/util/aws/cognito';
import { Avatar } from 'common/src/components/avatar/avatar';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { styled } from 'common/src/designSystem/components/stitches';
import { CommonEnvVars } from 'common/src/envVars';
import { OrganizationQuery } from 'common/src/generated/types';
import { HeaventEnv, heaventEnvTermsUrl } from 'common/src/heaventEnv';
import { useHistory, useTranslate } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';
import { OrganizationMenuItemMobile } from './organizationMenuItemMobile';
import { OrganizationSectionMobile } from './organizationSectionMobile';
import { OrganizationSectionTitleMobile } from './organizationSectionTitleMobile';

const Background = styled(Flex, {
    backgroundColor: '$gray50',
    padding: '$6 $3',
    height: '100%'
});

const _h1 = styled('h1', {
    color: '$gray800',
    fontSize: '$displaySm',
    fontWeight: '$semiBold',
    margin: 0
});

export const OrganizationMainMobile = ({ organization, user }: OrganizationQuery) => {
    const translate = useTranslate();
    const history = useHistory();
    const openSwitchOrganization = () => {
        history.push(HeaventPaths.ORGANIZATION_PARAMETERS_ORGANIZATION_SWITCH(organization.id));
    };
    const openEditProfile = () => {
        history.push(HeaventPaths.ORGANIZATION_PARAMETERS_PROFILE(organization.id));
    };
    const openCGU = () => {
        window.open(heaventEnvTermsUrl(), '_blank');
    };
    const openSupport = () => {
        window.open('https://support.weezevent.com/fr/weezcrew', '_blank');
    };
    const openDeleteAccount = () => {
        history.push(HeaventPaths.ORGANIZATION_PARAMETERS_DELETE_ACCOUNT(organization.id));
    };

    return (
        <Background direction="column" gap="6">
            <_h1>{translate('profil_14444')}</_h1>
            <Flex align="center" gap="4">
                <Avatar
                    email={user.email}
                    image={user.userInfo.picture?.url}
                    name={user.userInfo.name}
                    size={64}
                />
                <VolunteerEmailAndNameMobile
                    size={64}
                    userInfo={{ email: user.email, ...user.userInfo }}
                />
            </Flex>
            <OrganizationSectionMobile direction="column">
                <Flex align="center" gap="3" justify="between">
                    <Box css={{ color: '$gray800', fontWeight: '$medium' }}>
                        {organization.name}
                    </Box>
                    <Button onClick={openSwitchOrganization}>{translate('changer_74000')}</Button>
                </Flex>
            </OrganizationSectionMobile>
            <Flex direction="column" gap="2">
                <OrganizationSectionTitleMobile>
                    {translate('profil_14444')}
                </OrganizationSectionTitleMobile>
                <OrganizationSectionMobile direction="column">
                    <OrganizationMenuItemMobile icon="user-gear" onClick={openEditProfile}>
                        {translate('informations_pe_37892')}
                    </OrganizationMenuItemMobile>
                </OrganizationSectionMobile>
            </Flex>
            <Flex direction="column" gap="2">
                <OrganizationSectionTitleMobile>
                    {translate('g_n_ral_64953')}
                </OrganizationSectionTitleMobile>
                <OrganizationSectionMobile direction="column">
                    <OrganizationMenuItemMobile icon="shield" onClick={openCGU}>
                        {translate('cgu_51460')}
                    </OrganizationMenuItemMobile>
                    {CommonEnvVars.HEAVENT_ENV === HeaventEnv.Recrewteer && (
                        <OrganizationMenuItemMobile icon="circle-question" onClick={openSupport}>
                            {translate('aide_et_support_49790')}
                        </OrganizationMenuItemMobile>
                    )}
                </OrganizationSectionMobile>
            </Flex>
            <OrganizationSectionMobile direction="column">
                <OrganizationMenuItemMobile icon="arrow-left-from-line" onClick={signOut}>
                    {translate('se_d_connecter_74151')}
                </OrganizationMenuItemMobile>
                <OrganizationMenuItemMobile icon="user-xmark" onClick={openDeleteAccount}>
                    {translate('supprimer_mon_c_07051')}
                </OrganizationMenuItemMobile>
            </OrganizationSectionMobile>
        </Background>
    );
};
