import { UpdateLinkTextCell } from 'common-front/src/components/cells/updateLinkTextCell';
import { TextInput } from 'common-front/src/designSystem/components/textInput';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { useSegmentsContext } from 'common-front/src/segments/segmentsContext';
import { LinkText } from 'common/src/designSystem/components/linkText';
import { Accreditation, AccreditationProperty } from 'common/src/generated/types';
import { AccreditationsPaths } from 'common/src/util/paths/accreditationsPaths';
import * as React from 'react';
import { AccreditationsUpdateCell } from './accreditationsUpdateCell';

interface IAccreditationsNameCellProps {
    accreditation: Pick<Accreditation, 'id' | 'name'>;

    reload(): void;
}

export const AccreditationsNameCell = (props: IAccreditationsNameCellProps) => {
    const {
        translate,
        params: { organizationId, eventId }
    } = useHeavent();
    const { isEditMode } = useSegmentsContext();
    const name = props.accreditation.name;

    return (
        <AccreditationsUpdateCell
            accreditationId={props.accreditation.id}
            initialValue={props.accreditation.name}
            property={AccreditationProperty.Name}
            reload={props.reload}
            renderInput={(value, setValue) => (
                <TextInput
                    label={translate('nom_de_l_accr_d_11908')}
                    value={value}
                    onChange={setValue}
                />
            )}
            renderValue={() => (
                <UpdateLinkTextCell isEditMode={isEditMode} title={name}>
                    <LinkText
                        to={AccreditationsPaths.ACCREDITATION({
                            organizationId,
                            eventId,
                            accreditationId: props.accreditation.id
                        })}
                    >
                        {name}
                    </LinkText>
                </UpdateLinkTextCell>
            )}
        />
    );
};
