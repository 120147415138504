import { capitalizeName } from '../util/string';

interface IGetNameOptions {
    firstName?: string;
    lastName?: string;
}

export function getName(options: IGetNameOptions = {}): string {
    const firstName = capitalizeName(options.firstName || '').trim();
    const lastName = capitalizeName(options.lastName || '').trim();

    return `${lastName.toUpperCase()} ${firstName}`.trim();
}

type Slug =
    | 'city'
    | 'country'
    | 'custom'
    | 'dateOfBirth'
    | 'firstName'
    | 'language'
    | 'lastName'
    | 'nationality'
    | 'phone'
    | 'picture'
    | 'postalCode'
    | 'sex'
    | 'street'
    | 'population'
    | `cf${number}`;

export function getFieldValue<T>(
    expandedFields: any,
    slug: Slug,
    defaultValue: T | null = null
): T {
    return expandedFields?.[slug]?.value || defaultValue;
}
