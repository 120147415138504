import { injectable } from 'inversify';
import {
    ALL_DELEGATIONSTATE,
    DelegationsSortAttributes,
    EventId,
    FilterType,
    OrganizationId,
    SegmentCustomFieldFragment
} from '../../generated/types';
import { CountriesService } from '../../services/countriesService';
import { DateTimeService } from '../../services/dateTimeService';
import { TranslationService } from '../../services/translationService';
import { Emptyable } from '../../util/emptyable';
import { Filter, PossibleColumn, SegmentService } from '../segment';

export enum DelegationDefaultColumns {
    AssignedResources = 'resources',
    CanLeadersAddMembers = 'canLeadersAddMembers',
    CanLeadersEditCustomFields = 'canLeadersEditCustomFields',
    CanLeadersEditMembers = 'canLeadersEditMembers',
    CreatedBy = 'createdBy',
    DeadlineDate = 'deadlineDate',
    FillingRate = 'fillingRate',
    Form = 'form',
    Id = 'id',
    Leaders = 'leaders',
    MaxResources = 'maxResources',
    Name = 'name',
    ShowInDelegation = 'showInDelegation',
    State = 'delegationState',
    Tags = 'tags'
}

@injectable()
export class DelegationsSegmentsService extends SegmentService {
    constructor(
        countriesService: CountriesService,
        dateTimeService: DateTimeService,
        translationService: TranslationService
    ) {
        super(countriesService, dateTimeService, translationService);
    }

    getDelegationsFilters(
        organizationId: OrganizationId,
        eventId: Emptyable<EventId>,
        customFields: SegmentCustomFieldFragment[]
    ): Filter[] {
        const filters: Filter[] = [
            {
                color: 'warning',
                slug: 'delegationState',
                name: this.t('status_06428'),
                fieldType: FilterType.Select,
                values: ALL_DELEGATIONSTATE.map((state) => ({
                    id: state,
                    name: state
                })),
                hideEmpty: true
            },
            {
                color: 'warning',
                slug: 'form',
                name: this.t('Form'),
                fieldType: FilterType.Select,
                hideEmpty: true,
                needLoading: true,
                organizationId,
                eventId
            },
            {
                color: 'warning',
                slug: 'deadlineDate',
                name: this.t('date_limite_d_i_79779'),
                fieldType: FilterType.Date
            },
            {
                color: 'warning',
                slug: 'tag',
                name: this.t('Tag'),
                fieldType: FilterType.Select,
                needLoading: true,
                organizationId
            },
            {
                color: 'warning',
                slug: 'resources',
                name: this.t('nombre_de_membr_08851'),
                fieldType: FilterType.Number
            },
            {
                color: 'warning',
                slug: 'fillingRate',
                name: this.t('taux_de_remplis_11913'),
                fieldType: FilterType.Number
            },
            {
                color: 'warning',
                slug: DelegationDefaultColumns.ShowInDelegation,
                name: this.t('ShowInDelegationSpace'),
                fieldType: FilterType.Checkbox,
                hideEmpty: true
            },
            {
                color: 'warning',
                slug: DelegationDefaultColumns.CanLeadersAddMembers,
                name: this.t('CanLeadersAddMembers'),
                fieldType: FilterType.Checkbox,
                hideEmpty: true
            },
            {
                color: 'warning',
                slug: DelegationDefaultColumns.CanLeadersEditMembers,
                name: this.t('CanLeadersEditMembers'),
                fieldType: FilterType.Checkbox,
                hideEmpty: true
            },
            {
                color: 'warning',
                slug: DelegationDefaultColumns.CanLeadersEditCustomFields,
                name: this.t('CanLeadersEditCustomFields'),
                fieldType: FilterType.Checkbox,
                hideEmpty: true
            }
        ];

        if (eventId) {
            filters.push({
                color: 'warning',
                slug: 'accreditation',
                name: this.t('accr_ditations_39450'),
                fieldType: FilterType.Select,
                needLoading: true,
                eventId
            });
        }

        filters.push(...this.customFieldsToFilters(customFields));

        return filters;
    }

    getDelegationsPossibleColumns(customFields: SegmentCustomFieldFragment[]): PossibleColumn[] {
        const possibleColumns: PossibleColumn[] = [
            {
                slug: DelegationDefaultColumns.Id,
                name: this.t('id_51738'),
                isSearchable: true,
                isSortable: true,
                sortAttribute: DelegationsSortAttributes.Id,
                width: 100
            },
            {
                slug: DelegationDefaultColumns.Name,
                name: this.t('nom_de_la_d_l_g_28087'),
                isSearchable: true,
                isSortable: true,
                sortAttribute: DelegationsSortAttributes.Name
            },
            {
                slug: DelegationDefaultColumns.State,
                name: this.t('status_06428')
            },
            {
                slug: DelegationDefaultColumns.Leaders,
                name: this.t('responsables_84923')
            },
            {
                slug: DelegationDefaultColumns.Form,
                name: this.t('Form'),
                isSearchable: true
            },
            {
                slug: DelegationDefaultColumns.DeadlineDate,
                name: this.t('date_limite_d_i_79779')
            },
            {
                slug: DelegationDefaultColumns.MaxResources,
                name: this.t('MaxResources')
            },
            {
                slug: DelegationDefaultColumns.AssignedResources,
                name: this.t('membres_11310')
            },
            {
                slug: DelegationDefaultColumns.Tags,
                name: this.t('tags_79499'),
                isSearchable: true
            },
            {
                slug: DelegationDefaultColumns.FillingRate,
                name: this.t('taux_de_remplis_11913')
            },
            {
                slug: DelegationDefaultColumns.CreatedBy,
                name: this.t('cr_e_par_30622'),
                isSearchable: true
            },
            {
                slug: DelegationDefaultColumns.ShowInDelegation,
                name: this.t('ShowInDelegationSpace')
            },
            {
                slug: DelegationDefaultColumns.CanLeadersAddMembers,
                name: this.t('CanLeadersAddMembers')
            },
            {
                slug: DelegationDefaultColumns.CanLeadersEditMembers,
                name: this.t('CanLeadersEditMembers')
            },
            {
                slug: DelegationDefaultColumns.CanLeadersEditCustomFields,
                name: this.t('CanLeadersEditCustomFields')
            }
        ];

        return possibleColumns.concat(this.customFieldsToPossibleColumns(customFields));
    }
}
