import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { Feature, VolunteerRegistrationState } from 'common/src/generated/types';
import { isNonEmptyArray } from 'common/src/util/array';
import * as React from 'react';
import { useEventContext } from '../../../events/show/eventContext';
import { useEventUserProfileActionsQuery } from '../../../generated/graphqlHooks';
import { useOrganizationContext } from '../../../organization/organizationContext';
import { EventUserProfileActionsAccreditation } from './eventUserProfileActionsAccreditation';
import { EventUserProfileActionsAssignment } from './eventUserProfileActionsAssignment';
import { EventUserProfileActionsDocuments } from './eventUserProfileActionsDocuments';
import { EventUserProfileActionsGeneral } from './eventUserProfileActionsGeneral';

interface IUserProfileActionsProps {
    isWeezeventConnected: boolean;
}

export const EventUserProfileActions = (props: IUserProfileActionsProps) => {
    const {
        params: { eventId, userInfoId }
    } = useHeavent();
    const { organization, hasFeature } = useOrganizationContext();
    const { isEventAdmin } = useEventContext();
    const { data, isLoading, loader, reload } = useEventUserProfileActionsQuery({
        eventId,
        userInfoId
    });
    const showAccreditation = isEventAdmin && hasFeature(Feature.Accreditation);
    const showDocuments =
        (!isLoading &&
            data.event.volunteerRegistration.state === VolunteerRegistrationState.Assigned) ||
        isNonEmptyArray(organization.customBadges);
    const showGeneral = isEventAdmin;

    return (
        loader || (
            <>
                <Spacer height="7" />

                <EventUserProfileActionsAssignment
                    reload={reload}
                    state={data.event.volunteerRegistration.state}
                />

                {showAccreditation && (
                    <>
                        <Spacer height="7" />

                        <EventUserProfileActionsAccreditation
                            accreditationState={data.event.volunteerRegistration.accreditationState}
                            reload={reload}
                        />
                    </>
                )}

                {showDocuments && (
                    <>
                        <Spacer height="7" />

                        <EventUserProfileActionsDocuments
                            links={data.event.volunteerRegistration.userInfo.links!}
                            state={data.event.volunteerRegistration.state}
                        />
                    </>
                )}

                {showGeneral && (
                    <>
                        <Spacer height="7" />

                        <EventUserProfileActionsGeneral
                            isWeezeventConnected={props.isWeezeventConnected}
                            nameOrEmail={data.event.volunteerRegistration.userInfo.nameOrEmail}
                        />
                    </>
                )}

                <Spacer height="7" />
            </>
        )
    );
};
