import { Box } from 'common/src/designSystem/components/box';
import { ILinkProps } from 'common/src/designSystem/components/link';
import { useLocation } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';

interface IItemProps extends Pick<ILinkProps, 'to' | 'replace'> {
    children: React.ReactNode;

    isActive?(pathname: string): boolean;
}

export const Item = ({ children, isActive: _isActive, replace = true, to }: IItemProps) => {
    const location = useLocation();
    const isActive = _isActive?.(location.pathname) ?? location.pathname === to;

    return (
        <Box
            css={{
                background: isActive ? '$primary100' : 'transparent',
                borderRadius: '6px',
                color: isActive ? '$primary700' : '$gray500',
                cursor: isActive ? 'default' : 'pointer',
                padding: '$2 $3'
            }}
            replace={replace}
            to={to}
        >
            {children}
        </Box>
    );
};
