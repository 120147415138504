import { startCase } from 'lodash-es';
import { heaventEnvDisplayName } from '../heaventEnv';
import { STAFF_INSATIABLES_FR, WWW_PARTAGETAPASSION_COM } from '../thirdPartyDomains';

export const getTitle = (host?: string, pageName?: string) => {
    const title = pageName ? startCase(pageName) + ' | ' : '';
    const siteTitle =
        host === WWW_PARTAGETAPASSION_COM
            ? 'Partage Ta Passion'
            : host === STAFF_INSATIABLES_FR
              ? 'Staff Insatiables'
              : heaventEnvDisplayName();
    return title + siteTitle;
};
