import { Blank } from 'common-front/src/components/blank/blank';
import { Alert } from 'common-front/src/designSystem/components/alert';
import { Button } from 'common-front/src/designSystem/components/button';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Svgs } from 'common-front/src/util/assets';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { OrganizationId } from 'common/src/generated/types';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';
import { useOrganizationDomainsQuery } from '../../../generated/graphqlHooks';
import { OrganizationEmailsDomain } from './organizationEmailsDomain';

const DOC_ARTICLE_LINK =
    'https://support.weezevent.com/fr/param%C3%A9trer-ladresse-e-mail-de-lexp%C3%A9diteur';

interface IOrganizationEmailsDnsProps {
    organizationId: OrganizationId;
}

export const OrganizationEmailsDns = (props: IOrganizationEmailsDnsProps) => {
    const { history, translate } = useHeavent();
    const { data, loader, reload } = useOrganizationDomainsQuery({
        organizationId: props.organizationId
    });

    return (
        loader || (
            <>
                <Spacer height="7" />

                {data.organization.domains.length === 0 ? (
                    <Flex direction="column">
                        <Box height={250} width={1}>
                            <Blank
                                imageSrc={Svgs.DnsBlank}
                                subtitle={translate('pour_avoir_acc_02742')}
                                title={translate('les_entr_es_dns_52899')}
                            />
                        </Box>

                        <Flex gap="3" justify="center">
                            <Button
                                color="white"
                                onClick={() => {
                                    open(DOC_ARTICLE_LINK);
                                }}
                            >
                                {translate('lire_la_documen_25597')}
                            </Button>

                            <Button
                                onClick={() => {
                                    history.push(
                                        HeaventPaths.ORGANIZATION_PARAMETERS_EMAILS_SENDERS(
                                            props.organizationId
                                        )
                                    );
                                }}
                            >
                                {translate('ajouter_un_met_38216')}
                            </Button>
                        </Flex>
                    </Flex>
                ) : (
                    <>
                        <Alert color="gray" leftIcon="circle-exclamation">
                            {translate('les_entr_es_dns_55177')}
                        </Alert>

                        {data.organization.domains.map((domain, index) => (
                            <React.Fragment key={index}>
                                <Spacer height="6" />

                                <OrganizationEmailsDomain
                                    domain={domain}
                                    organizationId={props.organizationId}
                                    reload={reload}
                                />
                            </React.Fragment>
                        ))}
                    </>
                )}
            </>
        )
    );
};
