import { FileInput, FileInputValue } from 'common-front/src/designSystem/components/file/fileInput';
import { RichEditor } from 'common-front/src/designSystem/components/richEditor/richEditor';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Accept } from 'common-front/src/util/accept';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import {
    CustomBadge,
    DocumentType,
    DocumentUserCustomBadgeFragment
} from 'common/src/generated/types';
import { getDocumentFullPath } from 'common/src/util/documentsPaths';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import { getCustomBadgeReactNode, getDisplayName } from 'common/src/vo/customBadge';
import {
    getDefaultAsoassignmentOptions,
    getDefaultBruleursDocumentOptions,
    getDefaultPecbadgeOptions,
    getDefaultT24assignmentOptions
} from 'common/src/vo/eventDocument';
import * as React from 'react';
import { useUserCustomBadgeDocumentQuery } from '../../generated/graphqlHooks';
import { DocumentHeader } from './documentHeader';
import { DocumentOptions, useDocumentOptions } from './documentOptions';
import { DocumentsAssignedUserSelector } from './documentsAssignedUserSelector';
import { DocumentShadow } from './documentShadow';

interface IBadgeDinoContentProps {
    event: DocumentUserCustomBadgeFragment;
}

const CustomBadgeContent = (props: IBadgeDinoContentProps) => {
    const {
        translate,
        params: { organizationId, eventId, badgeType }
    } = useHeavent();
    const [options, setOptions] = useDocumentOptions(
        props.event.document.options,
        badgeType === CustomBadge.Pec
            ? getDefaultPecbadgeOptions()
            : badgeType === CustomBadge.T24assignment
              ? getDefaultT24assignmentOptions()
              : badgeType === CustomBadge.Bruleurs
                ? getDefaultBruleursDocumentOptions()
                : getDefaultAsoassignmentOptions(),
        eventId,
        props.event.document.id
    );

    return (
        <Flex css={{ flex: '1', overflow: 'hidden' }} gap="7" width={1}>
            {badgeType === CustomBadge.Asoassignment && (
                <DocumentOptions>
                    <RichEditor
                        initialValue={options.habits}
                        label={'Habitudes'}
                        onChange={(habits) => {
                            setOptions({ habits });
                        }}
                    />
                </DocumentOptions>
            )}

            {badgeType === CustomBadge.Pec && (
                <DocumentOptions>
                    <FileInput
                        accept={Accept.Images}
                        format="base64"
                        label={translate('banni_re_86634')}
                        placeholder={translate('banni_re_86634')}
                        value={options.header}
                        onChange={(header: FileInputValue) => {
                            setOptions({ header });
                        }}
                    />
                </DocumentOptions>
            )}

            {badgeType === CustomBadge.T24assignment && (
                <DocumentOptions>
                    <FileInput
                        accept={Accept.Images}
                        format="base64"
                        label={translate('logo_18191')}
                        placeholder={translate('logo_18191')}
                        value={options.logo}
                        onChange={(logo: FileInputValue) => {
                            setOptions({ logo });
                        }}
                    />
                </DocumentOptions>
            )}

            {badgeType === CustomBadge.Bruleurs && (
                <DocumentOptions>
                    <FileInput
                        accept={Accept.Images}
                        format="base64"
                        label={translate('logo_18191')}
                        placeholder={translate('logo_18191')}
                        value={options.logo}
                        onChange={(logo: FileInputValue) => {
                            setOptions({ logo });
                        }}
                    />
                </DocumentOptions>
            )}

            <Flex css={{ flex: '1' }} direction="column">
                <DocumentsAssignedUserSelector
                    downloadPath={getDocumentFullPath(
                        props.event.volunteerRegistration.userInfo.links?.userCustomBadgeLinks?.[
                            badgeType
                        ] || '',
                        'pdf'
                    )}
                    getPath={(newUserInfoId) =>
                        HeaventPaths.DOCUMENTS_BADGE(
                            organizationId,
                            eventId,
                            newUserInfoId,
                            badgeType
                        )
                    }
                />

                <DocumentShadow>
                    {getCustomBadgeReactNode(badgeType, props.event, options, null)}
                </DocumentShadow>
            </Flex>
        </Flex>
    );
};

export const UserCustomBadge = () => {
    const {
        translate,
        params: { organizationId, eventId, userInfoId, badgeType }
    } = useHeavent();
    const { data, loader } = useUserCustomBadgeDocumentQuery({
        eventId,
        userInfoId,
        customBadge: badgeType
    });

    return (
        <Flex css={{ padding: '$7' }} direction="column" height={1} width={1}>
            <DocumentHeader
                badgeType={badgeType}
                documentType={DocumentType.CustomBadge}
                eventId={eventId}
                languages={data.event?.languages ?? []}
                organizationId={organizationId}
                segmentsFolders={data.event?.segmentsFolders ?? []}
                showDate={badgeType === CustomBadge.Paris20kmassignment}
                showStrategy={true}
                title={translate('badge_1_89709', getDisplayName(badgeType))}
            />

            <Spacer height="7" />

            {loader || <CustomBadgeContent event={data.event} />}
        </Flex>
    );
};
