import { Cell } from 'common/src/designSystem/components/table/cell';
import { VolunteerRegistrationFragment } from 'common/src/generated/types';
import * as React from 'react';
import { BadgesPlus } from '../../../components/badgesPlus/badgesPlus';

interface IVolunteerRegistrationFormsProps {
    volunteerRegistration: VolunteerRegistrationFragment;
}

export const VolunteerRegistrationForms = (props: IVolunteerRegistrationFormsProps) => {
    const badges = React.useMemo(
        () =>
            (props.volunteerRegistration.userInfo?.formsUsersInfos ?? []).flatMap((fui) => {
                if (fui.form) {
                    return [
                        {
                            id: fui.form.id,
                            color: 'gray',
                            icon: null,
                            text: fui.form.name
                        }
                    ];
                } else {
                    return [];
                }
            }),
        [props.volunteerRegistration.userInfo]
    );

    return (
        <Cell>
            <BadgesPlus badges={badges} />
        </Cell>
    );
};
