import { Spacer } from 'common/src/designSystem/components/spacer';
import { DelegationId, EventId, OrganizationId, UsersInfoId } from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import { MembersPaths } from 'common/src/util/membersPaths';
import { DelegationsPaths } from 'common/src/util/paths/delegationsPaths';
import {
    EventIdPathParam,
    FormIdPathParam,
    OrganizationIdPathParam,
    UserInfoIdPathParam
} from 'common/src/util/pathsTypes';
import * as React from 'react';
import { RightPanel } from '../../designSystem/components/rightPanel/rightPanel';
import { RightPanelBody } from '../../designSystem/components/rightPanel/rightPanelBody';
import { RightPanelHeader } from '../../designSystem/components/rightPanel/rightPanelHeader';
import { useUserOverlaySlotsQuery } from '../../generated/graphqlHooks';
import { UserPositionsWishedSlots } from '../../users/information/userPositionsWishedSlots';

interface IAvailabilitiesRightPanelProps {
    delegationId?: DelegationId;
    eventId: EventId;
    organizationId: OrganizationId;
    returnPathFallback: string;
    showEditButton: boolean;
    userInfoId: UsersInfoId;
}

export const AvailabilitiesRightPanel = (props: IAvailabilitiesRightPanelProps) => {
    const translate = useTranslate();
    const { data, loader } = useUserOverlaySlotsQuery({
        organizationId: props.organizationId,
        eventId: props.eventId,
        userInfoId: props.userInfoId
    });
    console.log(location.host);

    return (
        <RightPanel returnToFallback={props.returnPathFallback} size="md">
            <RightPanelHeader>{translate('disponibilit_s_49923')}</RightPanelHeader>

            <RightPanelBody>
                {loader || (
                    <>
                        <Spacer height="6" />
                        <UserPositionsWishedSlots
                            editFormPath={(
                                organizationId: OrganizationIdPathParam,
                                userInfoId: UserInfoIdPathParam,
                                eventId: EventIdPathParam,
                                formId: FormIdPathParam
                            ) =>
                                location.host.startsWith('app.')
                                    ? HeaventPaths.EDIT_USER_SLOTS_FORM(
                                          organizationId,
                                          eventId,
                                          userInfoId,
                                          formId
                                      )
                                    : props.delegationId
                                      ? DelegationsPaths.MEMBER_EDIT({
                                            organizationId,
                                            userInfoId,
                                            delegationId: props.delegationId,
                                            eventId,
                                            formId
                                        })
                                      : MembersPaths.MEMBER_REGISTRATION_EDIT_FORM(
                                            organizationId,
                                            userInfoId,
                                            eventId,
                                            formId
                                        )
                            }
                            editPath={HeaventPaths.EDIT_USER_SLOTS(
                                props.organizationId,
                                props.eventId,
                                props.userInfoId
                            )}
                            eventId={props.eventId}
                            formsUsersInfos={data.organization.userInfo.formsUsersInfos}
                            organizationId={props.organizationId}
                            showEditButton={props.showEditButton}
                            userInfoId={props.userInfoId}
                        />

                        <Spacer height="6" />
                    </>
                )}
            </RightPanelBody>
        </RightPanel>
    );
};
