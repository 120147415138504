import { MobileOverlay } from 'common-front/src/components/mobileOverlay/mobileOverlay';
import { Button } from 'common-front/src/designSystem/components/button';
import { IconBackground } from 'common-front/src/designSystem/components/iconBackground';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { signOut } from 'common-front/src/util/aws/cognito';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { styled } from 'common/src/designSystem/components/stitches';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';
import { useUserDeleteMutation } from '../../generated/graphqlHooks';

const _h3 = styled('h3', {
    color: '$gray800',
    fontWeight: '$bold',
    margin: '$3 $4'
});

const YesButton = styled('button', {
    background: 'none',
    border: 'none',
    color: '$error700',
    fontWeight: '$semiBold'
});

export const DeleteAccountSheetMobile = () => {
    const { history, translate } = useHeavent();
    const { mutate } = useUserDeleteMutation();
    const onDelete = async () => {
        await mutate({});
        signOut();
    };

    return (
        <MobileOverlay>
            <Flex align="center" direction="column">
                <IconBackground color="error" icon="user-slash" />
                <Spacer height="2" />
                <_h3>{translate('suppression_de_67160')}</_h3>
                <Box color="gray500" textAlign="center">
                    {translate('souhaitez_vous_30775')}
                </Box>
                <Spacer height="7" />
                <Button onClick={() => history.goBack(HeaventPaths.HOME)}>
                    {translate('non_je_ne_souh_99960')}
                </Button>
                <Spacer height="4" />
                <YesButton onClick={onDelete}>{translate('oui_je_souhait_87268')}</YesButton>
            </Flex>
        </MobileOverlay>
    );
};
