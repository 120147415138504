import { Content } from 'common-front/src/designSystem/components/tooltip/content';
import { Tooltip } from 'common-front/src/designSystem/components/tooltip/tooltip';
import { Trigger } from 'common-front/src/designSystem/components/tooltip/trigger';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import {
    CustomFieldWithConditionFragment,
    TeamAssignmentInfosQuery
} from 'common/src/generated/types';
import * as React from 'react';
import { AssignmentUserPanelInfos } from '../assign/userPanel/assignmentUserPanelInfos';
import { AssignmentUserPanelPositionsWished } from '../assign/userPanel/assignmentUserPanelPositionsWished';
import { AssignmentUserPanelSlotsWished } from '../assign/userPanel/assignmentUserPanelSlotsWished';

interface ITeamAssignmentRightPanelProps {
    customFields: CustomFieldWithConditionFragment[];
    team: NonNullable<TeamAssignmentInfosQuery['event']['team']>;

    onCollapse(): void;
}

export const TeamAssignmentRightPanel = (props: ITeamAssignmentRightPanelProps) => {
    const {
        translate,
        params: { organizationId, eventId }
    } = useHeavent();
    const fuis = React.useMemo(
        () =>
            props.team.leaderUserInfo.formsUsersInfos.filter(
                (fui) => fui.form.id === props.team.formId
            ),
        [props.team]
    );

    return (
        <Flex direction="column" height={1} width={1}>
            <Flex align="center" css={{ padding: '$6' }} gap="3">
                <Tooltip>
                    <Trigger>
                        <Box
                            color="gray800"
                            css={{ cursor: 'pointer' }}
                            fontSize="textLg"
                            onClick={props.onCollapse}
                        >
                            <I icon="sidebar" />
                        </Box>
                    </Trigger>

                    <Content placement="top">{translate('cacher_les_info_67266')}</Content>
                </Tooltip>

                <Box font="gray900 textMd semiBold">{translate('_propos_de_l_47506')}</Box>
            </Flex>

            {props.team.members.map((vr) => (
                <AssignmentUserPanelInfos
                    key={vr.id}
                    customFields={props.customFields}
                    formsIds={[props.team.formId]}
                    title={translate('informations_de_63884', vr.userInfo.nameOrEmail)}
                    userInfo={vr.userInfo}
                />
            ))}

            <AssignmentUserPanelPositionsWished
                eventId={eventId}
                formsUsersInfos={fuis}
                organizationId={organizationId}
            />

            <AssignmentUserPanelSlotsWished formsUsersInfos={fuis} />
        </Flex>
    );
};
