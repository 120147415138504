import { FullPageContent } from 'common-front/src/components/mobileFullPage/fullPageContent';
import { FullPageHeader } from 'common-front/src/components/mobileFullPage/fullPageHeader';
import { FullPageHeaderText } from 'common-front/src/components/mobileFullPage/fullPageHeaderText';
import { MobileFullPage } from 'common-front/src/components/mobileFullPage/mobileFullPage';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { PositionSlotMembersCheckOutListMobile } from './positionSlotMembersCheckOutListMobile';

export const PositionSlotMembersCheckOutMobile = () => {
    const translate = useTranslate();

    return (
        <MobileFullPage>
            <FullPageHeader>
                <FullPageHeaderText>{translate('d_pointer_des_m_31557')}</FullPageHeaderText>
            </FullPageHeader>
            <FullPageContent css={{ margin: '0', overflow: 'auto' }}>
                <PositionSlotMembersCheckOutListMobile />
            </FullPageContent>
        </MobileFullPage>
    );
};
