import { injectable } from 'inversify';
import * as yup from 'yup';
import { AccreditationState, VolunteerRegistrationState } from '../generated/types';
import { TranslationService } from '../services/translationService';
import { InputService } from './inputService';

@injectable()
export class MassStateUpdateInputService extends InputService {
    constructor(protected translationService: TranslationService) {
        super(translationService);
    }

    massStateUpdateSchema() {
        return yup.object().shape({
            state: yup
                .string()
                .required(this.t('le_statut_est_r_13164'))
                .oneOf(
                    [
                        VolunteerRegistrationState.NotApplicable,
                        VolunteerRegistrationState.Refused,
                        VolunteerRegistrationState.WaitingAssignment
                    ],
                    this.t('le_statut_est_r_13164')
                )
        });
    }

    massAccreditationStateUpdateSchema() {
        return yup.object().shape({
            state: yup
                .string()
                .required(this.t('le_statut_est_r_13164'))
                .oneOf(
                    [
                        AccreditationState.NotApplicable,
                        AccreditationState.Refused,
                        AccreditationState.WaitingAccreditation
                    ],
                    this.t('le_statut_est_r_13164')
                )
        });
    }
}
