import { Page } from 'common-front/src/components/page/page';
import { KpiCard } from 'common-front/src/designSystem/components/kpiCard';
import { Tab } from 'common-front/src/designSystem/components/tabs/tab';
import { TabList } from 'common-front/src/designSystem/components/tabs/tabList';
import { TabPanel } from 'common-front/src/designSystem/components/tabs/tabPanel';
import { Tabs } from 'common-front/src/designSystem/components/tabs/tabs';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { PositionCategoryQuery } from 'common/src/generated/types';
import { IntervalService } from 'common/src/services/intervalService';
import { useLocation, useService } from 'common/src/util/dependencies/dependencies';
import { PositionCategoryParams, PositionsPaths } from 'common/src/util/paths/positionsPaths';
import * as React from 'react';
import { useEventContext } from '../../events/show/eventContext';
import { usePositionsCategoryLeadersAssignMutation } from '../../generated/graphqlHooks';
import { PositionCategoryDescription } from './positionCategoryDescription';
import { PositionCategoryHeader } from './positionCategoryHeader';
import { PositionCategoryLeaders } from './positionCategoryLeaders';
import { PositionCategoryPositions } from './positions/positionCategoryPositions';

interface IPositionCategoryProps {
    event: PositionCategoryQuery['event'];
    roles: PositionCategoryQuery['organization']['roles'];

    reload(): void;
}

export const PositionCategory = ({ event, roles, reload }: IPositionCategoryProps) => {
    const {
        translate,
        params: { organizationId, eventId, positionCategoryId }
    } = useHeavent();
    const location = useLocation();
    const intervalService = useService(IntervalService);
    const { updateLocalEvent } = useEventContext();
    const { mutate: leadersAssign } = usePositionsCategoryLeadersAssignMutation();

    React.useEffect(() => {
        updateLocalEvent({ positionBackPath: location.pathname });
    }, []);

    const getPositionsCategoryParams = (isRouteComponent: boolean): PositionCategoryParams => ({
        organizationId: isRouteComponent ? ':organizationId' : organizationId,
        eventId: isRouteComponent ? ':eventId' : eventId,
        positionCategoryId: isRouteComponent ? ':positionCategoryId' : positionCategoryId
    });

    return (
        <Page.Root>
            <PositionCategoryHeader category={event.positionCategory} reload={reload} />

            <Tabs css={{ flex: '1' }}>
                <TabList css={{ px: '$7' }}>
                    <Tab
                        path={PositionsPaths.POSITION_CATEGORY_DASHBOARD(
                            getPositionsCategoryParams(false)
                        )}
                    >
                        {translate('tableau_de_bord_24627')}
                    </Tab>
                </TabList>

                <TabPanel
                    path={PositionsPaths.POSITION_CATEGORY_DASHBOARD(
                        getPositionsCategoryParams(true)
                    )}
                >
                    <Page.Content>
                        <Flex direction="column" gap="6">
                            <Flex gap="6" width={1}>
                                <KpiCard
                                    iconColor="primary"
                                    text={translate('number_of_shift_32210')}
                                    value={event.positionCategory.positions.length}
                                />

                                <KpiCard
                                    iconColor="primary"
                                    text={translate('number_of_membe_24698')}
                                    value={event.positionCategory.resources}
                                />

                                <KpiCard
                                    iconColor="primary"
                                    text={translate('Date')}
                                    value={
                                        event.positionCategory.range
                                            ? intervalService.toLocaleString(
                                                  event.positionCategory.range,
                                                  undefined,
                                                  {
                                                      day: 'numeric',
                                                      month: 'short',
                                                      year: 'numeric'
                                                  }
                                              )
                                            : '-'
                                    }
                                />
                            </Flex>

                            <Flex gap="7">
                                <Flex direction="column" gap="6" width={470}>
                                    <PositionCategoryLeaders
                                        leaders={event.positionCategory.leaders}
                                        reload={reload}
                                        roles={roles}
                                        onSave={(leaders) =>
                                            leadersAssign({
                                                eventId,
                                                positionCategoryId,
                                                leaders
                                            })
                                        }
                                    />

                                    <PositionCategoryDescription
                                        category={event.positionCategory}
                                        eventId={eventId}
                                        reload={reload}
                                    />
                                </Flex>

                                <Box css={{ flex: '1' }}>
                                    <PositionCategoryPositions />
                                </Box>
                            </Flex>
                        </Flex>
                    </Page.Content>
                </TabPanel>
            </Tabs>
        </Page.Root>
    );
};
