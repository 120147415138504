import { UpdateCustomFieldCell } from 'common-front/src/components/cells/updateCustomFieldCell';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Badge } from 'common/src/designSystem/components/badge';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { Cell } from 'common/src/designSystem/components/table/cell';
import {
    CustomFieldSlug,
    EventId,
    PositionsPositionFragment,
    PositionState,
    SegmentCustomFieldFragment
} from 'common/src/generated/types';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { useService } from 'common/src/util/dependencies/dependencies';
import { Emptyable } from 'common/src/util/emptyable';
import { LocaleFormats } from 'common/src/util/luxon';
import { PossibleColumn } from 'common/src/vo/segment';
import { PositionDefaultColumns } from 'common/src/vo/segments/positionsSegmentsService';
import * as React from 'react';
import { usePositionUpdateFieldMutation } from '../../../generated/graphqlHooks';
import { PositionCreatedByCell } from './cells/positionCreatedByCell';
import { PositionsAcronymCell } from './cells/positionsAcronymCell';
import { PositionsCategoryCell } from './cells/positionsCategoryCell';
import { PositionsColorCell } from './cells/positionsColorCell';
import { PositionsConditionsCell } from './cells/positionsConditionsCell';
import { PositionsIconCell } from './cells/positionsIconCell';
import { PositionsNameCell } from './cells/positionsNameCell';
import { PositionsTagsCell } from './cells/positionsTagsCell';
import { PositionRowResources } from './positionRowResources';

interface IPositionsCellsProps {
    column: PossibleColumn;
    customField: Emptyable<SegmentCustomFieldFragment>;
    eventId: EventId;
    row: PositionsPositionFragment;

    reloadRow(): void;
}

export const PositionsCells = (props: IPositionsCellsProps) => {
    const { translate } = useHeavent();
    const dateTimeService = useService(DateTimeService);
    const { mutate: positionUpdateField } = usePositionUpdateFieldMutation();
    const updateField = React.useCallback(
        (value: any, slug: CustomFieldSlug) =>
            positionUpdateField({
                eventId: props.eventId,
                positionId: props.row.id,
                slug,
                value
            }),
        [props.row, positionUpdateField]
    );
    const interval = React.useMemo(() => {
        if (props.row.range) {
            const start = props.row.range.start!;
            const end = props.row.range.end!;

            if (start.startOf('day').equals(end.startOf('day'))) {
                return dateTimeService.toLocaleString(start, LocaleFormats.DateOnly.MonthShort);
            } else {
                return `${dateTimeService.toLocaleString(
                    start,
                    LocaleFormats.DateOnly.MonthShort
                )} → ${dateTimeService.toLocaleString(end, LocaleFormats.DateOnly.MonthShort)}`;
            }
        } else {
            return '-';
        }
    }, [props.row]);

    return props.column.slug == PositionDefaultColumns.Id ? (
        <Cell width={100}>{props.row.id}</Cell>
    ) : props.column.slug === PositionDefaultColumns.Category ? (
        <PositionsCategoryCell
            positionCategory={props.row.category}
            positionId={props.row.id}
            reload={props.reloadRow}
        />
    ) : props.column.slug === PositionDefaultColumns.Name ? (
        <PositionsNameCell position={props.row} reload={props.reloadRow} />
    ) : props.column.slug === PositionDefaultColumns.Slot ? (
        <Cell>
            <Flex css={{ overflow: 'hidden' }} direction="column" width={1}>
                <Box font="gray900 textSm medium">{interval}</Box>

                <Box color="gray500">{translate('creneau', props.row.numberOfSlots)}</Box>
            </Flex>
        </Cell>
    ) : props.column.slug === PositionDefaultColumns.SlotName ? (
        <Cell>-</Cell>
    ) : props.column.slug === PositionDefaultColumns.Resources ? (
        <Cell width={256}>
            <PositionRowResources
                assignedResources={props.row.assignedResources}
                resources={props.row.resources}
            />
        </Cell>
    ) : props.column.slug === PositionDefaultColumns.Tags ? (
        <PositionsTagsCell position={props.row} reload={props.reloadRow} />
    ) : props.column.slug === PositionDefaultColumns.Conditions ? (
        <PositionsConditionsCell position={props.row} />
    ) : props.column.slug === PositionDefaultColumns.Address ? (
        <Cell>{props.row.address}</Cell>
    ) : props.column.slug === PositionDefaultColumns.Latitude ? (
        <Cell>{props.row.latitude}</Cell>
    ) : props.column.slug === PositionDefaultColumns.Longitude ? (
        <Cell>{props.row.longitude}</Cell>
    ) : props.column.slug === 'isVisible' ? (
        <Cell>
            <Flex align="center" gap="3" width={1}>
                {props.row.isVisible ? (
                    <>
                        <Box>
                            <I icon="square-check" />
                        </Box>

                        <Box>{translate('visible_27909')}</Box>
                    </>
                ) : (
                    <>
                        <Box>
                            <I icon="square" />
                        </Box>

                        <Box>{translate('non_visible_30040')}</Box>
                    </>
                )}
            </Flex>
        </Cell>
    ) : props.column.slug === PositionDefaultColumns.Acronym ? (
        <PositionsAcronymCell position={props.row} reload={props.reloadRow} />
    ) : props.column.slug === PositionDefaultColumns.Color ? (
        <PositionsColorCell position={props.row} reload={props.reloadRow} />
    ) : props.column.slug === PositionDefaultColumns.Icon ? (
        <PositionsIconCell position={props.row} reload={props.reloadRow} />
    ) : props.column.slug === PositionDefaultColumns.State ? (
        <Cell>
            {props.row.state === PositionState.Full ? (
                <Badge color="success">{translate(props.row.state)}</Badge>
            ) : (
                <Badge color="warning">{translate(props.row.state)}</Badge>
            )}
        </Cell>
    ) : props.column.slug === PositionDefaultColumns.FillingRate ? (
        <Cell>{props.row.fillingRate}%</Cell>
    ) : props.column.slug === PositionDefaultColumns.CheckedInRate ? (
        <Cell>{props.row.checkedInRate || 0}%</Cell>
    ) : props.column.slug === PositionDefaultColumns.CreatedBy ? (
        <PositionCreatedByCell createdBy={props.row.createdBy} />
    ) : (
        <UpdateCustomFieldCell
            customField={props.customField}
            fields={props.row.fields}
            mutate={updateField}
            reload={props.reloadRow}
        />
    );
};
