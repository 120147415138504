import { MobileOverlay } from 'common-front/src/components/mobileOverlay/mobileOverlay';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { styled } from 'common/src/designSystem/components/stitches';
import * as React from 'react';
import { useEventsQuery } from '../../generated/graphqlHooks';
import { EventSwitchMobile } from './eventSwitchMobile';

const _h3 = styled('h3', {
    color: '$gray800',
    fontWeight: '$bold',
    margin: '$3 $4'
});

export const EventSwitchSheetMobile = () => {
    const {
        params: { organizationId },
        translate
    } = useHeavent();
    const { loader, data } = useEventsQuery({ organizationId });

    return (
        <MobileOverlay>
            <_h3>{translate('liste_de_vos_v_44993')}</_h3>
            {loader || <EventSwitchMobile events={data.organization.events.nodes} />}
        </MobileOverlay>
    );
};
