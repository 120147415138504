import * as React from 'react';
import { Loader } from '../../../components/loader/loader';
import { RightPanel } from './rightPanel';
import { RightPanelBody } from './rightPanelBody';

interface IRightPanelLoaderProps {
    returnPathFallback: string;
}

export const RightPanelLoader = ({ returnPathFallback }: IRightPanelLoaderProps) => (
    <RightPanel returnToFallback={returnPathFallback} size="md">
        <RightPanelBody>
            <Loader />
        </RightPanelBody>
    </RightPanel>
);
