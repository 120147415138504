import { BackButton } from 'common-front/src/components/buttons/backButton';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import { EventsPaths } from 'common/src/util/paths/eventsPaths';
import { OrganizationsPaths } from 'common/src/util/paths/organizationsPaths';
import * as React from 'react';
import {
    EventUserProfilePrivateFields,
    OrganizationUserProfilePrivateFields
} from './privateFields/userProfilePrivateFields';
import { EventUserProfileTabs, OrganizationUserProfileTabs } from './userProfileTabs';

export const UserProfile = () => {
    const {
        params: { organizationId, eventId, userInfoId }
    } = useHeavent();

    return (
        <Flex
            css={{
                background: '$gray50',
                height: 'fit-content',
                minHeight: '100%',
                padding: '$8 $7'
            }}
            direction="column"
            gap="6"
            width={1}
        >
            <BackButton
                color="primary"
                returnPathFallback={
                    eventId
                        ? EventsPaths.EVENT({ organizationId, eventId })
                        : OrganizationsPaths.ORGANIZATION({ organizationId })
                }
            >
                Retour
            </BackButton>

            <Flex gap="6">
                <Box width={348}>
                    {eventId ? (
                        <EventUserProfilePrivateFields />
                    ) : (
                        <OrganizationUserProfilePrivateFields />
                    )}
                </Box>

                <Box css={{ flex: '1' }}>
                    {eventId ? (
                        <EventUserProfileTabs
                            basePath={HeaventPaths.EVENT_USER_PROFILE(
                                organizationId,
                                eventId,
                                userInfoId
                            )}
                        />
                    ) : (
                        <OrganizationUserProfileTabs
                            basePath={HeaventPaths.COMMUNITY_USER_PROFILE(
                                organizationId,
                                userInfoId
                            )}
                        />
                    )}
                </Box>
            </Flex>
        </Flex>
    );
};
