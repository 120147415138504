import { MobileOverlay } from 'common-front/src/components/mobileOverlay/mobileOverlay';
import { styled } from 'common/src/designSystem/components/stitches';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { useOrganizationsQuery } from '../../generated/graphqlHooks';
import { OrganizationSwitchMobile } from './organizationSwitchMobile';

const _h3 = styled('h3', {
    color: '$gray800',
    fontWeight: '$bold',
    margin: '$3 $4'
});

export const OrganizationSwitchSheetMobile = () => {
    const translate = useTranslate();
    const { loader, data } = useOrganizationsQuery({});

    return (
        <MobileOverlay>
            <_h3>{translate('liste_de_vos_or_87919')}</_h3>
            {loader || <OrganizationSwitchMobile organizations={data.organizations} />}
        </MobileOverlay>
    );
};
