import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { IIcon } from 'common/src/designSystem/components/i';
import { Spacer } from 'common/src/designSystem/components/spacer';
import * as React from 'react';
import { IconBackground } from './iconBackground';

interface IKpiCardProps {
    backgroundColor?: string;
    button?: React.ReactNode;
    value: string | number;
    icon?: IIcon;
    iconColor?: 'primary' | 'purple' | 'success' | 'warning';
    text: string;
}

export const KpiCard = ({
    backgroundColor,
    button,
    iconColor,
    value,
    icon,
    text
}: IKpiCardProps) => (
    <Flex
        css={{
            backgroundColor: backgroundColor ?? '$white',
            border: '1px solid $gray200',
            borderRadius: '$2',
            boxShadow: '$xs',
            flex: '1',
            overflow: 'hidden'
        }}
        direction="column"
    >
        <Flex
            css={{
                borderBottom: button ? '1px solid $gray200' : 'none',
                padding: '$6'
            }}
            direction="column"
        >
            {icon && (
                <>
                    <IconBackground color={iconColor ?? 'primary'} icon={icon} />

                    <Spacer height="6" />
                </>
            )}

            <Box color="gray500">{text}</Box>

            <Spacer height="2" />

            <Box color="gray900" fontSize="displayMd" fontWeight="semiBold">
                {value}
            </Box>
        </Flex>

        {button && (
            <Flex css={{ background: '$gray50', padding: '$4 $6' }} gap="4" justify="end">
                {button}
            </Flex>
        )}
    </Flex>
);
