import { ProgressBar } from 'common-front/src/designSystem/components/progressBar';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { Row } from 'common/src/designSystem/components/table/row';
import { DashboardMobileQuery } from 'common/src/generated/types';
import { PositionsPaths } from 'common/src/util/paths/positionsPaths';
import * as React from 'react';

interface IDashboardPosition {
    position: DashboardMobileQuery['event']['positions']['nodes'][number];
}

export const DashboardPosition = ({ position }: IDashboardPosition) => {
    const {
        history,
        params: { organizationId, eventId },
        translate
    } = useHeavent();

    return (
        <Row
            key={position.id}
            css={{ cursor: 'pointer' }}
            onClick={() => {
                history.push(
                    PositionsPaths.POSITION({
                        organizationId,
                        eventId,
                        positionId: position.id
                    })
                );
            }}
        >
            <Cell>
                <Flex direction="column" gap="2" width={1}>
                    <Flex gap="3">
                        <Box color="gray700" css={{ flex: '1' }}>
                            {position.name}
                        </Box>

                        <Box color="gray700">
                            {translate(
                                '_1_2_membres_04485',
                                position.assignedResources,
                                position.resources
                            )}
                        </Box>
                    </Flex>

                    <Box width={1}>
                        <ProgressBar percent={position.assignedResources / position.resources} />
                    </Box>
                </Flex>
            </Cell>
        </Row>
    );
};
