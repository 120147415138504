import { SlotsBarChart } from 'common-front/src/charts/slotsBarChart';
import { DetailBox } from 'common-front/src/designSystem/components/detailBox';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import * as React from 'react';
import { useAccreditationChartQuery } from '../../../../generated/graphqlHooks';

export const AccreditationChartCard = () => {
    const {
        params: { eventId, accreditationId }
    } = useHeavent();
    const { data, loader } = useAccreditationChartQuery({ eventId, accreditationId });
    const slots = React.useMemo(() => {
        if (data.event) {
            return data.event.accreditation.hasSlots
                ? data.event.accreditation.slots
                : [data.event.accreditation.hiddenSlot];
        } else {
            return [];
        }
    }, [data.event]);

    return (
        loader || (
            <DetailBox title="Reporting">
                <Box height={380} width={1}>
                    <SlotsBarChart
                        accreditation={data.event.accreditation}
                        slots={slots}
                        weezevent={data.event.weezevent}
                    />
                </Box>
            </DetailBox>
        )
    );
};
