import { HeaderCell } from 'common/src/designSystem/components/table/headerCell';
import { HeaderRow } from 'common/src/designSystem/components/table/headerRow';
import { Table } from 'common/src/designSystem/components/table/table';
import { AccreditationsSlotFragment } from 'common/src/generated/types';
import { sortBy } from 'lodash-es';
import * as React from 'react';
import { useHeavent } from '../../../hooks/useHeavent';
import { Accreditation } from '../v2/accreditation';
import {
    AccreditationState,
    useAccreditationsRightPanelContext
} from './accreditationsRightPanelContext';
import { AccreditationsRightPanelSlot } from './accreditationsRightPanelSlot';
import { AccreditationsRightPanelSlotActions } from './accreditationsRightPanelSlotActions';

interface IAccreditationsRightPanelAccreditationProps {
    accreditation: AccreditationsSlotFragment['accreditation'];
    state: AccreditationState;
}

export const AccreditationsRightPanelAccreditation = (
    props: IAccreditationsRightPanelAccreditationProps
) => {
    const { translate } = useHeavent();
    const { assignedSlots, showActions, wishedSlots } = useAccreditationsRightPanelContext();
    const slots = React.useMemo(
        () =>
            sortBy(
                (props.state === 'assigned' ? assignedSlots : wishedSlots).filter(
                    (s) => s.accreditationId === props.accreditation.id
                ),
                (s) => [s.date?.toMillis() ?? 1, s.name?.toLowerCase() || '']
            ),
        [assignedSlots, wishedSlots, props.accreditation, props.state]
    );

    return (
        <Accreditation
            accreditation={props.accreditation}
            displaySlots={props.accreditation.hasSlots}
            numberOfSlots={slots.length}
            renderActions={
                !props.accreditation.hasSlots && showActions
                    ? () => (
                          <AccreditationsRightPanelSlotActions
                              accreditationSlotId={props.accreditation.hiddenSlotId}
                              state={props.state}
                          />
                      )
                    : undefined
            }
        >
            <Table
                css={{
                    '& .table-row:last-child': { borderBottom: 'none' }
                }}
                hideBorder={true}
            >
                <HeaderRow>
                    <HeaderCell>{translate('date_du_cr_neau_82295')}</HeaderCell>
                    <HeaderCell>{translate('nom_du_cr_neau_54351')}</HeaderCell>
                    {showActions && <HeaderCell />}
                </HeaderRow>

                {slots.map((slot) => (
                    <AccreditationsRightPanelSlot key={slot.id} slot={slot} state={props.state} />
                ))}
            </Table>
        </Accreditation>
    );
};
