import { Checkbox, ICheckboxState } from 'common-front/src/designSystem/components/checkbox';
import { closeOtherDropdowns } from 'common-front/src/designSystem/components/dropdown/dropdown';
import { Content } from 'common-front/src/designSystem/components/tooltip/content';
import { Tooltip } from 'common-front/src/designSystem/components/tooltip/tooltip';
import { Trigger } from 'common-front/src/designSystem/components/tooltip/trigger';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { useSlugToCustomField } from 'common-front/src/hooks/useSlugToCustomField';
import { Box } from 'common/src/designSystem/components/box';
import { I } from 'common/src/designSystem/components/i';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { CellControls } from 'common/src/designSystem/components/table/cellControls';
import { Row } from 'common/src/designSystem/components/table/row';
import {
    PositionId,
    PositionsPositionFragment,
    SegmentCustomFieldFragment
} from 'common/src/generated/types';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import { PossibleColumn } from 'common/src/vo/segment';
import * as React from 'react';
import { useEventContext } from '../../../events/show/eventContext';
import { PositionRowDropdown } from './positionRowDropdown';
import { PositionsCells } from './positionsCells';

interface IPositionRowProps {
    columns: PossibleColumn[];
    customFields: SegmentCustomFieldFragment[];
    position: PositionsPositionFragment;
    state: ICheckboxState;

    reload(): void;
    reloadPosition(id: PositionId): void;
    toggleRow(key: string, newState: ICheckboxState): void;
}

export const PositionRow = (props: IPositionRowProps) => {
    const {
        params: { organizationId, eventId },
        translate
    } = useHeavent();
    const { isEventAdmin, isPositionCategoryAdmin } = useEventContext();
    const slugToCustomField = useSlugToCustomField(props.customFields);
    const reloadPosition = React.useCallback(() => {
        props.reloadPosition(props.position.id);
    }, [props.position, props.reloadPosition]);

    return (
        <Row
            css={{
                '&:hover': {
                    background: '$gray100'
                }
            }}
            onMouseLeave={(e) => {
                closeOtherDropdowns(e.target);
            }}
        >
            {isEventAdmin && (
                <Cell justify="center" width={48}>
                    <Checkbox
                        state={props.state}
                        onClick={(newState, e) => {
                            e.nativeEvent.stopImmediatePropagation();
                            e.stopPropagation();

                            props.toggleRow(`p${props.position.id}`, newState);
                        }}
                    />
                </Cell>
            )}

            {props.columns.map((field) => {
                const customField = slugToCustomField.get(field.slug);

                return (
                    <PositionsCells
                        key={field.slug}
                        column={field}
                        customField={customField}
                        eventId={eventId}
                        reloadRow={reloadPosition}
                        row={props.position}
                    />
                );
            })}

            {isPositionCategoryAdmin() && (
                <CellControls justify="center">
                    {isPositionCategoryAdmin(props.position.category.id) && (
                        <>
                            <Tooltip>
                                <Trigger>
                                    <Box>
                                        <I
                                            icon="pen"
                                            to={HeaventPaths.EDIT_POSITION(
                                                organizationId,
                                                eventId,
                                                props.position.id
                                            )}
                                        />
                                    </Box>
                                </Trigger>

                                <Content placement="top">{translate('_diter_62574')}</Content>
                            </Tooltip>

                            <PositionRowDropdown position={props.position} reload={props.reload} />
                        </>
                    )}
                </CellControls>
            )}
        </Row>
    );
};
