import { PathParam, UserInfoIdPathParam } from '../pathsTypes';

export const buildPath = (components: Array<string | PathParam>) => components.join('/');

const slugs = {
    ITEMS: {
        FIELDS: {
            PLURAL: 'fields',
            SINGLE: 'field'
        }
    },
    ACTIONS: {
        AUTH: 'auth',
        EDIT: 'edit',
        CREATE: 'create',
        EXPORT: 'export',
        EXPORT_BASIC: 'export/basic',
        EXPORT_API: 'export/api',
        IMPORT: 'import'
    },
    SECTIONS: {
        INFORMATION: 'information',
        DASHBOARD: 'dashboard',
        REGISTRATION: 'registration',
        DOCUMENTS: 'documents',
        PROFILE: 'profile',
        REPORTING: 'reporting',
        FORMS: 'forms',
        FORM: 'form',
        MEMBER: 'member',
        MEMBERS: 'members'
    },
    VIEWS: {
        LIST: 'list'
    }
} as const;
export const commonSlugs = slugs;

export type UserParams<T> = T & {
    userInfoId: UserInfoIdPathParam;
};

export const CommonPaths = {
    //
    // CREATE
    //

    //
    // READ
    //

    AUTH: (path: string) => buildPath([path, commonSlugs.ACTIONS.AUTH]),
    SHOW: {
        LIST: (path: string) => buildPath([path, commonSlugs.VIEWS.LIST])
    },
    EXPORT: {
        BASIC: (path: string) => buildPath([path, commonSlugs.ACTIONS.EXPORT])
    },

    //
    // UPDATE
    //

    CREATE: (path: string) => buildPath([path, commonSlugs.ACTIONS.CREATE]),
    EDIT: (path: string) => buildPath([path, commonSlugs.ACTIONS.EDIT])

    //
    // DELETE
    //
};
