import { UpdateLinkTextCell } from 'common-front/src/components/cells/updateLinkTextCell';
import { TextInput } from 'common-front/src/designSystem/components/textInput';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { useSegmentsContext } from 'common-front/src/segments/segmentsContext';
import { LinkText } from 'common/src/designSystem/components/linkText';
import { DelegationProperty, DelegationsDelegationFragment } from 'common/src/generated/types';
import * as React from 'react';
import { useDelegationsContext } from '../delegationsContext';
import { DelegationsUpdateCell } from './delegationsUpdateCell';

interface IDelegationsNameCellProps {
    delegation: DelegationsDelegationFragment;

    reload(): void;
}

export const DelegationsNameCell = (props: IDelegationsNameCellProps) => {
    const { translate } = useHeavent();
    const { isEditMode } = useSegmentsContext();
    const { getShowPath } = useDelegationsContext();

    return (
        <DelegationsUpdateCell
            delegation={props.delegation}
            initialValue={props.delegation.name}
            property={DelegationProperty.Name}
            reload={props.reload}
            renderInput={(value, setValue) => (
                <TextInput
                    label={translate('nom_de_la_d_l_g_28087')}
                    value={value}
                    onChange={setValue}
                />
            )}
            renderValue={() => (
                <UpdateLinkTextCell isEditMode={isEditMode} title={props.delegation.name}>
                    <LinkText to={getShowPath(props.delegation.id)}>
                        {props.delegation.name}
                    </LinkText>
                </UpdateLinkTextCell>
            )}
        />
    );
};
