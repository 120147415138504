import { FullPageContent } from 'common-front/src/components/mobileFullPage/fullPageContent';
import { FullPageHeader } from 'common-front/src/components/mobileFullPage/fullPageHeader';
import { MobileFullPage } from 'common-front/src/components/mobileFullPage/mobileFullPage';
import { TextInput } from 'common-front/src/designSystem/components/textInput';
import { Flex } from 'common/src/designSystem/components/flex';
import { styled } from 'common/src/designSystem/components/stitches';
import { useLocation, useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { useState } from 'react';
import { PositionsRecentSearchesMobile } from './positionsRecentSearchesMobile';
import { PositionsSearchResultMobile } from './positionsSearchResultMobile';

const CancelLink = styled(Flex, {
    color: '$blue600'
});

export const PositionsSearchMobile = () => {
    const translate = useTranslate();
    const location = useLocation();
    const [value, setValue] = useState(location.state?.search || '');
    const [search, setSearch] = useState(location.state?.search || '');
    const cancel = () => {
        setValue('');
        setSearch('');
    };
    const replaySearch = (recentSearch: string) => {
        setValue(recentSearch);
        setSearch(recentSearch);
    };

    return (
        <MobileFullPage>
            <FullPageHeader>
                <Flex align="center" css={{ marginRight: '$4', flexGrow: 1 }} gap="3">
                    <TextInput
                        icon="search"
                        placeholder={translate('rechercher_une_69998')}
                        value={value}
                        onChange={(v) => {
                            setValue(v);
                            if (!v) {
                                setSearch(v);
                            }
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                setSearch(value);
                            }
                        }}
                    />
                    {search && (
                        <CancelLink onClick={cancel}>{translate('annuler_48254')}</CancelLink>
                    )}
                </Flex>
            </FullPageHeader>
            <FullPageContent>
                {search ? (
                    <PositionsSearchResultMobile search={search} />
                ) : (
                    <PositionsRecentSearchesMobile replaySearch={replaySearch} />
                )}
            </FullPageContent>
        </MobileFullPage>
    );
};
