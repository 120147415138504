import { injectable } from 'inversify';
import * as yup from 'yup';
import { DocumentInput } from '../generated/types';
import { TranslationService } from '../services/translationService';
import { CsvInputService } from './csvInput';
import { DocumentInputService } from './documentInput';
import { InputService } from './inputService';

export type DelegationsImportInput = {
    csv: DocumentInput;
};

export const DELEGATIONS_IMPORT_COLUMNS_DEFAULT = {
    /* Required */
    Name: 'name',
    FormId: 'formId',

    /* Optional */
    LeadersEmails: 'leadersEmails',
    LeadersFirstNames: 'leadersFirstnames',
    LeadersLastNames: 'leadersLastnames',
    Deadline: 'deadline',
    MaxResources: 'maxResources',
    PrivateNote: 'privateNote',
    Tags: 'tags'
};

const DELEGATIONS_IMPORT_COLUMNS_REQUIRED = [
    DELEGATIONS_IMPORT_COLUMNS_DEFAULT.Name,
    DELEGATIONS_IMPORT_COLUMNS_DEFAULT.FormId
];

@injectable()
export class DelegationsImportInputService extends InputService {
    constructor(
        private documentInputService: DocumentInputService,
        private csvInputService: CsvInputService,
        translationService: TranslationService
    ) {
        super(translationService);
    }

    default(): { input: DelegationsImportInput } {
        return {
            input: {
                csv: this.documentInputService.documentInputDefault()
            }
        };
    }

    schema() {
        return yup.object().shape({
            input: yup.object().shape({
                csv: this.csvInputService.schema({
                    defaultColumns: DELEGATIONS_IMPORT_COLUMNS_DEFAULT,
                    requiredColumns: DELEGATIONS_IMPORT_COLUMNS_REQUIRED,
                    hasCustomFields: false
                })
            })
        });
    }
}
