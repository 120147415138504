import {
    DelegationId,
    EventId,
    OrganizationId,
    VolunteerRegistrationFragment
} from 'common/src/generated/types';
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { AccreditationsRightPanel } from '../../components/accreditations/rightPanel/accreditationsRightPanel';
import { useHeavent } from '../../hooks/useHeavent';
import { AvailabilitiesRightPanel } from '../rightPanels/availabilitiesRightPanel';

interface IVolunteerPanelsProps {
    // These must be string, not functions that can return route matching paths (with e.g. `:organizationId`)
    // because otherwise the path to render the panels matches for all users simulteneously.
    // @see comment on {@link fullScreenPopup.tsx} for more about our use of `<Route />`s for things like panels.
    accreditationPanelPath: string;
    availabilitiesPanelPath: string;
    canAccredit: boolean;
    delegationId?: DelegationId;
    eventId: EventId;
    organizationId: OrganizationId;
    returnPathFallback: string;
    showAvailabilitiesEditButton: boolean;
    volunteerRegistration: VolunteerRegistrationFragment;

    reloadRow(): void;
}

export const VolunteerPanels = (props: IVolunteerPanelsProps) => {
    const { history } = useHeavent();

    return (
        <Switch>
            <Route path={props.accreditationPanelPath}>
                <AccreditationsRightPanel
                    basePath={props.accreditationPanelPath}
                    eventId={props.eventId}
                    showActions={props.canAccredit}
                    userInfoId={props.volunteerRegistration.userInfo.id}
                    volunteerRegistration={props.volunteerRegistration}
                    onClose={() => {
                        props.reloadRow();

                        history.goBack(props.returnPathFallback);
                    }}
                />
            </Route>

            <Route path={props.availabilitiesPanelPath}>
                <AvailabilitiesRightPanel
                    delegationId={props.delegationId}
                    eventId={props.eventId}
                    organizationId={props.organizationId}
                    returnPathFallback={props.availabilitiesPanelPath}
                    showEditButton={props.showAvailabilitiesEditButton}
                    userInfoId={props.volunteerRegistration.userInfo.id}
                />
            </Route>
        </Switch>
    );
};
