import { DeleteModal } from 'common-front/src/designSystem/components/deleteModal';
import {
    closeOtherDropdowns,
    Dropdown
} from 'common-front/src/designSystem/components/dropdown/dropdown';
import { ItemIcon } from 'common-front/src/designSystem/components/dropdown/item';
import { Menu } from 'common-front/src/designSystem/components/dropdown/menu';
import { Trigger } from 'common-front/src/designSystem/components/dropdown/trigger';
import { Select } from 'common-front/src/designSystem/components/select/select';
import { AvatarNameEmail } from 'common/src/components/avatarNameEmail';
import { Badge } from 'common/src/designSystem/components/badge';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { Row } from 'common/src/designSystem/components/table/row';
import {
    EventAdminsLeadersQuery,
    OrganizationId,
    Role,
    RoleId,
    TeamQuery
} from 'common/src/generated/types';
import { useHistory, useTranslate } from 'common/src/util/dependencies/dependencies';
import { DelegationsPaths } from 'common/src/util/paths/delegationsPaths';
import { PositionsPaths } from 'common/src/util/paths/positionsPaths';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';
import {
    useEmailEventInviteResendMutation,
    useUserLeaderDeleteMutation,
    useUserPositionCategoryUpdateMutation,
    useUserPositionUpdateMutation
} from '../../generated/graphqlHooks';

interface IEventLeaderProps {
    event: TeamQuery['organization']['events']['nodes'][0];
    leader: EventAdminsLeadersQuery['event']['adminsLeaders']['nodes'][0];
    organizationId: OrganizationId;
    roles: Array<Pick<Role, 'id' | 'name'>>;

    reload(): void;
}

export const EventLeader = (props: IEventLeaderProps) => {
    const translate = useTranslate();
    const history = useHistory();
    const { mutate: updateUserPositionCategory } = useUserPositionCategoryUpdateMutation();
    const { mutate: updateUserPosition } = useUserPositionUpdateMutation();
    const { mutate: deleteUserLeader } = useUserLeaderDeleteMutation();
    const { mutate: emailResend } = useEmailEventInviteResendMutation();
    const [isDeleteOpen, setIsDeleteOpen] = React.useState(false);

    return (
        <Row>
            <Cell>
                <AvatarNameEmail
                    hasName={isNonEmptyString(props.leader.userInfo.name)}
                    userInfo={props.leader.userInfo}
                />
            </Cell>
            <Cell>
                <Badge>{translate('reponsable_82458')}</Badge>
            </Cell>
            <Cell>
                <Box
                    color="primary700"
                    css={{ cursor: 'pointer', textDecoration: 'underline' }}
                    onClick={() => {
                        if (props.leader.delegation) {
                            history.push(
                                DelegationsPaths.DELEGATION({
                                    organizationId: props.organizationId,
                                    eventId: props.event.id,
                                    delegationId: props.leader.delegation.id
                                })
                            );
                        } else if (props.leader.position) {
                            history.push(
                                PositionsPaths.POSITION({
                                    organizationId: props.organizationId,
                                    eventId: props.event.id,
                                    positionId: props.leader.position.id
                                })
                            );
                        } else if (props.leader.positionCategory) {
                            history.push(
                                PositionsPaths.POSITION_CATEGORY({
                                    organizationId: props.organizationId,
                                    eventId: props.event.id,
                                    positionCategoryId: props.leader.positionCategory.id
                                })
                            );
                        }
                    }}
                >
                    {props.leader.delegation
                        ? props.leader.delegation.name
                        : props.leader.position
                          ? props.leader.position.name
                          : props.leader.positionCategory
                            ? props.leader.positionCategory.name
                            : ''}
                </Box>
            </Cell>
            <Cell>
                {props.leader.delegation ? null : (
                    <Select
                        shouldParseAsInt={true}
                        value={props.leader.roleId!}
                        onChange={async (roleId: RoleId) => {
                            if (props.leader.position) {
                                await updateUserPosition({
                                    eventId: props.event.id,
                                    userInfoId: props.leader.userInfo.id,
                                    positionId: props.leader.position.id,
                                    roleId
                                });
                            } else if (props.leader.positionCategory) {
                                await updateUserPositionCategory({
                                    eventId: props.event.id,
                                    userInfoId: props.leader.userInfo.id,
                                    positionCategoryId: props.leader.positionCategory.id,
                                    roleId
                                });
                            }

                            props.reload();
                        }}
                    >
                        {props.roles.map((role, i) => (
                            <option key={i} value={role.id}>
                                {translate(role.name)}
                            </option>
                        ))}
                    </Select>
                )}
            </Cell>
            <Cell justify="end" width={100}>
                <Dropdown>
                    <Trigger>
                        <Flex
                            align="center"
                            css={{ cursor: 'pointer' }}
                            height={20}
                            justify="center"
                            width={20}
                        >
                            <I icon="ellipsis-vertical" />
                        </Flex>
                    </Trigger>

                    <Menu placement="bottom-end">
                        <ItemIcon
                            icon="paper-plane"
                            onClick={async (e) => {
                                closeOtherDropdowns(e.target);

                                await emailResend({
                                    eventId: props.event.id,
                                    userInfoId: props.leader.userInfo.id
                                });
                            }}
                        >
                            {translate('r_envoyer_l_e_96215')}
                        </ItemIcon>

                        <ItemIcon
                            color="red"
                            icon="trash-can"
                            onClick={(e) => {
                                closeOtherDropdowns(e.target);

                                setIsDeleteOpen(true);
                            }}
                        >
                            {translate('supprimer_l_acc_85342')}
                        </ItemIcon>
                    </Menu>
                </Dropdown>
            </Cell>

            {isDeleteOpen && (
                <DeleteModal
                    buttonText={'Supprimer'}
                    subtext={translate('_tes_vous_s_r_d_70751', props.leader.userInfo.email)}
                    text={translate('supprimer_l_acc_85342')}
                    onClose={() => {
                        setIsDeleteOpen(false);
                    }}
                    onDelete={async () => {
                        await deleteUserLeader({
                            eventId: props.event.id,
                            userInfoId: props.leader.userInfo.id
                        });

                        props.reload();
                    }}
                />
            )}
        </Row>
    );
};
