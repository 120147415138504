import { Badge } from 'common/src/designSystem/components/badge';
import { EventId, Form, OrganizationId, UsersInfoId } from 'common/src/generated/types';
import { useHistory } from 'common/src/util/dependencies/dependencies';
import { Emptyable } from 'common/src/util/emptyable';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import { CommunityFormEditPath, EventFormEditPath } from 'common/src/util/membersPaths';
import * as React from 'react';

interface IUserFormBadgeProps {
    editDisabled?: boolean;
    eventId: Emptyable<EventId>;
    form: Pick<Form, 'id' | 'name'>;
    organizationId: OrganizationId;
    userInfoId?: UsersInfoId;

    editPath?: EventFormEditPath | CommunityFormEditPath;
}

export const UserFormBadge = ({
    editDisabled,
    editPath,
    eventId,
    form,
    organizationId,
    userInfoId
}: IUserFormBadgeProps) => {
    const history = useHistory();

    return (
        <Badge
            color="primary"
            cursor={!editDisabled ? 'pointer' : 'default'}
            onContentClick={() => {
                if (!editDisabled) {
                    history.push(
                        editPath
                            ? eventId
                                ? (editPath as EventFormEditPath)(
                                      organizationId,
                                      userInfoId!,
                                      eventId,
                                      form.id
                                  )
                                : (editPath as CommunityFormEditPath)(
                                      organizationId,
                                      userInfoId!,
                                      form.id
                                  )
                            : eventId
                              ? HeaventPaths.FORM(organizationId, eventId, form.id)
                              : HeaventPaths.COMMUNITY_FORM(organizationId, form.id)
                    );
                }
            }}
        >
            {form.name}
        </Badge>
    );
};
