import { Checkbox } from 'common-front/src/designSystem/components/checkbox';
import { Badge } from 'common/src/designSystem/components/badge';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { Row } from 'common/src/designSystem/components/table/row';
import { MassAssignSlotsQuery, PositionsSlotId } from 'common/src/generated/types';
import { IntervalService } from 'common/src/services/intervalService';
import { addOrRemove } from 'common/src/util/array';
import { useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';

interface IMassAssignPositionSlotProps {
    slot: MassAssignSlotsQuery['event']['positionsSlots']['nodes'][number];
    positionsSlotsIds: PositionsSlotId[];

    change(name: string, value: any): void;
}

export const MassAssignPositionSlot = (props: IMassAssignPositionSlotProps) => {
    const translate = useTranslate();
    const intervalService = useService(IntervalService);
    const state = props.positionsSlotsIds.includes(props.slot.id) ? 'checked' : 'unchecked';

    return (
        <Row>
            <Cell justify="center" width={48}>
                <Checkbox
                    state={state}
                    onClick={(newState) => {
                        props.change(
                            'massAssign.positionsSlotsIds',
                            addOrRemove(
                                props.positionsSlotsIds,
                                props.slot.id,
                                newState === 'checked'
                            )
                        );
                    }}
                />
            </Cell>

            <Cell css={{ textTransform: 'capitalize' }}>
                {intervalService.toDisplayString(props.slot.range, {
                    formats: { displayWeekday: true }
                })}
            </Cell>

            <Cell>{isNonEmptyString(props.slot.name) ? props.slot.name : '-'}</Cell>

            <Cell>
                <Badge
                    color={
                        props.slot.assignedResources >= props.slot.resources ? 'success' : 'warning'
                    }
                    leftIcon="user-group"
                >
                    {translate(
                        '_1_2_membres_04485',
                        props.slot.assignedResources,
                        props.slot.resources
                    )}
                </Badge>
            </Cell>
        </Row>
    );
};
