import { Button } from 'common-front/src/designSystem/components/button';
import { Modal } from 'common-front/src/designSystem/components/modal/modal';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';

interface INotSavedModalProps {
    isOpen: boolean;

    onClose(): void;
}

export const NotSavedModal = (props: INotSavedModalProps) => {
    const {
        history,
        params: { organizationId, eventId }
    } = useHeavent();

    return (
        <Modal.Root isOpen={props.isOpen} onClose={props.onClose}>
            <Modal.Portal>
                <Modal.Header icon="triangle-exclamation">Changements non sauvegardés</Modal.Header>

                <Modal.Content>
                    <Flex align="center" direction="column" gap="1" width={1}>
                        <Box font="gray900 textLg medium">
                            Voulez-vous quitter cette page sans sauvegarder ?
                        </Box>

                        <Box color="gray500">
                            Des données ne sont pas sauvegardées et seront définitivement perdues
                        </Box>
                    </Flex>
                </Modal.Content>

                <Modal.Buttons>
                    <Button
                        color="error"
                        onClick={() =>
                            history.goBack(HeaventPaths.DOCUMENTS(organizationId, eventId))
                        }
                    >
                        Quitter sans sauvegarder
                    </Button>
                </Modal.Buttons>
            </Modal.Portal>
        </Modal.Root>
    );
};
