import { AccreditationsCategories } from 'common-front/src/components/accreditations/v2/accreditationsCategories';
import { Button } from 'common-front/src/designSystem/components/button';
import { useStateDebounce } from 'common-front/src/hooks/useStateDebounce';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { EventId, SegmentId } from 'common/src/generated/types';
import { IMassAssignValues } from 'common/src/input/massAssignInput';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { isNonEmptyString } from 'common/src/util/string';
import { sortBy, uniqBy } from 'lodash-es';
import * as React from 'react';
import { useMassAssignSlotsQuery } from '../../generated/graphqlHooks';
import { MassAssignCategory } from './massAssignCategory';
import { MassAssignFilters } from './massAssignFilters';

interface IMassAffectStep2Props {
    eventId: EventId;
    numberOfSelected: number;
    submitting: boolean;
    values: IMassAssignValues;

    change(name: string, value: any): void;
    handleSubmit(): void;
}

export const MassAssignStep2 = (props: IMassAffectStep2Props) => {
    const translate = useTranslate();
    const [name, nameDebounced, setName] = useStateDebounce('');
    const [segmentId, setSegmentId] = React.useState<SegmentId>(-1 as SegmentId);
    const [excludeFull, setExcludeFull] = React.useState(false);
    const { data, loader } = useMassAssignSlotsQuery({
        eventId: props.eventId,
        positionName: isNonEmptyString(nameDebounced) ? nameDebounced : undefined,
        segmentId: segmentId === -1 ? undefined : segmentId,
        excludeFull
    });
    const categories = React.useMemo(
        () =>
            sortBy(
                uniqBy(
                    (data.event?.positionsSlots.nodes ?? []).map((ps) => ps.positionCategory),
                    (pc) => pc.id
                ),
                (pc) => pc.name.toLowerCase()
            ),
        [data.event]
    );
    const positions = React.useMemo(
        () =>
            uniqBy(
                (data.event?.positionsSlots.nodes ?? []).map((ps) => ps.position),
                (p) => p.id
            ),
        [data.event]
    );

    return (
        <>
            <MassAssignFilters
                excludeFull={excludeFull}
                name={name}
                segmentId={segmentId}
                segments={data.event?.segments ?? []}
                setExcludeFull={(newExcludeFull) => {
                    setExcludeFull(newExcludeFull);
                }}
                setName={setName}
                setSegmentId={(newSegmentId) => {
                    setSegmentId(newSegmentId);
                }}
            />

            <Spacer height="6" />

            {loader || (
                <AccreditationsCategories
                    css={{ border: '1px solid $gray100', borderRadius: '$1' }}
                >
                    {categories.map((category) => (
                        <MassAssignCategory
                            key={category.id}
                            category={category}
                            change={props.change}
                            positions={positions}
                            positionsSlotsIds={props.values.massAssign.positionsSlotsIds}
                            slots={data.event?.positionsSlots.nodes ?? []}
                        />
                    ))}
                </AccreditationsCategories>
            )}

            <Spacer height="7" />

            <Flex>
                <Button isLoading={props.submitting} onClick={props.handleSubmit}>
                    {translate('affecter_les_1_56142', props.numberOfSelected)}
                </Button>
            </Flex>
        </>
    );
};
