import { TabPanel } from 'common-front/src/designSystem/components/tabs/tabPanel';
import { FormSlots } from 'common-front/src/forms/slots/formSlots';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { UserUpdateLayout } from 'common-front/src/users/update/userUpdateLayout';
import { IUserUpdateSlotsValues } from 'common-front/src/users/updateSlots/userUpdateSlotsValues';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { UserUpdateSlotsInfosQuery } from 'common/src/generated/types';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';

interface IUserUpdateSlotsProps {
    event: UserUpdateSlotsInfosQuery['event'];
    forms: UserUpdateSlotsInfosQuery['event']['forms'];
    isLoading: boolean;
    userInfo: UserUpdateSlotsInfosQuery['organization']['userInfo'];
    values: IUserUpdateSlotsValues;

    change(name: string, value: any): void;
    handleSubmit(): void;
}

export const UserUpdateSlots = (props: IUserUpdateSlotsProps) => {
    const {
        translate,
        params: { organizationId, eventId, userInfoId }
    } = useHeavent();

    return (
        <UserUpdateLayout
            forms={props.forms}
            getTabPath={(formId) =>
                HeaventPaths.EDIT_USER_SLOTS_FORM(organizationId, eventId, userInfoId, formId)
            }
            handleSubmit={props.handleSubmit}
            isLoading={props.isLoading}
            returnPathFallback={HeaventPaths.VOLUNTEERS(organizationId, eventId)}
            title={translate('mise_jour_des_28904', props.userInfo.nameOrEmail)}
        >
            {props.forms.map((form, index) => (
                <TabPanel
                    key={form.id}
                    path={HeaventPaths.EDIT_USER_SLOTS_FORM(
                        organizationId,
                        eventId,
                        userInfoId,
                        form.id
                    )}
                >
                    <Spacer height="7" />

                    <FormSlots
                        change={props.change}
                        currentSlots={props.values.userInfo.slots[index].slots}
                        customSlotsPeriods={form.customSlotsPeriods}
                        daysDisplay={form.daysDisplay}
                        event={props.event}
                        prefix={`userInfo.slots[${index}]`}
                        slotDisplay={form.slotDisplay}
                    />
                </TabPanel>
            ))}
        </UserUpdateLayout>
    );
};
