import { SlotsBarChart } from 'common-front/src/charts/slotsBarChart';
import { DetailBox } from 'common-front/src/designSystem/components/detailBox';
import { Box } from 'common/src/designSystem/components/box';
import { Accreditation, DelegationReportingQuery } from 'common/src/generated/types';
import * as React from 'react';

interface IDelegationReportingAccreditationProps {
    accreditation: Pick<Accreditation, 'id' | 'name' | 'hasSlots'>;
    event: DelegationReportingQuery['event'];
}

export const DelegationReportingAccreditation = (props: IDelegationReportingAccreditationProps) => {
    const slots = React.useMemo(
        () =>
            props.event.delegation.accreditationsSlots
                .filter((das) => das.accreditation.id === props.accreditation.id)
                .map((das) => ({
                    maxResources: das.maxResources,
                    assignedResources: das.assignedResources,
                    scannedResources: das.scannedResources,
                    wishedResources: das.wishedResources,
                    name: das.accreditationSlot.name,
                    date: das.accreditationSlot.date
                })),
        [props.accreditation, props.event]
    );

    return (
        <DetailBox title={props.accreditation.name}>
            <Box height={380} width={1}>
                <SlotsBarChart
                    accreditation={props.accreditation}
                    slots={slots}
                    weezevent={props.event.weezevent}
                />
            </Box>
        </DetailBox>
    );
};
