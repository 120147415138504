import { Flex } from 'common/src/designSystem/components/flex';
import { ILinkProps } from 'common/src/designSystem/components/link';
import { useLocation } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';

interface IItemProps extends Pick<ILinkProps, 'to' | 'replace'> {
    children: React.ReactNode;

    isActive?(pathname: string): boolean;
    onClick?(): Promise<any>;
}

export const Item = ({
    children,
    isActive: _isActive,
    onClick,
    replace = true,
    to
}: IItemProps) => {
    const location = useLocation();
    const isActive = _isActive?.(location.pathname) ?? location.pathname === to;

    return (
        <Flex
            align="center"
            css={{
                borderBottom: isActive ? '2px solid $primary700' : '2px solid transparent',
                borderTop: '2px solid transparent',
                color: isActive ? '$primary700' : '$gray500',
                cursor: 'pointer',
                fontWeight: '$medium',
                px: '$3'
            }}
            height={1}
            replace={replace}
            to={to}
            onClick={async () => {
                if (onClick) {
                    await onClick();
                }
            }}
        >
            {children}
        </Flex>
    );
};
