import { QueryLoading } from 'common-front/src/components/graphql/queryLoading';
import { useHistory } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import { EventsPaths } from 'common/src/util/paths/eventsPaths';
import * as React from 'react';
import { useHomeOrganizationsQuery } from '../generated/graphqlHooks';

export const Home = () => {
    const { data, loader } = useHomeOrganizationsQuery({});
    const history = useHistory();

    return (
        <QueryLoading
            loader={loader}
            onLoad={() => {
                const organization = data.organizations.find(
                    ({ permissions }) =>
                        permissions.isOrganizationAdmin ||
                        permissions.isOrganizationEventAdmin ||
                        permissions.isOrganizationEventLeader
                );

                if (organization) {
                    history.replace(EventsPaths.EVENTS({ organizationId: organization.id }));
                } else {
                    history.replace(HeaventPaths.CREATE_EVENT_WITH_ORGANIZATION);
                }
            }}
        />
    );
};
