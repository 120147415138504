import { AccreditationCategory } from 'common-front/src/components/accreditations/v2/accreditationCategory';
import { MassAssignSlotsQuery, PositionsSlotId } from 'common/src/generated/types';
import { sortBy } from 'lodash-es';
import * as React from 'react';
import { MassAssignPosition } from './massAssignPosition';

interface IMassAssignCategoryProps {
    category: MassAssignSlotsQuery['event']['positionsSlots']['nodes'][number]['positionCategory'];
    positions: Array<MassAssignSlotsQuery['event']['positionsSlots']['nodes'][number]['position']>;
    slots: MassAssignSlotsQuery['event']['positionsSlots']['nodes'];
    positionsSlotsIds: PositionsSlotId[];

    change(name: string, value: any): void;
}

export const MassAssignCategory = (props: IMassAssignCategoryProps) => {
    const positions = React.useMemo(
        () =>
            sortBy(
                props.positions.filter((p) => p.positionCategoryId === props.category.id),
                (p) => p.name.toLowerCase()
            ),
        [props.category, props.positions]
    );

    return (
        <AccreditationCategory category={props.category}>
            {positions.map((position) => (
                <MassAssignPosition
                    key={position.id}
                    change={props.change}
                    position={position}
                    positionsSlotsIds={props.positionsSlotsIds}
                    slots={props.slots}
                />
            ))}
        </AccreditationCategory>
    );
};
