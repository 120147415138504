import { useHistory, useParams } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';
import { useOrganizationArchiveMutation } from '../generated/graphqlHooks';

export const ArchiveOrganization = () => {
    const { organizationId } = useParams();
    const history = useHistory();
    const { mutate } = useOrganizationArchiveMutation();

    React.useEffect(() => {
        mutate({ organizationId }).then(() => {
            history.replace(HeaventPaths.HOME);
        });
    }, []);

    return <div />;
};
