import { FullPageHeader } from 'common-front/src/components/mobileFullPage/fullPageHeader';
import { FullPageHeaderText } from 'common-front/src/components/mobileFullPage/fullPageHeaderText';
import { MobileFullPage } from 'common-front/src/components/mobileFullPage/mobileFullPage';
import { styled } from 'common/src/designSystem/components/stitches';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { ProfileFormMobile } from './profileFormMobile';

const _h1 = styled('h1', {
    color: '$gray900',
    fontSize: '$textMd',
    fontWeight: '$medium'
});

export const ProfileMobile = () => {
    const translate = useTranslate();

    return (
        <MobileFullPage>
            <FullPageHeader>
                <FullPageHeaderText>
                    <_h1>{translate('informations_pe_37892')}</_h1>
                </FullPageHeaderText>
            </FullPageHeader>
            <ProfileFormMobile />
        </MobileFullPage>
    );
};
