import { Cell } from 'common/src/designSystem/components/table/cell';
import { VolunteerRegistrationFragment } from 'common/src/generated/types';
import * as React from 'react';
import { BadgesPlus } from '../../../components/badgesPlus/badgesPlus';

interface IVolunteerRegistrationPreassignedPositionsCategoriesProps {
    volunteerRegistration: VolunteerRegistrationFragment;
}

export const VolunteerRegistrationPreassignedPositionsCategories = (
    props: IVolunteerRegistrationPreassignedPositionsCategoriesProps
) => {
    const badges = React.useMemo(
        () =>
            (props.volunteerRegistration?.preassignPositionsCategories || []).map((p) => ({
                id: p.id,
                color: 'gray',
                icon: null,
                text: p.name
            })),
        [props.volunteerRegistration]
    );

    return (
        <Cell>
            <BadgesPlus badges={badges} />
        </Cell>
    );
};
