import { CenteredContainer } from 'common-front/src/components/centeredContainer/centeredContainer';
import { Form } from 'common-front/src/components/form/form';
import { FormErrors } from 'common-front/src/components/form/formErrors';
import { FullScreenPopup } from 'common-front/src/components/fullScreenPopup/fullScreenPopup';
import { Button } from 'common-front/src/designSystem/components/button';
import { RadioText } from 'common-front/src/designSystem/form/radio';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import {
    CustomFieldVariety,
    VolunteersImportInput,
    VolunteersImportMode
} from 'common/src/generated/types';
import {
    MEMBERS_IMPORT_COLUMNS_DEFAULT,
    VolunteersImportInputService
} from 'common/src/input/volunteersImportInput';
import { ValidateService } from 'common/src/services/validateService';
import { useParams, useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import { FormApi } from 'final-form';
import * as React from 'react';
import { CSVFormBox } from '../../common/import/csvFormBox';
import { ImportProcessingAlert } from '../../common/import/importProcessingAlert';
import { MappingsFormBoxes } from '../../common/import/mappingsFormBoxes';
import {
    useOrganizationCustomFieldsFrontQuery,
    useVolunteersImportMutation
} from '../../generated/graphqlHooks';
import { useNotificationContext } from '../../notifications/notificationContext';

const isFileValid = (form: FormApi<{ input: VolunteersImportInputWithFile }>): boolean =>
    !!form.getFieldState('input.csv.key')?.valid && !!form.getFieldState('input.csv.file')?.valid;

const removeFileFromInput = (input: VolunteersImportInputWithFile) => {
    const csvCopy = { ...input.csv };

    delete csvCopy.file;

    return {
        ...input,
        csv: csvCopy
    };
};

type VolunteersImportInputWithFile = VolunteersImportInput & {
    csv: {
        file?: File;
    };
};

export const VolunteersImport = () => {
    const translate = useTranslate();
    const volunteersImportInput = useService(VolunteersImportInputService);
    const validateService = useService(ValidateService);
    const centeredContainerRef = React.useRef<HTMLDivElement | null>(null);
    const { eventId, organizationId } = useParams();
    const { data, loader } = useOrganizationCustomFieldsFrontQuery({
        organizationId,
        variety: CustomFieldVariety.UserInfo
    });
    const { mutate } = useVolunteersImportMutation();
    const { checkVolunteersImport } = useNotificationContext();

    if (loader) {
        return loader;
    } else {
        const customFields = data.organization.customFields.nodes;

        return (
            <Form
                height={1}
                hideErrors={true}
                initialValues={{
                    input: volunteersImportInput.default().input as VolunteersImportInputWithFile
                }}
                render={({ form, handleSubmit, submitting, values }) => (
                    <FullScreenPopup
                        button={
                            <Button isLoading={submitting} onClick={handleSubmit}>
                                {translate('importer_la_lis_12866')}
                            </Button>
                        }
                        category={translate('import_de_membr_48618')}
                        color="dark"
                        title={translate('nouvel_import_48306')}
                    >
                        <CenteredContainer ref={centeredContainerRef}>
                            {form.getState().submitSucceeded && <ImportProcessingAlert />}
                            <FormErrors />

                            <Flex direction="column" gap="5">
                                <CSVFormBox />

                                {values.input.csv?.file && isFileValid(form) && (
                                    <MappingsFormBoxes<VolunteersImportInputWithFile>
                                        customFields={customFields}
                                        defaultColumns={MEMBERS_IMPORT_COLUMNS_DEFAULT}
                                        defaultFieldsOverride={
                                            <>
                                                {values.input.mode ===
                                                    VolunteersImportMode.UpdateFromId && (
                                                    <optgroup label={translate('id_51738')}>
                                                        <option value="id">
                                                            {translate('id_51738')}
                                                        </option>
                                                    </optgroup>
                                                )}
                                                <optgroup label={translate('Email')}>
                                                    <option value="email">
                                                        {translate('Email')}
                                                    </option>
                                                </optgroup>
                                            </>
                                        }
                                        file={values.input.csv.file}
                                        form={form}
                                        modeSelection={
                                            <>
                                                {values.input.mode && (
                                                    <Flex direction="column" gap="2">
                                                        <Box color="gray800" fontWeight="medium">
                                                            {translate('que_souhaitez_v_33936')}
                                                        </Box>
                                                        <RadioText
                                                            name="input.mode"
                                                            value={VolunteersImportMode.Create}
                                                        >
                                                            {translate('ajouter_un_memb_41735')}
                                                        </RadioText>
                                                        <RadioText
                                                            name="input.mode"
                                                            value={
                                                                VolunteersImportMode.CreateUpdateFromEmail
                                                            }
                                                        >
                                                            {translate('ajouter_un_memb_91120')}
                                                        </RadioText>
                                                        <RadioText
                                                            name="input.mode"
                                                            value={
                                                                VolunteersImportMode.UpdateFromId
                                                            }
                                                        >
                                                            {translate('n_ajouter_aucun_41072')}
                                                        </RadioText>
                                                    </Flex>
                                                )}
                                            </>
                                        }
                                        requiredColumns={
                                            values.input.mode === VolunteersImportMode.UpdateFromId
                                                ? [MEMBERS_IMPORT_COLUMNS_DEFAULT.Id]
                                                : [MEMBERS_IMPORT_COLUMNS_DEFAULT.Email]
                                        }
                                        values={values}
                                    />
                                )}
                            </Flex>
                        </CenteredContainer>
                    </FullScreenPopup>
                )}
                validate={validateService.validateForForm(volunteersImportInput.schema())}
                onShowErrors={() => {
                    if (centeredContainerRef.current) {
                        centeredContainerRef.current.scrollTop = 0;
                    }
                }}
                onSubmit={async ({ input }) => {
                    const inputWithoutFile = removeFileFromInput(input);

                    try {
                        const { jobId } = await mutate({
                            eventId,
                            organizationId,
                            input: inputWithoutFile
                        });
                        if (centeredContainerRef.current) {
                            centeredContainerRef.current.scrollTop = 0;
                        }
                        checkVolunteersImport(organizationId, jobId);
                    } catch (e) {
                        /* Already displayed in <FormErrors />. */
                    }
                }}
            />
        );
    }
};
