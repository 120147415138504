import { UpdateLinkTextCell } from 'common-front/src/components/cells/updateLinkTextCell';
import { useSegmentsContext } from 'common-front/src/segments/segmentsContext';
import { LinkText } from 'common/src/designSystem/components/linkText';
import {
    AccreditationId,
    AccreditationProperty,
    AccreditationsCategory
} from 'common/src/generated/types';
import { useParams } from 'common/src/util/dependencies/dependencies';
import { AccreditationsPaths } from 'common/src/util/paths/accreditationsPaths';
import * as React from 'react';
import { AccreditationsCategoryCellInput } from './accreditationsCategoryCellInput';
import { AccreditationsUpdateCell } from './accreditationsUpdateCell';

interface IAccreditationsCategoryCellProps {
    accreditationId: AccreditationId;
    accreditationCategory: Pick<AccreditationsCategory, 'id' | 'name'>;

    reload(): void;
}

export const AccreditationsCategoryCell = (props: IAccreditationsCategoryCellProps) => {
    const { organizationId, eventId } = useParams();
    const { isEditMode } = useSegmentsContext();
    const name = props.accreditationCategory.name;

    return (
        <AccreditationsUpdateCell
            accreditationId={props.accreditationId}
            initialValue={props.accreditationCategory.id}
            property={AccreditationProperty.Category}
            reload={props.reload}
            renderInput={(value, setValue) => (
                <AccreditationsCategoryCellInput setValue={setValue} value={value} />
            )}
            renderValue={() => (
                <UpdateLinkTextCell isEditMode={isEditMode} title={name}>
                    <LinkText
                        to={AccreditationsPaths.ACCREDITATION_CATEGORY({
                            organizationId,
                            eventId,
                            accreditationCategoryId: props.accreditationCategory.id
                        })}
                    >
                        {name}
                    </LinkText>
                </UpdateLinkTextCell>
            )}
        />
    );
};
