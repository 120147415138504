import { Button } from 'common-front/src/designSystem/components/button';
import { copy } from 'common-front/src/util/commands';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { LinkText } from 'common/src/designSystem/components/linkText';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { CommonEnvVars } from 'common/src/envVars';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { getFormLink } from 'common/src/vo/form';
import * as React from 'react';
import { useFormContext } from '../formContext';

export const FormIntegration = () => {
    const translate = useTranslate();
    const { organizationId, eventId, formId } = useFormContext();
    const [isLoading, setIsLoading] = React.useState(false);
    const link = getFormLink({ organizationId, eventId, formId });
    const iframe = `<iframe
        height="100%"
        width="100%"
        src="${link}"
        style="border: none;"></iframe>`.replace(/\s+/g, ' ');

    return (
        <Flex justify="center" width={1}>
            <Flex direction="column" width="$container">
                <Spacer height="8" />

                <Box color="gray700" fontWeight="medium">
                    {translate('lien_du_formula_51477')}
                </Box>

                <Spacer height="1" />

                <LinkText css={{ color: '$primary700' }} to={link}>
                    {link}
                </LinkText>

                <Spacer height="6" />

                <Box color="gray700" fontWeight="medium">
                    {translate('code_html_pour_00029')}
                </Box>

                <Spacer height="1" />

                <Box
                    css={{
                        '& pre': {
                            border: '1px solid $gray300',
                            borderRadius: '$2',
                            padding: '$3'
                        }
                    }}
                >
                    <pre>{iframe}</pre>
                </Box>

                <Spacer height="2" />

                <Flex>
                    <Button
                        isLoading={isLoading}
                        leftIcon="copy"
                        onClick={() => {
                            setIsLoading(true);
                            copy(iframe);

                            setTimeout(() => {
                                setIsLoading(false);
                            }, 500);
                        }}
                    >
                        {translate('copier_le_code_99588')}
                    </Button>
                </Flex>

                <Spacer height="6" />

                <Box color="gray700" fontWeight="medium">
                    {translate('qr_code_du_lien_34278')}
                </Box>

                <Spacer height="1" />

                <Box height={100} width={100}>
                    <img
                        height="100%"
                        src={`https://api.${CommonEnvVars.HEAVENT_EXTERNAL_DOMAIN}/barcode/qr?text=${encodeURIComponent(link)}&color=000000&height=100&width=100`}
                        width="100%"
                    />
                </Box>
            </Flex>
        </Flex>
    );
};
