import { Empty } from 'common-front/src/components/empty/empty';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { PositionsPaths } from 'common/src/util/paths/positionsPaths';
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { usePositionCategoryQuery } from '../../generated/graphqlHooks';
import { PositionCategory } from './positionCategory';

export const PositionCategoryLoader = () => {
    const {
        params: { organizationId, eventId, positionCategoryId }
    } = useHeavent();
    const { data, loader, reload } = usePositionCategoryQuery({
        organizationId,
        eventId,
        positionCategoryId
    });

    return (
        loader || (
            <Switch>
                <Route
                    exact
                    path={PositionsPaths.POSITION_CATEGORY({
                        organizationId: ':organizationId',
                        eventId: ':eventId',
                        positionCategoryId: ':positionCategoryId'
                    })}
                >
                    <Empty
                        path={PositionsPaths.POSITION_CATEGORY_DASHBOARD({
                            organizationId,
                            eventId,
                            positionCategoryId
                        })}
                        replace={true}
                    />
                </Route>

                <Route>
                    <PositionCategory
                        event={data.event}
                        reload={reload}
                        roles={data.organization.roles}
                    />
                </Route>
            </Switch>
        )
    );
};
