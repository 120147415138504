import { BackButton } from 'common-front/src/components/buttons/backButton';
import { Page } from 'common-front/src/components/page/page';
import { Button } from 'common-front/src/designSystem/components/button';
import { Dropdown } from 'common-front/src/designSystem/components/dropdown/dropdown';
import { ItemIcon } from 'common-front/src/designSystem/components/dropdown/item';
import { Menu } from 'common-front/src/designSystem/components/dropdown/menu';
import { Trigger } from 'common-front/src/designSystem/components/dropdown/trigger';
import { Separator } from 'common-front/src/designSystem/components/separator';
import { Content } from 'common-front/src/designSystem/components/tooltip/content';
import { Tooltip } from 'common-front/src/designSystem/components/tooltip/tooltip';
import { Trigger as TooltipTrigger } from 'common-front/src/designSystem/components/tooltip/trigger';
import { useVolunteersRegistrationsAccreditationsValidateMutation } from 'common-front/src/generated/graphqlHooks';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { DelegationQuery } from 'common/src/generated/types';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import { CommonPaths } from 'common/src/util/paths/commonPaths';
import { DelegationsPaths } from 'common/src/util/paths/delegationsPaths';
import * as React from 'react';
import {
    useDelegationDuplicateMutation,
    useDelegationLockMutation,
    useDelegationUnlockMutation
} from '../../generated/graphqlHooks';
import { DelegationAddMembersModal } from '../create/delegationAddMembersModal';
import { DelegationDeleteModal } from '../list/delegationDeleteModal';

interface IDelegationHeaderProps {
    delegation: DelegationQuery['organization']['delegation'];

    reload(): void;
}

export const DelegationHeader = ({ delegation, reload }: IDelegationHeaderProps) => {
    const {
        history,
        translate,
        params: { organizationId, eventId }
    } = useHeavent();
    const { mutate } = useDelegationDuplicateMutation();
    const { mutate: accreditationsValidate } =
        useVolunteersRegistrationsAccreditationsValidateMutation();
    const { mutate: delegationLock } = useDelegationLockMutation();
    const { mutate: delegationUnlock } = useDelegationUnlockMutation();
    const [isDeleteOpen, setIsDeleteOpen] = React.useState(false);
    const [addMembersOpen, setAddMembersOpen] = React.useState(false);
    const isLocked = delegation.lockedAt?.isValid;

    return (
        <Page.Header align="center" gap="3" hideBorder={true}>
            <BackButton
                returnPathFallback={DelegationsPaths.DELEGATIONS({ organizationId, eventId })}
            />

            <Flex align="center" css={{ flex: '1' }} gap="2">
                <Box font="gray900 displayXs medium">{delegation.name}</Box>

                {isLocked && (
                    <Tooltip>
                        <TooltipTrigger>
                            <Box color="error600" fontSize="textMd">
                                <I icon="lock-keyhole" />
                            </Box>
                        </TooltipTrigger>

                        <Content placement="top">{translate('cette_d_l_gatio_29556')}</Content>
                    </Tooltip>
                )}
            </Flex>

            <Button
                color="white"
                to={CommonPaths.EDIT(
                    DelegationsPaths.DELEGATION({
                        organizationId,
                        eventId,
                        delegationId: delegation.id
                    })
                )}
            >
                {translate('_diter_la_d_l_g_30300')}
            </Button>

            <Button
                disabled={delegation.numberOfResources >= (delegation.maxResources ?? Infinity)}
                title={
                    delegation.numberOfResources >= (delegation.maxResources ?? Infinity)
                        ? translate('la_d_l_gation_e_73604')
                        : undefined
                }
                onClick={() => setAddMembersOpen(true)}
            >
                {translate('ajouter_le_memb_03343')}
            </Button>

            <Dropdown>
                <Trigger>
                    <Button color="white" leftIcon="ellipsis-vertical" />
                </Trigger>

                <Menu placement="bottom-end">
                    {eventId && (
                        <ItemIcon
                            icon="check"
                            onClick={async () => {
                                await accreditationsValidate({
                                    eventId,
                                    delegationId: delegation.id,
                                    formId: delegation.formId,
                                    volunteerRegistrationsIds: []
                                });

                                reload();
                            }}
                        >
                            {translate('valider_les_acc_80801')}
                        </ItemIcon>
                    )}

                    {isLocked ? (
                        <ItemIcon
                            icon="lock-keyhole-open"
                            onClick={async () => {
                                await delegationUnlock({
                                    organizationId,
                                    eventId,
                                    delegationId: delegation.id
                                });

                                reload();
                            }}
                        >
                            {translate('d_verrouiller_67042')}
                        </ItemIcon>
                    ) : (
                        <ItemIcon
                            icon="lock-keyhole"
                            onClick={async () => {
                                await delegationLock({
                                    organizationId,
                                    eventId,
                                    delegationId: delegation.id
                                });

                                reload();
                            }}
                        >
                            {translate('verrouiller_24528')}
                        </ItemIcon>
                    )}

                    <ItemIcon
                        icon="clone"
                        onClick={async () => {
                            const { delegationDuplicate } = await mutate({
                                organizationId,
                                eventId,
                                delegationId: delegation.id
                            });

                            history.push(
                                CommonPaths.EDIT(
                                    DelegationsPaths.DELEGATION({
                                        organizationId,
                                        eventId,
                                        delegationId: delegationDuplicate.id
                                    })
                                )
                            );
                        }}
                    >
                        {translate('dupliquer_28256')}
                    </ItemIcon>

                    <Separator color="gray100" direction="horizontal" />

                    <ItemIcon
                        color="red"
                        icon="trash-can"
                        onClick={() => {
                            setIsDeleteOpen(true);
                        }}
                    >
                        {translate('supprimer_43083')}
                    </ItemIcon>
                </Menu>
            </Dropdown>

            {isDeleteOpen && (
                <DelegationDeleteModal
                    delegation={delegation}
                    onClose={() => setIsDeleteOpen(false)}
                    onSuccess={() =>
                        history.replace(
                            eventId
                                ? DelegationsPaths.DELEGATIONS({ organizationId, eventId })
                                : HeaventPaths.COMMUNITY_DELEGATIONS(organizationId)
                        )
                    }
                />
            )}

            {addMembersOpen && (
                <DelegationAddMembersModal
                    delegationId={delegation.id}
                    eventId={eventId}
                    organizationId={organizationId}
                    reload={reload}
                    onClose={() => {
                        setAddMembersOpen(false);
                    }}
                />
            )}
        </Page.Header>
    );
};
