import { Empty } from 'common-front/src/components/empty/empty';
import { FullPageContent } from 'common-front/src/components/mobileFullPage/fullPageContent';
import { MobileFullPage } from 'common-front/src/components/mobileFullPage/mobileFullPage';
import { TabListMobile } from 'common-front/src/designSystem/components/tabs/mobile/tabListMobile';
import { TabMobile } from 'common-front/src/designSystem/components/tabs/mobile/tabMobile';
import { TabPanel } from 'common-front/src/designSystem/components/tabs/tabPanel';
import { Tabs } from 'common-front/src/designSystem/components/tabs/tabs';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { PositionsSlotId } from 'common/src/generated/types';
import { useLocation } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import { PositionsPaths } from 'common/src/util/paths/positionsPaths';
import * as React from 'react';
import { usePositionDashboardQuery } from '../../../generated/graphqlHooks';
import { PositionHeaderMobile } from './positionHeaderMobile';
import { PositionSettingsMobile } from './positionSettings/positionSettingsMobile';
import { PositionSlotsListMobile } from './positionSlots/positionSlotsListMobile';

export const PositionMobile = () => {
    const {
        history,
        params: { organizationId, eventId, positionId },
        translate
    } = useHeavent();
    const { data, loader } = usePositionDashboardQuery({ organizationId, eventId, positionId });
    const location = useLocation();

    if (location.pathname === PositionsPaths.POSITION({ organizationId, eventId, positionId })) {
        return (
            <Empty
                path={HeaventPaths.POSITION_SETTINGS(organizationId, eventId, positionId)}
                replace={true}
            />
        );
    }

    const paths = {
        settings: HeaventPaths.POSITION_SETTINGS(organizationId, eventId, positionId),
        slots: PositionsPaths.POSITION_SLOTS({ organizationId, eventId, positionId })
    };

    const openSlot = (id: PositionsSlotId) => {
        history.push(HeaventPaths.POSITION_SLOT_MEMBERS(organizationId, eventId, positionId, id));
    };

    if (loader) {
        return loader;
    } else {
        return (
            <MobileFullPage>
                <PositionHeaderMobile position={data.event.position} />
                <FullPageContent>
                    <Tabs>
                        <TabListMobile css={{ margin: '$6 0' }}>
                            <TabMobile path={paths.settings}>
                                {translate('param_tres_78420')}
                            </TabMobile>
                            <TabMobile path={paths.slots}>{translate('cr_neaux_33401')}</TabMobile>
                        </TabListMobile>
                        <TabPanel path={paths.settings}>
                            <PositionSettingsMobile
                                customFields={data.organization.customFields.nodes}
                                position={data.event.position}
                            />
                        </TabPanel>
                        <TabPanel path={paths.slots}>
                            <PositionSlotsListMobile
                                eventId={eventId}
                                open={openSlot}
                                positionId={data.event.position.id}
                            />
                        </TabPanel>
                    </Tabs>
                </FullPageContent>
            </MobileFullPage>
        );
    }
};
