import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { useHistory } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';

interface IBackButtonProps {
    color?: 'primary';
    children?: React.ReactNode;
    fontWeight?: 'medium';
    returnPathFallback: string;
}

export const BackButton = ({
    children,
    color,
    fontWeight,
    returnPathFallback: returnFallbackPath
}: IBackButtonProps) => {
    const history = useHistory();

    return (
        <Flex
            align="center"
            css={{
                color: color === 'primary' ? '$primary700' : '$gray900',
                cursor: 'pointer',
                fontSize: 'textXl',
                fontWeight: fontWeight ?? 'normal',
                padding: 0
            }}
            gap="2"
            onClick={() => history.goBack(returnFallbackPath)}
        >
            <I icon="arrow-left" />

            {children}
        </Flex>
    );
};
