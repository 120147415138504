import { Button } from 'common-front/src/designSystem/components/button';
import { useVolunteerRegistrationAssignMutation } from 'common-front/src/generated/graphqlHooks';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { VolunteersRegistrationId } from 'common/src/generated/types';
import { isNonEmptyArray } from 'common/src/util/array';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';
import {
    useVolunteerRegistrationAssignmentRefuseMutation,
    useVolunteerRegistrationPreAssignMutation
} from '../../generated/graphqlHooks';
import { useAssignmentContext } from './assignmentContext';

interface IAssignmentButtonsProps {
    isEdit: boolean;
    volunteerRegistrationId: VolunteersRegistrationId;
}

export const AssignmentButtons = (props: IAssignmentButtonsProps) => {
    const {
        history,
        params: { organizationId, eventId },
        translate
    } = useHeavent();
    const {
        isPreAssign,
        positionsIds,
        positionsCategoriesIds,
        positionsSlotsIds,
        showNextVolunteer
    } = useAssignmentContext();
    const { mutate: assign, isLoading: assignIsLoading } = useVolunteerRegistrationAssignMutation();
    const { mutate: preAssign, isLoading: preAssignIsLoading } =
        useVolunteerRegistrationPreAssignMutation();
    const { mutate: refuse, isLoading: refuseIsLoading } =
        useVolunteerRegistrationAssignmentRefuseMutation();
    const onSuccess = React.useCallback(() => {
        if (props.isEdit) {
            history.goBack(HeaventPaths.ASSIGNMENTS(organizationId, eventId));
        } else {
            showNextVolunteer();
        }
    }, [props.isEdit, showNextVolunteer]);

    return (
        <Flex
            align="center"
            css={{
                borderTop: '1px solid $gray200',
                px: '$7'
            }}
            gap="4"
            height={1}
            width={1}
        >
            <Box
                color="error700"
                css={{ cursor: 'pointer' }}
                onClick={async () => {
                    if (!assignIsLoading && !preAssignIsLoading && !refuseIsLoading) {
                        await refuse({
                            eventId,
                            volunteerRegistrationId: props.volunteerRegistrationId
                        });

                        onSuccess();
                    }
                }}
            >
                {translate('refuser_92819')}
            </Box>

            <Box css={{ flex: '1' }} />

            <Button
                color="primary"
                isLoading={assignIsLoading || preAssignIsLoading}
                onClick={async () => {
                    if (
                        isPreAssign &&
                        (isNonEmptyArray(positionsIds) ||
                            isNonEmptyArray(positionsCategoriesIds)) &&
                        !assignIsLoading &&
                        !preAssignIsLoading &&
                        !refuseIsLoading
                    ) {
                        await preAssign({
                            eventId,
                            volunteerRegistrationId: props.volunteerRegistrationId,
                            preAssignment: {
                                positionsIds,
                                positionsCategoriesIds
                            }
                        });

                        onSuccess();
                    } else if (
                        isNonEmptyArray(positionsSlotsIds) &&
                        !assignIsLoading &&
                        !preAssignIsLoading &&
                        !refuseIsLoading
                    ) {
                        await assign({
                            eventId,
                            volunteerRegistrationId: props.volunteerRegistrationId,
                            assignment: { positionsSlotsIds }
                        });

                        onSuccess();
                    }
                }}
            >
                {props.isEdit
                    ? translate('mettre_jour_l_30423')
                    : isPreAssign
                      ? translate('pr_affecter_17434')
                      : translate('affecter_94255')}
            </Button>

            <Button color="white" onClick={onSuccess}>
                {props.isEdit ? translate('annuler_48254') : translate('affecter_plus_t_97778')}
            </Button>
        </Flex>
    );
};
