import { sortBy, uniqBy } from 'lodash-es';
import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { CommonEnvVars } from '../../../envVars';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { isNonEmptyArray } from '../../../util/array';
import { A4_SIZES } from '../../../util/pdf';
import { getFieldValue } from '../../../vo/userInfo';

const getPage1Background = (population: string) =>
    population === 'Staff'
        ? 'https://assets.recrewteer.com/badges/cercle/fiche_missions_Staff.png'
        : 'https://assets.recrewteer.com/badges/cercle/fiche_missions_Visiteur.png';

interface ICercleStaffProps {
    event: DocumentUserCustomBadgeFragment;
}

export const CercleStaff = (props: ICercleStaffProps) => {
    const vr = props.event.volunteerRegistration;
    const ui = vr.userInfo;
    const population = getFieldValue<string>(ui.fields, 'population', '');
    const delegation = vr.delegation;
    const days = sortBy(
        uniqBy(
            ui.formsUsersInfos.flatMap((fui) =>
                fui.slots.map((s) => s.range.start!.startOf('day'))
            ),
            (d) => d.toMillis()
        ),
        (d) => d.toMillis()
    );
    const cateringDays = sortBy(
        uniqBy(
            vr.accreditationsUsersInfos.flatMap((aui) =>
                aui.accreditationCategory.id === 2826 && aui.accreditationSlot.date?.isValid
                    ? [aui.accreditationSlot.date]
                    : []
            ),
            (d) => d.toMillis()
        ),
        (d) => d.toMillis()
    );

    return (
        <>
            <Flex
                css={{
                    background: `url(${getPage1Background(population)}) no-repeat`,
                    backgroundSize: 'cover',
                    position: 'relative'
                }}
                height={A4_SIZES['96dpi'].height}
                width={A4_SIZES['96dpi'].width}
            >
                <Box
                    css={{
                        fontSize: '20px',
                        fontWeight: '700',
                        left: '32px',
                        position: 'absolute',
                        textTransform: 'uppercase',
                        top: '206px'
                    }}
                >
                    {ui.name}
                </Box>

                <Box
                    css={{
                        color: '#1e3159',
                        fontSize: '17px',
                        fontWeight: '600',
                        left: '32px',
                        position: 'absolute',
                        top: '238px'
                    }}
                >
                    Nom du responsable : {delegation?.leaders?.[0]?.name}{' '}
                    {delegation?.leaders?.[0]?.phone?.internationalFormat}
                </Box>

                <Box
                    css={{
                        position: 'absolute',
                        right: '32px',
                        top: '188px'
                    }}
                    height={90}
                    width={90}
                >
                    <img
                        height="100%"
                        src={`https://api.${
                            CommonEnvVars.HEAVENT_EXTERNAL_DOMAIN
                        }/barcode/qr?text=${encodeURIComponent(
                            vr.ticketId
                        )}&color=000000&height=90&width=90`}
                        width="100%"
                    />
                </Box>

                <Flex
                    css={{
                        gap: '6px',
                        left: '32px',
                        position: 'absolute',
                        top: population === 'Staff' ? '461px' : '452px'
                    }}
                >
                    {days.map((d, index) => (
                        <Flex
                            key={index}
                            align="center"
                            css={{
                                border: '1px solid black',
                                borderRadius: '$1',
                                fontFamily: '$montserrat',
                                padding: '4px 6px'
                            }}
                            direction="column"
                            justify="center"
                        >
                            <Box css={{ fontSize: '16px', fontWeight: '700' }}>
                                {d.toFormat('dd')}
                            </Box>
                            <Box css={{ fontSize: '10px', fontWeight: '500' }}>
                                {d.toFormat('MMM', { locale: 'fr' })}
                            </Box>
                        </Flex>
                    ))}
                </Flex>

                {isNonEmptyArray(cateringDays) && (
                    <Box
                        css={{
                            fontSize: '22px',
                            fontWeight: '800',
                            left: '32px',
                            position: 'absolute',
                            textTransform: 'uppercase',
                            top: population === 'Staff' ? '530px' : '521px'
                        }}
                    >
                        Catering
                    </Box>
                )}

                <Flex
                    css={{
                        gap: '6px',
                        left: '32px',
                        position: 'absolute',
                        top: population === 'Staff' ? '562px' : '553px'
                    }}
                >
                    {cateringDays.map((d, index) => (
                        <Flex
                            key={index}
                            align="center"
                            css={{
                                border: '1px solid black',
                                borderRadius: '$1',
                                fontFamily: '$montserrat',
                                padding: '4px 6px'
                            }}
                            direction="column"
                            justify="center"
                        >
                            <Box css={{ fontSize: '16px', fontWeight: '700' }}>
                                {d.toFormat('dd')}
                            </Box>
                            <Box css={{ fontSize: '10px', fontWeight: '500' }}>
                                {d.toFormat('MMM', { locale: 'fr' })}
                            </Box>
                        </Flex>
                    ))}
                </Flex>
            </Flex>

            {population === 'Staff' && (
                <>
                    <Flex
                        css={{
                            background:
                                'url(https://assets.recrewteer.com/badges/cercle/fiche_missions_Staff-01.png) no-repeat',
                            backgroundSize: 'cover',
                            pageBreakBefore: 'always'
                        }}
                        height={A4_SIZES['96dpi'].height}
                        width={A4_SIZES['96dpi'].width}
                    />

                    <Flex
                        css={{
                            background:
                                'url(https://assets.recrewteer.com/badges/cercle/fiche_missions_Staff-02.png) no-repeat',
                            backgroundSize: 'cover',
                            pageBreakBefore: 'always'
                        }}
                        height={A4_SIZES['96dpi'].height}
                        width={A4_SIZES['96dpi'].width}
                    />
                </>
            )}

            {population === 'Visiteur' && (
                <Flex
                    css={{
                        background:
                            'url(https://assets.recrewteer.com/badges/cercle/fiche_missions_Visiteur_2.png) no-repeat',
                        backgroundSize: 'cover',
                        pageBreakBefore: 'always'
                    }}
                    height={A4_SIZES['96dpi'].height}
                    width={A4_SIZES['96dpi'].width}
                />
            )}
        </>
    );
};
