import { Flex } from 'common/src/designSystem/components/flex';
import { Skeleton } from 'common/src/designSystem/components/skeleton';
import { Cell } from 'common/src/designSystem/components/table/cell';
import {
    DelegationId,
    EventId,
    UpdateCellDelegationsQuery,
    VolunteerRegistrationFragment
} from 'common/src/generated/types';
import { isNonEmptyArray } from 'common/src/util/array';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { BadgesPlus } from '../../../components/badgesPlus/badgesPlus';
import { UpdateCellDropdown } from '../../../components/cells/updateCellDropdown';
import { RichSelect } from '../../../designSystem/components/richSelect/richSelect';
import {
    executeUpdateCellDelegationsQuery,
    useUserInfoDelegationUpdateMutation
} from '../../../generated/graphqlHooks';
import { useRichTableContext } from '../../../richTable/richTableContext';
import { useSegmentsContext } from '../../../segments/segmentsContext';
import { getToken } from '../../../util/aws/cognito';

interface IVolunteerRegistrationDelegationProps {
    eventId: EventId;
    volunteerRegistration: VolunteerRegistrationFragment;

    reload(): void;
}

export const VolunteerRegistrationDelegation = (props: IVolunteerRegistrationDelegationProps) => {
    const translate = useTranslate();
    const { mutate: userInfoDelegationUpdate } = useUserInfoDelegationUpdateMutation();
    // TODO: temporary solution
    // TODO: use only useRichTableContext() once refacto is done
    const { isEditMode: segmentsIsEditMode } = useSegmentsContext();
    const { isEditMode: richTableIsEditMode } = useRichTableContext();
    const isEditMode = segmentsIsEditMode || richTableIsEditMode;
    const delegation = React.useMemo(
        () => props.volunteerRegistration.delegation,
        [props.volunteerRegistration.delegation]
    );
    const badges = React.useMemo(
        () =>
            delegation
                ? [
                      {
                          id: delegation.id,
                          color: 'primary',
                          icon: null,
                          text: delegation.name
                      }
                  ]
                : [],
        [delegation]
    );
    const initialValue = React.useMemo(() => (delegation ? [delegation.id] : []), [delegation]);
    const [isFirstTime, setIsFirstTime] = React.useState(true);
    const [allDelegations, setAllDelegations] = React.useState<
        UpdateCellDelegationsQuery['event']['delegations']['nodes']
    >([]);
    const onStateChange = React.useCallback(
        async (isOpen) => {
            if (isOpen && isFirstTime) {
                const {
                    event: { delegations }
                } = await executeUpdateCellDelegationsQuery(
                    { eventId: props.eventId },
                    await getToken()
                );

                setAllDelegations(delegations.nodes);
                setIsFirstTime(false);
            }
        },
        [isFirstTime]
    );
    const onSave = React.useCallback(
        async (value: DelegationId[]) => {
            await userInfoDelegationUpdate({
                eventId: props.eventId,
                userInfoId: props.volunteerRegistration.userInfo.id,
                delegationId: isNonEmptyArray(value) ? value[0] : null
            });

            props.reload();
        },
        [props.eventId, props.volunteerRegistration]
    );

    if (isEditMode) {
        return (
            <UpdateCellDropdown
                css={{ gap: '$2' }}
                initialValue={initialValue}
                renderInput={(value, setValue) =>
                    isFirstTime ? (
                        <Flex direction="column" gap="1" width={1}>
                            <Skeleton borderRadius="$1" height={20} width={1} />
                            <Skeleton borderRadius="$1" height={40} width={1} />
                        </Flex>
                    ) : (
                        <RichSelect
                            isSearchVisible={true}
                            label={translate('Delegation')}
                            renderOnPortal={true}
                            values={value}
                            onChange={setValue}
                        >
                            {allDelegations.map((delegation) => (
                                <option key={delegation.id} value={delegation.id}>
                                    {delegation.name}
                                </option>
                            ))}
                        </RichSelect>
                    )
                }
                renderValue={() => <BadgesPlus badges={badges} />}
                onSave={onSave}
                onStateChange={onStateChange}
            />
        );
    } else {
        return (
            <Cell css={{ border: '2px solid transparent' }}>
                <BadgesPlus badges={badges} />
            </Cell>
        );
    }
};
