import { IUseHistoryReturn } from 'common/src/hooks/types';
import { parseParamsIds } from 'common/src/util/params';
import * as H from 'history';
import { useHistory, useLocation, useParams } from 'react-router';

export function useParamsImpl<T>() {
    const params = useParams();

    return parseParamsIds<T>(params);
}

export function useHistoryImpl(): IUseHistoryReturn {
    const history = useHistory();
    return {
        ...history,
        goBack: (fallbackPath: string) => {
            // `POP` action handles both direct link clicks and 'New Tab' Page -> paste link flow.
            // @see https://github.com/remix-run/history/blob/dev/docs/api-reference.md#action:~:text=This%20is%20the%20default%20action%20for%20newly%20created%20history%20objects.
            //
            // However, `POP` also matches when the last history action was `goBack()`, so multiple
            // nested 'back' operations aren't possible. For e.g. if you go to 'Positions Lists' -> '
            // 'Position Category View' -> 'Position View' -> 'Position Category View' and then click
            // the back button within the app, you'll go back to the 'Position View' with `history.goBack()`,
            // but the second time `history.action` will be `POP` and `fallbackPath` will be used instead,
            // taking you back to 'Positions List' rather than the 'Position Category View'.
            //
            // The `length` test should limit the use of `fallbackPath` to only new tabs (where `history` will
            // contain the current page and the 'New Tab' page, at least in Firefox) and direct link clicks.
            // It's possible that there could be some unexpected interaction where this causes a new issue, but
            // I can't think of anything now (it would have to be something quite obscure and rare, I think)
            // and I think any impact would be minor.
            //
            // The most robust solution, though, is to redesign the app to do away with the various pseudo
            // 'back' buttons, and instead use positive navigation (i.e. `history.push()`) to clearly-defined
            // list pages and rely on the user's browser Back functionality to handle everything else.
            // @see https://weezevent.slack.com/archives/C06E84L542Z/p1736875365688659?thread_ts=1736872503.805599&cid=C06E84L542Z
            if (history.action === 'POP' && history.length <= 2) {
                history.push(fallbackPath);
            } else {
                history.goBack();
            }
        }
    };
}

export function useLocationImpl(): H.Location<any> {
    return useLocation();
}
