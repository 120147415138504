import { DetailBox } from 'common-front/src/designSystem/components/detailBox';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { fromIconVO, I } from 'common/src/designSystem/components/i';
import { LinkText } from 'common/src/designSystem/components/linkText';
import {
    Accreditation,
    AccreditationsCategoryId,
    CustomFieldWithConditionFragment
} from 'common/src/generated/types';
import { useService } from 'common/src/util/dependencies/dependencies';
import { AccreditationsPaths } from 'common/src/util/paths/accreditationsPaths';
import { Fields, FieldService } from 'common/src/vo/field';
import * as React from 'react';
import {
    Label,
    Parameter,
    ParameterSeparator,
    ParametersList,
    Value
} from '../../../common/cards/parametersList';

interface IAccreditationsSlotParametersCardProps {
    accreditationCategoryId: AccreditationsCategoryId;
    accreditationCategoryName: string;
    accreditationName?: string;
    color: Accreditation['color'];
    customFields: CustomFieldWithConditionFragment[];
    fields: Fields;
    icon: Accreditation['icon'];
}

export const AccreditationOrAccreditationsSlotParametersCard = ({
    accreditationCategoryId,
    accreditationCategoryName,
    accreditationName,
    color,
    customFields,
    fields,
    icon
}: IAccreditationsSlotParametersCardProps) => {
    const {
        params: { organizationId, eventId, accreditationId },
        translate
    } = useHeavent();
    const fieldService = useService(FieldService);

    return (
        <DetailBox
            css={{
                flex: '2 1'
            }}
            title={translate('param_tres_78420')}
        >
            <Flex direction="column" gap="4" width={1}>
                <ParametersList>
                    <Parameter>
                        <Label>{translate('cat_gorie_00291')}</Label>
                        <Value>
                            <LinkText
                                to={AccreditationsPaths.ACCREDITATION_CATEGORY({
                                    organizationId,
                                    eventId,
                                    accreditationCategoryId
                                })}
                            >
                                {accreditationCategoryName}
                            </LinkText>
                        </Value>
                    </Parameter>
                    {accreditationName && ( // i.e. if it's an accreditation slot
                        <>
                            <ParameterSeparator direction="horizontal" />
                            <Parameter>
                                <Label>{translate('Accreditation')}</Label>
                                <Value>
                                    <LinkText
                                        to={AccreditationsPaths.ACCREDITATION({
                                            organizationId,
                                            eventId,
                                            accreditationId
                                        })}
                                    >
                                        {accreditationName}
                                    </LinkText>
                                </Value>
                            </Parameter>
                        </>
                    )}
                    <ParameterSeparator direction="horizontal" />
                    <Parameter>
                        <Label>{translate('Color')}</Label>
                        <Value>
                            <Box
                                css={{
                                    background: color,
                                    borderRadius: '20px',
                                    height: '20px',
                                    width: '20px'
                                }}
                            />
                        </Value>
                    </Parameter>
                    <ParameterSeparator direction="horizontal" />
                    <Parameter>
                        <Label>{translate('ic_ne_55554')}</Label>
                        <Value>
                            <I icon={fromIconVO(icon)} />
                        </Value>
                    </Parameter>
                    {customFields.map((cf) => (
                        <React.Fragment key={cf.slug}>
                            <ParameterSeparator direction="horizontal" />

                            <Parameter>
                                <Label>{cf.name}</Label>
                                <Value>{fieldService.getValueString(cf, fields) || '-'}</Value>
                            </Parameter>
                        </React.Fragment>
                    ))}
                </ParametersList>
            </Flex>
        </DetailBox>
    );
};
