import { Flex } from 'common/src/designSystem/components/flex';
import { Skeleton } from 'common/src/designSystem/components/skeleton';
import { Cell } from 'common/src/designSystem/components/table/cell';
import {
    EventId,
    PositionsSlotId,
    UsersInfoId,
    VolunteerRegistrationFragment,
    VolunteerRegistrationState
} from 'common/src/generated/types';
import { IntervalService } from 'common/src/services/intervalService';
import { isNonEmptyArray } from 'common/src/util/array';
import { useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import { getPositionsBadges } from 'common/src/vo/position';
import { fullNameWithPositionName } from 'common/src/vo/positionSlot';
import { groupBy, sortBy } from 'lodash-es';
import * as React from 'react';
import { BadgesPlus } from '../../../components/badgesPlus/badgesPlus';
import { UpdateCellDropdown } from '../../../components/cells/updateCellDropdown';
import { RichSelect } from '../../../designSystem/components/richSelect/richSelect';
import {
    executeUpdateCellPositionsSlotsQuery,
    useVolunteerRegistrationAssignMutation,
    useVolunteersRegistrationsMassStateUpdateMutation
} from '../../../generated/graphqlHooks';
import { useRichTableContext } from '../../../richTable/richTableContext';
import { useSegmentsContext } from '../../../segments/segmentsContext';
import { getToken } from '../../../util/aws/cognito';

interface IVolunteerRegistrationPositionsProps {
    eventId: EventId;
    firstCellCss?: any;
    hidePosition?: boolean;
    volunteerRegistration: Pick<
        VolunteerRegistrationFragment,
        'id' | 'positionsSlotsUsersInfos'
    > & { userInfo: { id: UsersInfoId } };

    reload(): void;
}

export const VolunteerRegistrationPositions = (props: IVolunteerRegistrationPositionsProps) => {
    const translate = useTranslate();
    const { mutate: volunteerRegistrationAssign } = useVolunteerRegistrationAssignMutation();
    const { mutate: stateUpdate } = useVolunteersRegistrationsMassStateUpdateMutation();
    const intervalService = useService(IntervalService);
    // TODO: temporary solution
    // TODO: use only useRichTableContext() once refacto is done
    const { isEditMode: segmentsIsEditMode } = useSegmentsContext();
    const { isEditMode: richTableIsEditMode } = useRichTableContext();
    const isEditMode = segmentsIsEditMode || richTableIsEditMode;
    const psuis = React.useMemo(
        () => props.volunteerRegistration?.positionsSlotsUsersInfos || [],
        [props.volunteerRegistration]
    );
    const badges = React.useMemo(
        () => getPositionsBadges(intervalService, psuis, props.hidePosition),
        [psuis]
    );
    const initialValue = React.useMemo(() => psuis.map((psui) => psui.positionSlot.id), [psuis]);
    const [isFirstTime, setIsFirstTime] = React.useState(true);
    const [positions, setPositions] = React.useState<any[]>([]);
    const onStateChange = React.useCallback(
        async (isOpen) => {
            if (isOpen && isFirstTime) {
                const {
                    event: { positionsSlots }
                } = await executeUpdateCellPositionsSlotsQuery(
                    { eventId: props.eventId },
                    await getToken()
                );
                const slots = sortBy(positionsSlots.nodes, (s) => [
                    s.range.start!.toMillis(),
                    s.name
                ]).map((s) => ({
                    id: s.id,
                    positionName: s.position.name,
                    name: fullNameWithPositionName(intervalService, s, s.position.name)
                }));

                setPositions(
                    sortBy(
                        Object.entries(groupBy(slots, (s) => s.positionName)),
                        ([positionName]) => positionName
                    )
                );
                setIsFirstTime(false);
            }
        },
        [isFirstTime]
    );
    const onSave = React.useCallback(
        async (value: PositionsSlotId[]) => {
            if (isNonEmptyArray(value)) {
                await volunteerRegistrationAssign({
                    eventId: props.eventId,
                    volunteerRegistrationId: props.volunteerRegistration.id,
                    assignment: { positionsSlotsIds: value }
                });
            } else {
                await stateUpdate({
                    eventId: props.eventId,
                    massStateUpdate: {
                        selecteds: { ids: [props.volunteerRegistration.userInfo.id] },
                        state: VolunteerRegistrationState.WaitingAssignment
                    }
                });
            }

            props.reload();
        },
        [props.eventId, props.volunteerRegistration, volunteerRegistrationAssign, props.reload]
    );

    if (isEditMode) {
        return (
            <UpdateCellDropdown
                css={{ gap: '$2' }}
                initialValue={initialValue}
                renderInput={(value, setValue) =>
                    isFirstTime ? (
                        <Flex direction="column" gap="1" width={1}>
                            <Skeleton borderRadius="$1" height={20} width={1} />
                            <Skeleton borderRadius="$1" height={40} width={1} />
                        </Flex>
                    ) : (
                        <RichSelect
                            isSearchVisible={true}
                            label={translate('missions_63972')}
                            multiple={true}
                            renderOnPortal={true}
                            values={value}
                            onChange={setValue}
                        >
                            {positions.map(([positionName, slots], index) => (
                                <optgroup key={index} label={positionName}>
                                    {slots.map((slot: any) => (
                                        <option key={slot.id} value={slot.id}>
                                            {slot.name}
                                        </option>
                                    ))}
                                </optgroup>
                            ))}
                        </RichSelect>
                    )
                }
                renderValue={() => <BadgesPlus badges={badges} doNotSort={true} />}
                onSave={onSave}
                onStateChange={onStateChange}
            />
        );
    } else {
        return (
            <Cell css={{ ...props.firstCellCss, border: '2px solid transparent' }}>
                <BadgesPlus badges={badges} doNotSort={true} />
            </Cell>
        );
    }
};
