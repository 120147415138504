import { Button } from 'common-front/src/designSystem/components/button';
import {
    closeOtherDropdowns,
    Dropdown
} from 'common-front/src/designSystem/components/dropdown/dropdown';
import { ItemIcon } from 'common-front/src/designSystem/components/dropdown/item';
import { Menu } from 'common-front/src/designSystem/components/dropdown/menu';
import { Trigger } from 'common-front/src/designSystem/components/dropdown/trigger';
import { Modal } from 'common-front/src/designSystem/components/modal/modal';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { isNonEmptyArray } from 'common/src/util/array';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';
import { useParentDelegationsQuery } from '../../generated/graphqlHooks';
import { CreateDelegationModal } from './createDelegationModal';

interface IDelegationsHeaderProps {
    name: string;
}

export const DelegationsHeader = (props: IDelegationsHeaderProps) => {
    const {
        params: { organizationId, eventId },
        translate
    } = useHeavent();
    const { data } = useParentDelegationsQuery({ organizationId });
    const hasParentDelegations = isNonEmptyArray(data.organization?.delegations.nodes);

    return (
        <Flex gap="4" width={1}>
            <Box css={{ flex: '1' }} font="gray900 displaySm semiBold">
                {props.name}
            </Box>

            {hasParentDelegations ? (
                <Modal.Root>
                    <Modal.Trigger>
                        <Button>{translate('cr_er_une_d_l_g_23035')}</Button>
                    </Modal.Trigger>

                    <CreateDelegationModal
                        parentDelegations={data.organization.delegations.nodes}
                    />
                </Modal.Root>
            ) : (
                <Button to={HeaventPaths.CREATE_DELEGATION(organizationId, eventId)}>
                    {translate('cr_er_une_d_l_g_23035')}
                </Button>
            )}

            <Dropdown>
                <Trigger>
                    <Button color="white" leftIcon="ellipsis-vertical" />
                </Trigger>

                <Menu placement="bottom-end">
                    <ItemIcon
                        icon="file-import"
                        to={HeaventPaths.EVENT_DELEGATIONS_IMPORT(organizationId, eventId)}
                        onClick={(e) => closeOtherDropdowns(e.target)}
                    >
                        {translate('importer_des_d_05454')}
                    </ItemIcon>
                </Menu>
            </Dropdown>
        </Flex>
    );
};
