import { IconBackground } from 'common-front/src/designSystem/components/iconBackground';
import { TableFilters } from 'common-front/src/designSystem/components/tableFilters';
import { TextInput } from 'common-front/src/designSystem/components/textInput';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { usePaginationInfos } from 'common-front/src/hooks/usePaginationInfos';
import { useStateDebounce } from 'common-front/src/hooks/useStateDebounce';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { HeaderCell } from 'common/src/designSystem/components/table/headerCell';
import { HeaderCellSort } from 'common/src/designSystem/components/table/headerCellSort';
import { Row } from 'common/src/designSystem/components/table/row';
import { RowSkeleton } from 'common/src/designSystem/components/table/rowSkeleton';
import { AccreditationsSortAttributes, SortDirection } from 'common/src/generated/types';
import { AccreditationsPaths } from 'common/src/util/paths/accreditationsPaths';
import { isNonEmptyString } from 'common/src/util/string';
import { fullName } from 'common/src/vo/accreditation';
import { Sort } from 'common/src/vo/sort';
import * as React from 'react';
import { useAccreditationsCategoryAccreditationsQuery } from '../../../../generated/graphqlHooks';

export const AccreditationsCategoryAccreditationsCard = () => {
    const {
        history,
        params: { organizationId, eventId, accreditationsCategoryId },
        translate
    } = useHeavent();
    const [name, nameDebounced, setName] = useStateDebounce('');
    const [offset, setOffset] = React.useState(0);
    const [sort, setSort] = React.useState<Sort<AccreditationsSortAttributes> | null>({
        direction: SortDirection.Asc,
        attribute: AccreditationsSortAttributes.Name
    });
    const { data, isLoading } = useAccreditationsCategoryAccreditationsQuery({
        eventId,
        accreditationsCategoryId,
        name: isNonEmptyString(nameDebounced) ? nameDebounced : undefined,
        offset,
        sort
    });
    const { numberOfPages, totalCount } = usePaginationInfos(data.event?.accreditations);

    return (
        <TableFilters
            filters={
                <Box width={320}>
                    <TextInput
                        icon="magnifying-glass"
                        placeholder={translate('rechercher_une_65646')}
                        value={name}
                        onChange={setName}
                    />
                </Box>
            }
            headerCells={
                <>
                    <HeaderCellSort
                        attribute={AccreditationsSortAttributes.Name}
                        setSort={setSort}
                        sort={sort}
                    >
                        {translate('nom_de_l_accr_d_11908')}
                    </HeaderCellSort>
                    <HeaderCell>{translate('number_of_slots_92461')}</HeaderCell>
                    <HeaderCell>{translate('type_d_affichag_55093')}</HeaderCell>
                </>
            }
            numberOfPages={numberOfPages}
            offset={offset}
            rows={
                isLoading ? (
                    <>
                        <RowSkeleton bx={true} />
                        <RowSkeleton bx={true} />
                        <RowSkeleton bx={true} />
                    </>
                ) : data.event.accreditations.totalCount === 0 ? (
                    <Flex
                        align="center"
                        css={{
                            border: '1px solid $gray200',
                            borderTop: 'none',
                            py: '$8'
                        }}
                        direction="column"
                        gap="4"
                    >
                        <IconBackground color="primary" icon="user-group" />

                        <Box color="gray800" fontWeight="medium">
                            {translate('no_accreditations')}
                        </Box>
                    </Flex>
                ) : (
                    data.event.accreditations.nodes.map((acc) => (
                        <Row
                            key={acc.id}
                            css={{
                                cursor: 'pointer',
                                userSelect: 'none',
                                '&:hover': {
                                    background: '$gray100'
                                }
                            }}
                            onClick={() => {
                                history.push(
                                    AccreditationsPaths.ACCREDITATION({
                                        organizationId,
                                        eventId,
                                        accreditationId: acc.id
                                    })
                                );
                            }}
                        >
                            <Cell css={{ fontWeight: '$medium' }}>{fullName(acc)}</Cell>
                            <Cell>{acc.numberOfSlots}</Cell>
                            <Cell>{acc.accreditationDisplay}</Cell>
                        </Row>
                    ))
                )
            }
            setOffset={setOffset}
            title={translate('liste_des_accr_02647')}
            totalCount={totalCount}
        />
    );
};
