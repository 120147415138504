import { Page } from 'common-front/src/components/page/page';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { useParams } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { useAccreditationCategoryDashboardQuery } from '../../../generated/graphqlHooks';
import { AccreditationsCategoryAccreditationsCard } from '../cards/accreditationsCategoryAccreditationsCard/accreditationsCategoryAccreditationsCard';
import { AccreditationsCategoryChartCard } from '../cards/accreditationsCategoryChartCard/accreditationsCategoryChartCard';

export const AccreditationCategoryDashboard = () => {
    const { eventId, accreditationsCategoryId } = useParams();
    const { data: _data, loader } = useAccreditationCategoryDashboardQuery({
        eventId,
        accreditationsCategoryId
    });

    return (
        loader || (
            <Page.Content>
                <Flex direction="column" gap="6">
                    <Flex width={1}>
                        <AccreditationsCategoryChartCard />
                    </Flex>

                    <Box css={{ flex: '1' }}>
                        <AccreditationsCategoryAccreditationsCard />
                    </Box>
                </Flex>
            </Page.Content>
        )
    );
};
