import { Page } from 'common-front/src/components/page/page';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';
import { Route } from 'react-router';
import { CustomFieldsCard } from '../../../common/cards/customFieldsCard';
import { DescriptionCard } from '../../../common/cards/descriptionCard';
import { LocationCard } from '../../../common/cards/locationCard';
import { PrivateNoteCard } from '../../../common/cards/privateNoteCard';
import { TagsCard } from '../../../common/cards/tagsCard';
import { useEventContext } from '../../../events/show/eventContext';
import {
    usePositionDashboardQuery,
    usePositionLeadersAsignMutation,
    useTagsPositionAssociateMutation
} from '../../../generated/graphqlHooks';
import { PositionCategoryGauge } from '../../../positionsCategories/show/positionCategoryGauge';
import { PositionCategoryLeaders } from '../../../positionsCategories/show/positionCategoryLeaders';
import { PositionParameters } from '../positionParameters';
import { PositionSlotRightPanel } from '../positionSlots/positionSlotRightPanel';
import { PositionSlots } from '../positionSlots/positionSlots';

export const PositionDashboard = () => {
    const {
        params: { organizationId, eventId, positionId },
        translate
    } = useHeavent();
    const { data, loader, reload } = usePositionDashboardQuery({
        organizationId,
        eventId,
        positionId
    });
    const { mutate: leadersAssign } = usePositionLeadersAsignMutation();
    const { mutate: tagsAssociate } = useTagsPositionAssociateMutation();
    const { isPositionAdmin } = useEventContext();

    return (
        loader || (
            <Page.Content>
                <Flex gap="6">
                    <PositionCategoryGauge
                        category={data.event.position}
                        title={translate('remplissage_de_52564')}
                    />

                    <PositionParameters
                        customFields={data.organization.customFields.nodes}
                        position={data.event.position}
                    />
                </Flex>

                <Spacer height="6" />

                <PositionSlots />

                <Spacer height="6" />

                <Flex gap="6">
                    <Flex direction="column" gap="4" width={1}>
                        <PositionCategoryLeaders
                            leaders={data.event.position.leaders}
                            reload={reload}
                            roles={data.organization.roles}
                            onSave={(leaders) =>
                                leadersAssign({
                                    eventId,
                                    positionId,
                                    leaders
                                })
                            }
                        />

                        <CustomFieldsCard customFields={data.event.position.customFields} />

                        <TagsCard
                            modalSubtitle={translate('ajouter_des_tag_64526')}
                            reload={reload}
                            tags={data.event.position.tags}
                            {...(isPositionAdmin(data.event.position.positionCategoryId, positionId)
                                ? {
                                      associateTags: (tagsIds) =>
                                          tagsAssociate({
                                              eventId,
                                              positionId,
                                              tagsIds
                                          })
                                  }
                                : {})}
                        />
                    </Flex>

                    <Flex direction="column" gap="4" width={1}>
                        <DescriptionCard description={data.event.position.description} />

                        <LocationCard location={data.event.position} />

                        <PrivateNoteCard privateNote={data.event.position.privateNote} />
                    </Flex>
                </Flex>

                <Route
                    path={HeaventPaths.POSITION_SLOT_MEMBERS(
                        ':organizationId',
                        ':eventId',
                        ':positionId',
                        ':positionSlotId'
                    )}
                >
                    <PositionSlotRightPanel />
                </Route>
            </Page.Content>
        )
    );
};
