import { Form } from 'common-front/src/components/form/form';
import { FormErrors } from 'common-front/src/components/form/formErrors';
import { Button } from 'common-front/src/designSystem/components/button';
import { RightPanel } from 'common-front/src/designSystem/components/rightPanel/rightPanel';
import { RightPanelBody } from 'common-front/src/designSystem/components/rightPanel/rightPanelBody';
import { RightPanelFooter } from 'common-front/src/designSystem/components/rightPanel/rightPanelFooter';
import { RightPanelHeader } from 'common-front/src/designSystem/components/rightPanel/rightPanelHeader';
import { RightPanelLoader } from 'common-front/src/designSystem/components/rightPanel/rightPanelLoader';
import { RichSelect } from 'common-front/src/designSystem/form/richSelect';
import { Select } from 'common-front/src/designSystem/form/select/select';
import { TextInput } from 'common-front/src/designSystem/form/textInput';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Grid } from 'common/src/designSystem/components/grid';
import { Spacer } from 'common/src/designSystem/components/spacer';
import {
    CreateWeezeventRuleFragment,
    WeezeventField,
    WeezeventRuleInput
} from 'common/src/generated/types';
import {
    ICreateUpdateWeezeventRuleValues,
    WeezeventRuleInputService
} from 'common/src/input/weezeventRuleInput';
import { ValidateService } from 'common/src/services/validateService';
import { useParams, useService } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';
import {
    useCreateWeezeventRuleInfosQuery,
    useWeezeventRuleCreateMutation,
    useWeezeventRuleToEditQuery,
    useWeezeventRuleUpdateMutation
} from '../../../../generated/graphqlHooks';

interface IRuleNumberProps {
    children: React.ReactNode;
}

const RuleNumber = (props: IRuleNumberProps) => (
    <Flex
        align="center"
        css={{
            background: '$primary300',
            borderRadius: '24px',
            color: '$primary700',
            fontSize: '$textXs',
            fontWeight: '$semiBold'
        }}
        height={24}
        justify="center"
        width={24}
    >
        {props.children}
    </Flex>
);

const Line = () => (
    <Box
        css={{
            border: '2px solid $primary600',
            borderRadius: '2px',
            justifySelf: 'center',
            width: '2px'
        }}
        height={1}
    />
);

interface ICreateUpdateWeezeventRuleProps {
    fields: WeezeventField[];
    initialValues: ICreateUpdateWeezeventRuleValues;
    isEdit: boolean;
    segmentsFolders: CreateWeezeventRuleFragment['event']['segmentsFolders'];

    mutate(weezeventRule: WeezeventRuleInput): Promise<any>;
    onSuccess(): void;
}

const CreateUpdateWeezeventRule = (props: ICreateUpdateWeezeventRuleProps) => {
    const {
        params: { organizationId, eventId },
        translate
    } = useHeavent();
    const weezeventRuleInput = useService(WeezeventRuleInputService);
    const validateService = useService(ValidateService);

    return (
        <Form
            hideErrors={true}
            initialValues={props.initialValues}
            render={({ handleSubmit, submitting, values }) => {
                const selectedField = props.fields.find(
                    (field) => field.id === values.weezeventRule.idQuestion
                );

                return (
                    <RightPanel
                        returnToFallback={HeaventPaths.API_WEEZEVENT(organizationId, eventId)}
                        size="md"
                    >
                        <RightPanelHeader>
                            {props.isEdit
                                ? translate('_diter_la_r_gle_29081')
                                : translate('nouvelle_r_gle_66127')}
                        </RightPanelHeader>

                        <RightPanelBody>
                            <Spacer height="7" />

                            <FormErrors />

                            <TextInput
                                label={translate('nom_de_votre_r_36932')}
                                name="weezeventRule.name"
                            />

                            <Spacer height="8" />

                            <Box color="gray800" fontSize="textMd" fontWeight="semiBold">
                                {translate('param_tres_de_l_14644')}
                            </Box>

                            <Spacer height="1" />

                            <Box color="gray500">{translate('param_trez_la_r_13448')}</Box>

                            <Spacer height="6" />

                            <Grid
                                align="center"
                                columnGap="4"
                                gridtemplatecolumns="24px 1fr"
                                gridtemplaterows="auto"
                                rowGap="1"
                            >
                                <RuleNumber>1</RuleNumber>

                                <Box color="gray800" fontWeight="medium">
                                    {translate('si_le_membre_fa_34820')}
                                </Box>

                                <Line />

                                <Flex direction="column">
                                    <Spacer height="1" />

                                    <RichSelect
                                        isSearchVisible={true}
                                        name="weezeventRule.segmentId"
                                    >
                                        {props.segmentsFolders.map((segmentFolder) => (
                                            <optgroup
                                                key={segmentFolder.id}
                                                label={segmentFolder.name}
                                            >
                                                {segmentFolder.segments.map((segment) => (
                                                    <option key={segment.id} value={segment.id}>
                                                        {segment.name}
                                                    </option>
                                                ))}
                                            </optgroup>
                                        ))}
                                    </RichSelect>

                                    <Spacer height="7" />
                                </Flex>

                                <RuleNumber>2</RuleNumber>

                                <Box color="gray800" fontWeight="medium">
                                    {translate('alors_la_valeur_89509')}
                                </Box>

                                <Line />

                                <Flex direction="column">
                                    <Spacer height="1" />

                                    <RichSelect
                                        isSearchVisible={true}
                                        name="weezeventRule.idQuestion"
                                    >
                                        {props.fields.map((field) => (
                                            <option key={field.id} value={field.id}>
                                                {field.name}
                                            </option>
                                        ))}
                                    </RichSelect>

                                    <Spacer height="7" />
                                </Flex>

                                <RuleNumber>3</RuleNumber>

                                <Box color="gray800" fontWeight="medium">
                                    {translate('prends_la_valeu_93740')}
                                </Box>

                                <Box />

                                {selectedField && (
                                    <Box>
                                        {selectedField.weezeventFieldType === 'checkbox' ? (
                                            <Select
                                                name="weezeventRule.value"
                                                shouldParseAsBoolean={true}
                                            >
                                                <option value="">-</option>
                                                <option value="true">{translate('true')}</option>
                                                <option value="false">{translate('false')}</option>
                                            </Select>
                                        ) : selectedField.weezeventFieldType === 'select' ? (
                                            <Select name="weezeventRule.value">
                                                <option value="">-</option>
                                                {(selectedField.choices ?? []).map((choice) => (
                                                    <option
                                                        key={choice.id}
                                                        value={
                                                            selectedField.id === 'ticket'
                                                                ? choice.id
                                                                : choice.label
                                                        }
                                                    >
                                                        {choice.label}
                                                    </option>
                                                ))}
                                            </Select>
                                        ) : (
                                            <TextInput name="weezeventRule.value" />
                                        )}
                                    </Box>
                                )}
                            </Grid>

                            <Spacer height="7" />
                        </RightPanelBody>

                        <RightPanelFooter>
                            <Flex gap="3" justify="end">
                                <Button isLoading={submitting} onClick={handleSubmit}>
                                    {props.isEdit
                                        ? translate('modifier_la_r_g_44361')
                                        : translate('cr_er_la_r_gle_94387')}
                                </Button>

                                <Button
                                    color="white"
                                    to={HeaventPaths.API_WEEZEVENT(organizationId, eventId)}
                                >
                                    {translate('annuler_48254')}
                                </Button>
                            </Flex>
                        </RightPanelFooter>
                    </RightPanel>
                );
            }}
            validate={validateService.validateForForm(
                weezeventRuleInput.createWeezeventRuleSchema()
            )}
            onSubmit={async (values: ICreateUpdateWeezeventRuleValues) => {
                await props.mutate(values.weezeventRule);

                props.onSuccess();
            }}
        />
    );
};

interface ICreateWeezeventRuleProps {
    onSuccess(): void;
}

export const CreateWeezeventRule = (props: ICreateWeezeventRuleProps) => {
    const { organizationId, eventId } = useParams();
    const { data, loader } = useCreateWeezeventRuleInfosQuery({ eventId });
    const { mutate } = useWeezeventRuleCreateMutation();
    const weezeventRuleInput = useService(WeezeventRuleInputService);

    if (loader) {
        return (
            <RightPanelLoader
                returnPathFallback={HeaventPaths.API_WEEZEVENT(organizationId, eventId)}
            />
        );
    } else {
        return (
            <CreateUpdateWeezeventRule
                fields={data.weezevent.fields}
                initialValues={{
                    weezeventRule: weezeventRuleInput.weezeventRuleDefault()
                }}
                isEdit={false}
                mutate={(weezeventRule) => mutate({ eventId, weezeventRule })}
                segmentsFolders={data.event.segmentsFolders}
                onSuccess={props.onSuccess}
            />
        );
    }
};

interface IUpdateWeezeventRuleProps {
    onSuccess(): void;
}

export const UpdateWeezeventRule = (props: IUpdateWeezeventRuleProps) => {
    const { organizationId, eventId, ruleId } = useParams();
    const { data, loader } = useWeezeventRuleToEditQuery({ eventId, ruleId });
    const { mutate } = useWeezeventRuleUpdateMutation();
    const weezeventRuleInput = useService(WeezeventRuleInputService);

    if (loader) {
        return (
            <RightPanelLoader
                returnPathFallback={HeaventPaths.API_WEEZEVENT(organizationId, eventId)}
            />
        );
    } else {
        return (
            <CreateUpdateWeezeventRule
                fields={data.weezevent.fields}
                initialValues={{
                    weezeventRule: weezeventRuleInput.weezeventRuleDefault(data.event.weezeventRule)
                }}
                isEdit={true}
                mutate={(weezeventRule) => mutate({ eventId, ruleId, weezeventRule })}
                segmentsFolders={data.event.segmentsFolders}
                onSuccess={props.onSuccess}
            />
        );
    }
};
