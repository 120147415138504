import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { TeamAssignmentInfosQuery } from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';

interface ITeamAssignmentHeaderProps {
    team: NonNullable<TeamAssignmentInfosQuery['event']['team']>;
}

export const TeamAssignmentHeader = (props: ITeamAssignmentHeaderProps) => {
    const translate = useTranslate();

    return (
        <Flex
            align="center"
            css={{
                padding: '$5 $6'
            }}
            gap="4"
        >
            <Flex css={{ flex: '1' }} direction="column">
                <Box font="gray900 textXl medium">{props.team.teamCode}</Box>
                <Box color="gray500">{translate('_1_membre_49737', props.team.members.length)}</Box>
            </Flex>
        </Flex>
    );
};
