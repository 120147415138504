import { ChartDataset } from 'chart.js/auto';
import { theme } from 'common/src/designSystem/components/stitches';
import { Accreditation, AccreditationsSlot } from 'common/src/generated/types';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { hexToRgba } from 'common/src/util/color';
import { useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import { fullName, sortedSlots } from 'common/src/vo/accreditationSlot';
import * as React from 'react';
import { BarChart, commonYAxisOptions, titleOptions } from './barChart';

type SlotResources = Pick<
    AccreditationsSlot,
    'date' | 'name' | 'assignedResources' | 'wishedResources' | 'scannedResources' | 'maxResources'
>;

interface ISlotsBarChartProps {
    accreditation: Pick<Accreditation, 'name' | 'hasSlots'>;
    slots: SlotResources[];
    weezevent?: any;
}

export const SlotsBarChart = (props: ISlotsBarChartProps) => {
    const translate = useTranslate();
    const dateTimeService = useService(DateTimeService);
    const slots = React.useMemo(() => sortedSlots(props.slots), [props.slots]);

    const labels = React.useMemo(() => {
        if (props.accreditation.hasSlots) {
            return slots.map((slot) =>
                fullName(dateTimeService, slot, '', { includeAccreditationName: false })
            );
        } else {
            return [props.accreditation.name];
        }
    }, [slots, props.accreditation]);
    const datasets = React.useMemo(() => {
        const isWeezeventSynchronized = typeof props.weezevent?.id === 'number';

        // if (accreditation.hasSlots) {
        const hasMax = slots.some((s) => typeof s.maxResources === 'number');
        const datasets: ChartDataset<'bar', number[]>[] = [
            {
                label: translate('souhait_es_78101'),
                data: slots.map((s) => s.wishedResources),
                backgroundColor: hasMax
                    ? slots.map((s) =>
                          s.assignedResources + s.wishedResources <= (s.maxResources ?? Infinity)
                              ? theme.colors.primary400.value
                              : hexToRgba(theme.colors.primary400.value, 0.5)
                      )
                    : theme.colors.primary400.value
            },
            {
                label: translate('affect_es_00048'),
                data: slots.map((s) => s.assignedResources),
                backgroundColor: hasMax
                    ? slots.map((s) =>
                          s.assignedResources <= (s.maxResources ?? Infinity)
                              ? theme.colors.primary700.value
                              : hexToRgba(theme.colors.error500.value, 0.7)
                      )
                    : theme.colors.primary700.value
            }
        ];

        if (isWeezeventSynchronized) {
            datasets.push({
                label: translate('scann_es_13169'),
                data: slots.map((s) => s.scannedResources),
                backgroundColor: theme.colors.primary900.value
            });
        }

        if (hasMax) {
            datasets.push({
                label: translate('maximum_56225'),
                data: slots.map((s) => s.maxResources ?? Infinity),
                backgroundColor: theme.colors.gray200.value
            });
        }

        return datasets;
        /*
        } else {
            const datasets: ChartDataset<'bar', number[]>[] = [
                {
                    label: translate('souhait_es_78101'),
                    data: [accreditation.hiddenSlot.wishedResources],
                    backgroundColor: theme.colors.primary400.value
                },
                {
                    label: translate('affect_es_00048'),
                    data: [accreditation.hiddenSlot.assignedResources],
                    backgroundColor: theme.colors.primary700.value
                }
            ];

            if (isWeezeventSynchronized) {
                datasets.push({
                    label: translate('scann_es_13169'),
                    data: [accreditation.hiddenSlot.scannedResources],
                    backgroundColor: theme.colors.primary900.value
                });
            }

            return datasets;
        }
        */
    }, [slots]);

    return (
        <BarChart
            datasets={datasets}
            labels={labels}
            options={{
                scales: {
                    y: {
                        ...commonYAxisOptions(),
                        ticks: {
                            precision: 0
                        },
                        ...titleOptions(translate('nb_d_accr_dita_23359'))
                    },
                    x: {
                        grid: { display: false },
                        ...titleOptions(
                            translate(
                                props.accreditation.hasSlots ? 'slots_05517' : 'Accreditation'
                            )
                        )
                    }
                }
            }}
        />
    );
};
