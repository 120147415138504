import { Avatar } from 'common/src/components/avatar/avatar';
import { AvatarNameEmail } from 'common/src/components/avatarNameEmail';
import { Box } from 'common/src/designSystem/components/box';
import { Cell } from 'common/src/designSystem/components/table/cell';
import {
    CustomFieldSlug,
    EventId,
    SegmentCustomFieldFragment,
    VolunteerRegistrationFragment
} from 'common/src/generated/types';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { DurationService } from 'common/src/services/durationService';
import { isNonEmptyArray } from 'common/src/util/array';
import { useService } from 'common/src/util/dependencies/dependencies';
import { Emptyable } from 'common/src/util/emptyable';
import { LocaleFormats } from 'common/src/util/luxon';
import { isNonEmptyString } from 'common/src/util/string';
import { getFormInsertedAt, getFormsInsertedAt } from 'common/src/vo/formUserInfo';
import { PossibleColumn } from 'common/src/vo/segment';
import { VolunteerDefaultColumns } from 'common/src/vo/segments/volunteersSegmentsService';
import * as React from 'react';
import { UpdateCustomFieldCell } from '../../components/cells/updateCustomFieldCell';
import { useVolunteerRegistrationUpdateFieldMutation } from '../../generated/graphqlHooks';
import { useHeavent } from '../../hooks/useHeavent';
import { VolunteerRegistrationAccreditations } from './cells/volunteerRegistrationAccreditations';
import { VolunteerRegistrationAccreditationsCategories } from './cells/volunteerRegistrationAccreditationsCategories';
import { VolunteerRegistrationAccreditationStateCell } from './cells/volunteerRegistrationAccreditationStateCell';
import { VolunteerRegistrationAvailabilities } from './cells/volunteerRegistrationAvailabilities';
import { VolunteerRegistrationDelegation } from './cells/volunteerRegistrationDelegation';
import { VolunteerRegistrationForms } from './cells/volunteerRegistrationForms';
import { VolunteerRegistrationPositions } from './cells/volunteerRegistrationPositions';
import { VolunteerRegistrationPositionsCategories } from './cells/volunteerRegistrationPositionsCategories';
import { VolunteerRegistrationPreassignedPositions } from './cells/volunteerRegistrationPreassignedPositions';
import { VolunteerRegistrationPreassignedPositionsCategories } from './cells/volunteerRegistrationPreassignedPositionsCategories';
import { VolunteerRegistrationStateCell } from './cells/volunteerRegistrationStateCell';
import { VolunteerRegistrationWishedAccreditations } from './cells/volunteerRegistrationWishedAccreditations';
import { VolunteerRegistrationWishedPositions } from './cells/volunteerRegistrationWishedPositions';

interface IVolunteerCellsProps {
    accreditationPanelPath?: string;
    availabilitiesPanelPath?: string;
    column: PossibleColumn;
    customField: Emptyable<SegmentCustomFieldFragment>;
    eventId: EventId;
    row: VolunteerRegistrationFragment;

    reloadRow(): void;
}

export const VolunteerCells = (props: IVolunteerCellsProps) => {
    const { history } = useHeavent();
    const dateTimeService = useService(DateTimeService);
    const durationService = useService(DurationService);
    const formsInsertedAt = React.useMemo(
        () => getFormsInsertedAt(dateTimeService, props.row.userInfo.formsUsersInfos || []),
        [props.row]
    );
    const { mutate: volunteerRegistrationUpdateField } =
        useVolunteerRegistrationUpdateFieldMutation();
    const updateField = React.useCallback(
        (value: any, slug: CustomFieldSlug) =>
            volunteerRegistrationUpdateField({
                eventId: props.eventId,
                volunteerRegistrationId: props.row.id,
                slug,
                value
            }),
        [props.eventId, props.row, volunteerRegistrationUpdateField]
    );
    const userInfo = props.row.userInfo;

    switch (props.column.slug) {
        case VolunteerDefaultColumns.Accreditations:
            return (
                <VolunteerRegistrationAccreditations
                    eventId={props.eventId}
                    reload={props.reloadRow}
                    volunteerRegistration={props.row}
                    onClick={() => {
                        if (isNonEmptyString(props.accreditationPanelPath)) {
                            history.push(`${props.accreditationPanelPath}/assigned`);
                        }
                    }}
                />
            );
        case VolunteerDefaultColumns.AccreditationsCategories:
            return (
                <VolunteerRegistrationAccreditationsCategories volunteerRegistration={props.row} />
            );
        case VolunteerDefaultColumns.AccreditationState:
            return (
                <VolunteerRegistrationAccreditationStateCell
                    eventId={props.eventId}
                    field={props.column}
                    reload={props.reloadRow}
                    state={props.row.accreditationState}
                    volunteerRegistrationId={props.row.id}
                />
            );
        case VolunteerDefaultColumns.Availability:
            return (
                <VolunteerRegistrationAvailabilities
                    volunteerRegistration={props.row}
                    onClick={() => {
                        if (isNonEmptyString(props.availabilitiesPanelPath)) {
                            history.push(props.availabilitiesPanelPath);
                        }
                    }}
                />
            );
        case VolunteerDefaultColumns.Comments:
            return (
                <Cell>
                    {isNonEmptyArray(userInfo.comments)
                        ? userInfo.comments.flatMap((c) => c.content).join('; ')
                        : ''}
                </Cell>
            );
        case VolunteerDefaultColumns.CompletedForms:
            return <VolunteerRegistrationForms volunteerRegistration={props.row} />;
        case VolunteerDefaultColumns.FormsInsertedAt:
            return (
                <Cell>
                    <Box css={{ ellipsis: true }} title={formsInsertedAt}>
                        {formsInsertedAt}
                    </Box>
                </Cell>
            );
        case VolunteerDefaultColumns.Delegations:
            return (
                <VolunteerRegistrationDelegation
                    eventId={props.eventId}
                    reload={props.reloadRow}
                    volunteerRegistration={props.row}
                />
            );
        case VolunteerDefaultColumns.Email:
            return <Cell>{userInfo.email}</Cell>;
        case VolunteerDefaultColumns.Id:
            return <Cell width={100}>{userInfo.id}</Cell>;
        case VolunteerDefaultColumns.InsertedAt:
            return (
                <Cell width={230}>
                    {dateTimeService.toLocaleString(
                        props.row.insertedAt.toLocal(),
                        LocaleFormats.DateTime
                    )}
                </Cell>
            );
        case VolunteerDefaultColumns.Name:
            return (
                <Cell>
                    <AvatarNameEmail hasName={true} size={32} userInfo={userInfo} />
                </Cell>
            );
        case VolunteerDefaultColumns.NumberOfAccreditations:
            return <Cell width={230}>{props.row.numberOfAccreditations}</Cell>;
        case VolunteerDefaultColumns.NumberOfAssignments:
            return <Cell width={230}>{props.row.numberOfAssignments}</Cell>;
        case VolunteerDefaultColumns.NumberOfAssignmentsDays:
            return <Cell width={230}>{props.row.numberOfAssignmentsDays}</Cell>;
        case VolunteerDefaultColumns.NumberOfAvailableDays:
            return <Cell width={230}>{props.row.numberOfAvailableDays}</Cell>;
        case 'picture':
            return (
                <Cell width={80}>
                    <Avatar
                        email={userInfo.email}
                        image={userInfo.picture?.url}
                        name={userInfo.name}
                        size={32}
                    />
                </Cell>
            );
        case VolunteerDefaultColumns.Positions:
            return (
                <VolunteerRegistrationPositions
                    eventId={props.eventId}
                    reload={props.reloadRow}
                    volunteerRegistration={props.row}
                />
            );
        case VolunteerDefaultColumns.PositionsCategories:
            return <VolunteerRegistrationPositionsCategories volunteerRegistration={props.row} />;
        case VolunteerDefaultColumns.PreassignedPositions:
            return <VolunteerRegistrationPreassignedPositions volunteerRegistration={props.row} />;
        case VolunteerDefaultColumns.PreassignedPositionsCategories:
            return (
                <VolunteerRegistrationPreassignedPositionsCategories
                    volunteerRegistration={props.row}
                />
            );
        case VolunteerDefaultColumns.TeamCode:
            return (
                <Cell
                    onClick={(e) => {
                        e.stopPropagation();
                        e.nativeEvent.stopImmediatePropagation();
                    }}
                >
                    {props.row.teamCode}
                </Cell>
            );
        case VolunteerDefaultColumns.VolunteerMinutes:
            return (
                <Cell width={230}>
                    {durationService.minutesToHoursMinutesString(props.row.volunteerMinutes)}
                </Cell>
            );
        case VolunteerDefaultColumns.VolunteerRegistrationState:
            return (
                <VolunteerRegistrationStateCell
                    eventId={props.eventId}
                    field={props.column}
                    reload={props.reloadRow}
                    state={props.row.state}
                    volunteerRegistrationId={props.row.id}
                />
            );
        case VolunteerDefaultColumns.WishedAccreditationSlots:
            return (
                <VolunteerRegistrationWishedAccreditations
                    volunteerRegistration={props.row}
                    onClick={() => {
                        if (isNonEmptyString(props.accreditationPanelPath)) {
                            history.push(`${props.accreditationPanelPath}/wished`);
                        }
                    }}
                />
            );
        case VolunteerDefaultColumns.WishedPositions:
            return <VolunteerRegistrationWishedPositions volunteerRegistration={props.row} />;
        case VolunteerDefaultColumns.WeezeventBarcodeId:
            return (
                <Cell>{props.row.weezeventParticipantInfo?.idBarcode ?? props.row.ticketId}</Cell>
            );
        case VolunteerDefaultColumns.WeezeventShortTag:
            return <Cell>{props.row.shortTag || ''}</Cell>;
        default:
            if (Object.values<string>(VolunteerDefaultColumns).includes(props.column.slug)) {
                console.error(`003: Unhandled default column ${props.column.slug}`);
                return <Cell>ERR</Cell>;
            }

            return props.column.slug.startsWith('form_inserted_at') ? (
                <Cell width={230}>
                    {getFormInsertedAt(
                        dateTimeService,
                        props.column.slug,
                        props.row.userInfo.formsUsersInfos || []
                    )}
                </Cell>
            ) : (
                <UpdateCustomFieldCell
                    customField={props.customField}
                    fields={props.row.userInfo.fields}
                    mutate={updateField}
                    reload={props.reloadRow}
                />
            );
    }
};
