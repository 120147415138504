import { injectable } from 'inversify';
import * as yup from 'yup';
import {
    CreateDelegationFormFragment,
    DelegationEventInfosQuery,
    DelegationEventToEditQuery,
    DelegationId,
    DelegationInput,
    FormId
} from '../generated/types';
import { TranslationService } from '../services/translationService';
import { Emptyable } from '../util/emptyable';
import { DateTimeSchemaType } from '../util/yup/dateTimeSchemaType';
import { getDefaultColumns } from '../vo/delegation';
import { InputService } from './inputService';

export interface ICreateUpdateDelegationValues {
    delegation: DelegationInput;
}

interface IDelegationInputDefaultOptions {
    forms?: CreateDelegationFormFragment[];
    parentId: Emptyable<DelegationId>;
    parentDelegation: Emptyable<
        DelegationEventInfosQuery['organization']['delegations']['nodes'][0]
    >;
}

@injectable()
export class DelegationInputService extends InputService {
    constructor(translationService: TranslationService) {
        super(translationService);
    }

    delegationInputDefault(
        delegation: Emptyable<DelegationEventToEditQuery['event']['delegation']>,
        options: Emptyable<IDelegationInputDefaultOptions>
    ): DelegationInput {
        const form =
            options?.forms && options?.parentDelegation
                ? options.forms.find((f) => f.name === options.parentDelegation?.form.name)
                : null;
        const formId = delegation?.formId ?? form?.id ?? (-1 as FormId);

        return {
            parentId: delegation?.parentId ?? options?.parentId ?? null,
            name: delegation?.name ?? '',
            leadersIds: delegation?.leadersIds ?? options?.parentDelegation?.leadersIds ?? [],
            formId,
            deadlineDate: delegation?.deadlineDate ?? null,
            maxResources: delegation?.maxResources ?? null,
            showInDelegationSpace: delegation?.showInDelegationSpace ?? true,
            showFormLinkInDelegationSpace: delegation?.showFormLinkInDelegationSpace ?? true,
            columns:
                delegation?.columns ?? options?.parentDelegation?.columns ?? getDefaultColumns(),
            canLeadersAddMembers: delegation?.canLeadersAddMembers ?? true,
            canLeadersEditMembers: delegation?.canLeadersEditMembers ?? true,
            canLeadersEditCustomFields: delegation?.canLeadersEditCustomFields ?? true,
            privateNote: delegation?.privateNote ?? options?.parentDelegation?.privateNote ?? '',
            accreditations: delegation?.accreditations || [],
            accreditationsSlots: delegation?.accreditationsSlots || [],
            addDefaultAccreditationsByAvailability:
                delegation?.addDefaultAccreditationsByAvailability ?? false,
            tagsIds: delegation?.tagsIds ?? options?.parentDelegation?.tagsIds ?? [],
            customFields: delegation?.customFields || [],
            fields: delegation?.fields ?? {}
        };
    }

    delegationInputSchema() {
        return yup.object().shape({
            parentId: yup.number().nullable(),
            name: yup.string().when('parentId', {
                is: null,
                then: (n) => n.required(this.t('le_nom_de_la_d_10042'))
            }),
            leadersIds: yup.array().of(yup.number()),
            formId: yup
                .number()
                .required(this.t('le_formulaire_e_77404'))
                .typeError(this.t('le_formulaire_e_77404')),
            deadlineDate: DateTimeSchemaType.typeError(this.t('la_date_limite_91708')).nullable(),
            maxResources: yup.number().min(0).nullable(),
            showInDelegationSpace: yup.boolean().required(),
            showFormLinkInDelegationSpace: yup.boolean().required(),
            columns: yup
                .array()
                .of(yup.string())
                .required(this.t('vous_devez_choi_82605'))
                .min(1, this.t('vous_devez_choi_82605')),
            canLeadersAddMembers: yup.boolean().required(),
            canLeadersEditMembers: yup.boolean().required(),
            canLeadersEditCustomFields: yup.boolean().required(),
            privateNote: yup.string(),
            accreditations: yup.array().of(
                yup.object().shape({
                    accreditationCategoryId: yup.number().required(),
                    accreditationId: yup.number().required(),
                    maxResources: yup.mixed().nullable()
                })
            ),
            accreditationsSlots: yup.array().of(
                yup.object().shape({
                    accreditationCategoryId: yup.number().required(),
                    accreditationId: yup.number().required(),
                    accreditationSlotId: yup.number().required(),
                    isDefault: yup.boolean().required(),
                    maxResources: yup.mixed().nullable()
                })
            )
        });
    }

    createUpdateDelegationSchema() {
        return yup.object().shape({
            delegation: this.delegationInputSchema()
        });
    }

    adminAddSchema() {
        return yup.object().shape({
            usersInfosIds: yup.array().of(yup.number())
        });
    }
}
