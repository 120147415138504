import Chart, { ChartDataset, ChartOptions } from 'chart.js/auto';
import { Box } from 'common/src/designSystem/components/box';
import { theme } from 'common/src/designSystem/components/stitches';
import merge from 'lodash/merge';
import uniqueId from 'lodash/uniqueId';
import * as React from 'react';

export const titleOptions = (text: string) => ({
    title: {
        display: true,
        font: { weight: 500 },
        padding: {
            bottom: 30
        },
        text
    }
});

const commonXAxisOptions = () => ({ grid: { display: false } });

export const commonYAxisOptions = () => ({
    border: {
        display: false
    },
    grid: {
        color: theme.colors.gray100.value
    }
});

interface IBarChartProps {
    datasets: ChartDataset[];
    labels: string[];
    options?: ChartOptions;
}

export const BarChart = ({ datasets, labels, options }: IBarChartProps) => {
    const chartId = React.useMemo(() => uniqueId('chart-'), []);
    const chartRef = React.useRef<any>(null);

    const defaultOptions = {
        animation: false,
        datasets: {
            bar: {
                //barThickness: 32,
                borderRadius: 8
            }
        },
        maintainAspectRatio: false,
        plugins: {
            legend: {
                align: 'end',
                labels: {
                    // Circular legend items
                    useBorderRadius: true,
                    borderRadius: 5,
                    boxWidth: 10,
                    boxHeight: 10
                }
            }
        },
        scales: {
            x: commonXAxisOptions(),
            y: commonYAxisOptions()
        }
    };

    React.useEffect(() => {
        if (chartRef.current) {
            chartRef.current.destroy();
        }

        chartRef.current = new Chart(document.getElementById(chartId) as any, {
            type: 'bar',
            options: merge(defaultOptions, options),
            data: {
                labels,
                datasets
            }
        });
    }, [datasets, labels, options]);

    return (
        <Box height={1} width={1}>
            <canvas id={chartId} />
        </Box>
    );
};
