import { DetailBox } from 'common-front/src/designSystem/components/detailBox';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { useMobileQuery } from 'common-front/src/hooks/useMobileQuery';
import { Flex } from 'common/src/designSystem/components/flex';
import { fromIconVO, I } from 'common/src/designSystem/components/i';
import { LinkText } from 'common/src/designSystem/components/linkText';
import {
    CustomFieldWithConditionFragment,
    PositionDashboardQuery
} from 'common/src/generated/types';
import { IntervalService } from 'common/src/services/intervalService';
import { useService } from 'common/src/util/dependencies/dependencies';
import { PositionsPaths } from 'common/src/util/paths/positionsPaths';
import { FieldService } from 'common/src/vo/field';
import * as React from 'react';
import {
    Label,
    Parameter,
    ParameterSeparator,
    ParametersList,
    Value
} from '../../common/cards/parametersList';
import { PositionColorMobile } from '../mobile/show/positionColorMobile';
import { PositionColor } from './positionColor';

interface IPositionParametersProps {
    customFields: CustomFieldWithConditionFragment[];
    position: PositionDashboardQuery['event']['position'];
}

export const PositionParameters = ({ customFields, position }: IPositionParametersProps) => {
    const onMobile = useMobileQuery();
    const {
        params: { organizationId, eventId },
        translate
    } = useHeavent();
    const intervalService = useService(IntervalService);
    const fieldService = useService(FieldService);

    return (
        <DetailBox title={translate('param_tres_78420')}>
            <Flex direction="column" gap="4" width={1}>
                <ParametersList>
                    <Parameter>
                        <Label>{translate('cat_gorie_00291')}</Label>
                        <Value>
                            {onMobile ? (
                                <>{position.positionCategoryName}</>
                            ) : (
                                <LinkText
                                    to={PositionsPaths.POSITION_CATEGORY({
                                        organizationId,
                                        eventId,
                                        positionCategoryId: position.positionCategoryId
                                    })}
                                >
                                    {position.positionCategoryName}
                                </LinkText>
                            )}
                        </Value>
                    </Parameter>
                    <ParameterSeparator direction="horizontal" />
                    <Parameter>
                        <Label>{translate('Color')}</Label>
                        <Value>
                            {onMobile ? (
                                <PositionColorMobile position={position} />
                            ) : (
                                <PositionColor position={position} />
                            )}
                        </Value>
                    </Parameter>
                    <ParameterSeparator direction="horizontal" />
                    <Parameter>
                        <Label>{translate('ic_ne_55554')}</Label>
                        <Value>
                            <I icon={fromIconVO(position.icon)} />
                        </Value>
                    </Parameter>
                    <ParameterSeparator direction="horizontal" />
                    <Parameter>
                        <Label>{translate('Date')}</Label>
                        <Value>
                            {position.range
                                ? onMobile
                                    ? intervalService.toLocaleString(position.range, undefined, {
                                          year: 'numeric',
                                          month: 'long',
                                          day: 'numeric'
                                      })
                                    : intervalService.toDisplayString(position.range)
                                : '-'}
                        </Value>
                    </Parameter>

                    {customFields.map((cf) => (
                        <React.Fragment key={cf.slug}>
                            <ParameterSeparator direction="horizontal" />
                            <Parameter>
                                <Label>{cf.name}</Label>
                                <Value>
                                    {fieldService.getValueString(cf, position.fields, {
                                        fileReturnValue: 'name'
                                    }) || '-'}
                                </Value>
                            </Parameter>
                        </React.Fragment>
                    ))}
                </ParametersList>
            </Flex>
        </DetailBox>
    );
};
