import { Avatar } from 'common/src/components/avatar/avatar';
import { Flex } from 'common/src/designSystem/components/flex';
import { styled } from 'common/src/designSystem/components/stitches';
import { EventId, OrganizationQuery } from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import { PositionsPaths } from 'common/src/util/paths/positionsPaths';
import * as React from 'react';
import { NavigationLink } from './navigationLink';
import { NavigationLinkIcon } from './navigationLinkIcon';

const Bar = styled(Flex, {
    color: '$gray500',
    padding: '10px 0',
    boxShadow: '0px -2px 8px -2px rgba(16, 24, 40, 0.04), 0px -4px 12px -2px rgba(16, 24, 40, 0.05)'
});

export const NavigationBar = ({
    user,
    eventId
}: {
    user: OrganizationQuery['user'];
    eventId: EventId;
}) => {
    const translate = useTranslate();

    return (
        <Bar justify="evenly">
            <NavigationLink
                eventId={eventId}
                path={HeaventPaths.DASHBOARD(':organizationId', ':eventId')}
                text={translate('accueil_70474')}
            >
                {(active) => <NavigationLinkIcon active={active} className="fa-house" />}
            </NavigationLink>
            <NavigationLink
                eventId={eventId}
                path={HeaventPaths.VOLUNTEERS(':organizationId', ':eventId')}
                text={translate('membres_11310')}
            >
                {(active) => <NavigationLinkIcon active={active} className="fa-user-group" />}
            </NavigationLink>
            <NavigationLink
                eventId={eventId}
                path={PositionsPaths.POSITIONS({
                    organizationId: ':organizationId',
                    eventId: ':eventId'
                })}
                text={translate('missions_63972')}
            >
                {(active) => <NavigationLinkIcon active={active} className="fa-sitemap" />}
            </NavigationLink>
            <NavigationLink
                eventId={eventId}
                path={HeaventPaths.ORGANIZATION_PARAMETERS(':organizationId')}
                text={translate('profil_14444')}
            >
                {() => (
                    <Avatar
                        email={user.email}
                        image={user.userInfo.picture?.url}
                        name={user.userInfo.name}
                        size={24}
                    />
                )}
            </NavigationLink>
        </Bar>
    );
};
