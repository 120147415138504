import { useTitle } from 'common-front/src/hooks/useTitle';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { useParams, useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { useEventContext } from '../events/show/eventContext';
import { Templates } from './templates/templates';

export const Emails = () => {
    const translate = useTranslate();
    const {
        event: { name: eventName }
    } = useEventContext();
    useTitle(`${eventName} ${translate('e_mails_09850')}`);
    const { organizationId, eventId } = useParams();

    return (
        <Flex css={{ padding: '$7' }} direction="column" width={1}>
            <Box color="gray900" fontSize="displayXs" fontWeight="semiBold">
                {translate('mod_les_e_mail_91257')}
            </Box>

            <Spacer height="7" />

            <Templates eventId={eventId} organizationId={organizationId} />
        </Flex>
    );
};
