import { Button } from 'common-front/src/designSystem/components/button';
import { Dropdown } from 'common-front/src/designSystem/components/dropdown/dropdown';
import { Menu } from 'common-front/src/designSystem/components/dropdown/menu';
import { Trigger } from 'common-front/src/designSystem/components/dropdown/trigger';
import { Label } from 'common-front/src/designSystem/components/input/label';
import { RichSelect } from 'common-front/src/designSystem/components/richSelect/richSelect';
import { Select } from 'common-front/src/designSystem/components/select/select';
import { TextInput } from 'common-front/src/designSystem/components/textInput';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Badge } from 'common/src/designSystem/components/badge';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Skeleton } from 'common/src/designSystem/components/skeleton';
import { Spacer } from 'common/src/designSystem/components/spacer';
import {
    AccreditAssignWaitingMembersQuery,
    AccreditationState,
    DelegationId,
    SegmentId,
    SortDirection,
    UsersInfoId,
    VolunteerRegistrationState
} from 'common/src/generated/types';
import { isNonEmptyArray } from 'common/src/util/array';
import { Emptyable } from 'common/src/util/emptyable';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import { last } from 'lodash-es';
import * as React from 'react';
import { BackButton } from '../../components/buttons/backButton';
import { AccreditAssignLeftPanelMember } from './accreditAssignLeftPanelMember';
import { AccreditAssignWaitingVolunteerSkeleton } from './accreditAssignWaitingVolunteerSkeleton';

interface IAccreditAssignLeftPanelProps {
    accreditationStates: AccreditationState[];
    delegationId: Emptyable<DelegationId>;
    direction: SortDirection;
    event: AccreditAssignWaitingMembersQuery['event'];
    isLoading: boolean;
    loadDelegations: boolean;
    name: string;
    segmentId: SegmentId;
    showStates: boolean;
    showAccreditationStates: boolean;
    states: VolunteerRegistrationState[];
    userInfoId: UsersInfoId;

    getPath(userInfoId: UsersInfoId): string;
    setAccreditationStates(accreditationStates: AccreditationState[]): void;
    setDelegationId(delegationId: Emptyable<DelegationId>): void;
    setDirection(direction: SortDirection): void;
    setName(name: string): void;
    setSegmentId(segmentId: SegmentId): void;
    setStates(states: VolunteerRegistrationState[]): void;
}

export const AccreditAssignLeftPanel = (props: IAccreditAssignLeftPanelProps) => {
    const {
        history,
        params: { organizationId, eventId },
        translate
    } = useHeavent();
    const delegations = props.event?.delegations?.nodes ?? [];
    const segmentsFolders = props.event?.segmentsFolders ?? [];
    const numberOfVrs = props.event?.volunteersRegistrations.totalCount ?? 0;
    const vrs = props.event?.volunteersRegistrations.nodes ?? [];

    return (
        <Flex direction="column" height={1} width={1}>
            <Flex css={{ padding: '$5 $4' }} direction="column" gap="2">
                <BackButton
                    color="primary"
                    returnPathFallback={HeaventPaths.ACCREDITATIONS(organizationId, eventId)}
                >
                    {translate('retour_92202')}
                </BackButton>

                <Flex align="center" gap="2">
                    <Box font="gray900 textLg medium">{translate('affectation_plural')}</Box>

                    <Badge color="error" size="sm">
                        {translate('profil_restant', numberOfVrs)}
                    </Badge>
                </Flex>
            </Flex>

            <Flex css={{ px: '$4' }} gap="4">
                <Box css={{ flex: '1' }}>
                    <TextInput
                        icon="magnifying-glass"
                        placeholder={translate('rechercher_50038')}
                        value={props.name}
                        onChange={props.setName}
                    />
                </Box>

                <Dropdown doNotCloseOnOtherOpen={true}>
                    <Trigger>
                        <Button color="white" leftIcon="bars-filter" />
                    </Trigger>

                    <Menu css={{ padding: '0' }} placement="bottom">
                        <Flex css={{ padding: '$4 $6' }} direction="column" gap="4" width={320}>
                            <Box font="gray800 textMd medium">
                                {translate('liste_des_filtr_75605')}
                            </Box>

                            <Select
                                label={translate('date_d_inscript_31369')}
                                value={props.direction}
                                onChange={props.setDirection}
                            >
                                <option value={SortDirection.Desc}>
                                    {translate('du_plus_r_cent_76468')}
                                </option>
                                <option value={SortDirection.Asc}>
                                    {translate('du_plus_ancien_08999')}
                                </option>
                            </Select>

                            <Flex direction="column" gap="1" width={1}>
                                <Label>{translate('vue_personnalis_97654')}</Label>

                                {props.isLoading ? (
                                    <Skeleton borderRadius="$1" height={40} width={1} />
                                ) : (
                                    <RichSelect
                                        isSearchVisible={true}
                                        multiple={false}
                                        renderOnPortal={true}
                                        values={[props.segmentId]}
                                        onChange={(segmentsIds) => {
                                            if (isNonEmptyArray(segmentsIds)) {
                                                props.setSegmentId(last(segmentsIds)!);
                                            } else {
                                                props.setSegmentId(-1 as SegmentId);
                                            }
                                        }}
                                    >
                                        {segmentsFolders.map((segmentFolder) => (
                                            <optgroup
                                                key={segmentFolder.id}
                                                label={segmentFolder.name}
                                            >
                                                {segmentFolder.segments.map((segment) => (
                                                    <option key={segment.id} value={segment.id}>
                                                        {segment.name}
                                                    </option>
                                                ))}
                                            </optgroup>
                                        ))}
                                    </RichSelect>
                                )}
                            </Flex>

                            {props.showStates && (
                                <RichSelect
                                    isSelectAllVisible={true}
                                    label={translate('status_d_affect_75769')}
                                    multiple={true}
                                    values={props.states}
                                    onChange={props.setStates}
                                >
                                    <option value={VolunteerRegistrationState.WaitingAssignment}>
                                        {translate(VolunteerRegistrationState.WaitingAssignment)}
                                    </option>
                                    <option value={VolunteerRegistrationState.PreAssigned}>
                                        {translate(VolunteerRegistrationState.PreAssigned)}
                                    </option>
                                    <option value={VolunteerRegistrationState.Assigned}>
                                        {translate(VolunteerRegistrationState.Assigned)}
                                    </option>
                                </RichSelect>
                            )}

                            {props.showAccreditationStates && (
                                <RichSelect
                                    isSelectAllVisible={true}
                                    label={translate('statut_d_accr_d_75527')}
                                    multiple={true}
                                    values={props.accreditationStates}
                                    onChange={props.setAccreditationStates}
                                >
                                    <option value={AccreditationState.WaitingAccreditation}>
                                        {translate(AccreditationState.WaitingAccreditation)}
                                    </option>
                                    <option value={AccreditationState.Accredited}>
                                        {translate(AccreditationState.Accredited)}
                                    </option>
                                </RichSelect>
                            )}

                            {props.loadDelegations && (
                                <Flex direction="column" gap="1" width={1}>
                                    <Label>{translate('Delegation')}</Label>

                                    {props.isLoading ? (
                                        <Skeleton borderRadius="$1" height={40} width={1} />
                                    ) : (
                                        <RichSelect
                                            isSearchVisible={true}
                                            multiple={false}
                                            renderOnPortal={true}
                                            values={props.delegationId ? [props.delegationId] : []}
                                            onChange={(delegationsIds) => {
                                                if (isNonEmptyArray(delegationsIds)) {
                                                    props.setDelegationId(last(delegationsIds));
                                                } else {
                                                    props.setDelegationId(null);
                                                }
                                            }}
                                        >
                                            {delegations.map((delegation) => (
                                                <option key={delegation.id} value={delegation.id}>
                                                    {delegation.name}
                                                </option>
                                            ))}
                                        </RichSelect>
                                    )}
                                </Flex>
                            )}
                        </Flex>
                    </Menu>
                </Dropdown>
            </Flex>

            <Spacer height="3" />

            <Flex css={{ flex: '1', overflowY: 'auto' }} direction="column">
                {props.isLoading ? (
                    <>
                        <AccreditAssignWaitingVolunteerSkeleton />
                        <AccreditAssignWaitingVolunteerSkeleton />
                        <AccreditAssignWaitingVolunteerSkeleton />
                    </>
                ) : (
                    vrs.map((vr) => (
                        <AccreditAssignLeftPanelMember
                            key={vr.userInfo.id}
                            displayBorder={true}
                            isSelected={vr.userInfo.id === props.userInfoId}
                            vr={vr}
                            onClick={() => {
                                history.push(props.getPath(vr.userInfo.id));
                            }}
                        />
                    ))
                )}
            </Flex>
        </Flex>
    );
};
