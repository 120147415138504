import { useParams } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';
import { UserSheetMobile } from '../../../users/mobile/show/userSheetMobile';

export const VolunteersSearchUserSheetMobile = () => {
    const { organizationId, eventId, userInfoId } = useParams();
    const basePath = HeaventPaths.VOLUNTEERS_SEARCH_USER(organizationId, eventId, userInfoId);

    return <UserSheetMobile basePath={basePath} />;
};
