import { FullPageHeader } from 'common-front/src/components/mobileFullPage/fullPageHeader';
import { FullPageHeaderText } from 'common-front/src/components/mobileFullPage/fullPageHeaderText';
import { I } from 'common/src/designSystem/components/i';
import { styled } from 'common/src/designSystem/components/stitches';
import { PositionSlotMembersMobileQuery } from 'common/src/generated/types';
import { IntervalService } from 'common/src/services/intervalService';
import { useHistory, useParams, useService } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';
import { useEventContext } from '../../../../events/show/eventContext';
import { slotName } from '../slotName';

const _h1 = styled('h1', {
    color: '$gray900',
    fontSize: '$textMd',
    fontWeight: '$medium'
});

const _i = styled(I, {
    color: '$gray800',
    margin: '0 $3',
    padding: '$3 $4'
});

interface IPositionSlotMembersHeaderMobileProps {
    slot: PositionSlotMembersMobileQuery['event']['positionSlot'];
}

export const PositionSlotMembersHeaderMobile = ({
    slot
}: IPositionSlotMembersHeaderMobileProps) => {
    const { organizationId, eventId, positionId, positionSlotId } = useParams();
    const intervalService = useService(IntervalService);
    const history = useHistory();
    const { isPositionViewer } = useEventContext();
    const canCheckIn = isPositionViewer(slot.positionCategoryId, positionId);

    return (
        <FullPageHeader>
            <FullPageHeaderText css={{ minHeight: '40px', marginRight: '0' }}>
                <_h1>{slotName(slot, intervalService)}</_h1>
            </FullPageHeaderText>
            {canCheckIn && (
                <_i
                    icon="list-check"
                    onClick={() => {
                        history.push(
                            HeaventPaths.POSITION_SLOT_MEMBERS_CHECK_OPTIONS(
                                organizationId,
                                eventId,
                                positionId,
                                positionSlotId
                            )
                        );
                    }}
                />
            )}
        </FullPageHeader>
    );
};
