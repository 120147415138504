import { buildPath } from './commonPaths';
import { OrganizationParams, OrganizationsPaths } from './organizationsPaths';

const slugs = {
    COMMUNITY: 'community',
    SECTIONS: {
        DASHBOARD: 'dashboard',
        USERS: 'users'
    }
};

export type CommunityParams = OrganizationParams;

export const CommunityPaths = {
    //
    // CREATE
    //

    //
    // READ
    //

    COMMUNITY: (params: CommunityParams) =>
        buildPath([OrganizationsPaths.ORGANIZATION(params), slugs.COMMUNITY]),

    // Backoffice space

    COMMUNITY_DASHBOARD: (params: CommunityParams) =>
        buildPath([CommunityPaths.COMMUNITY(params), slugs.SECTIONS.DASHBOARD]),
    COMMUNITY_USERS: (params: CommunityParams) =>
        buildPath([CommunityPaths.COMMUNITY(params), slugs.SECTIONS.USERS])

    //
    // UPDATE
    //

    //
    // DELETE
    //
};
