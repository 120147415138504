import { Button } from 'common-front/src/designSystem/components/button';
import { PaginationCell } from 'common-front/src/designSystem/components/pagination/paginationCell';
import { PaginationRow } from 'common-front/src/designSystem/components/pagination/paginationRow';
import { RightPanel } from 'common-front/src/designSystem/components/rightPanel/rightPanel';
import { RightPanelBody } from 'common-front/src/designSystem/components/rightPanel/rightPanelBody';
import { RightPanelFooter } from 'common-front/src/designSystem/components/rightPanel/rightPanelFooter';
import { RightPanelHeader } from 'common-front/src/designSystem/components/rightPanel/rightPanelHeader';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { usePaginationInfos } from 'common-front/src/hooks/usePaginationInfos';
import { Badge } from 'common/src/designSystem/components/badge';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Skeleton } from 'common/src/designSystem/components/skeleton';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { HeaderCell } from 'common/src/designSystem/components/table/headerCell';
import { HeaderCellSort } from 'common/src/designSystem/components/table/headerCellSort';
import { HeaderRow } from 'common/src/designSystem/components/table/headerRow';
import { RowSkeleton } from 'common/src/designSystem/components/table/rowSkeleton';
import { Table } from 'common/src/designSystem/components/table/table';
import { SortDirection, VolunteersRegistrationsSortAttributes } from 'common/src/generated/types';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import { PositionsPaths } from 'common/src/util/paths/positionsPaths';
import { Sort } from 'common/src/vo/sort';
import * as React from 'react';
import { Route, Switch } from 'react-router';
import { usePositionSlotVolunteersQuery } from '../../../generated/graphqlHooks';
import { UserOverlayEvent } from '../../../users/overlay/userOverlay';
import { PositionPositionSlotVolunteerRow } from './positionPositionSlotVolunteerRow';

export const PositionSlotRightPanel = () => {
    const {
        history,
        translate,
        params: { organizationId, eventId, positionId, positionSlotId }
    } = useHeavent();
    const [offset, setOffset] = React.useState(0);
    const [sort, setSort] = React.useState<Sort<VolunteersRegistrationsSortAttributes> | null>({
        direction: SortDirection.Asc,
        attribute: VolunteersRegistrationsSortAttributes.Name
    });
    const { data, isLoading, reload } = usePositionSlotVolunteersQuery({
        eventId,
        positionSlotId,
        offset,
        sort
    });
    const { numberOfPages, totalCount } = usePaginationInfos(data.event?.volunteersRegistrations);
    const missingResources = React.useMemo(() => {
        if (data.event?.positionSlot) {
            return data.event.positionSlot.resources - data.event.positionSlot.assignedResources;
        } else {
            return 0;
        }
    }, [data.event?.positionSlot]);
    const _setSort = (newSort: Sort<VolunteersRegistrationsSortAttributes> | null) => {
        setOffset(0);
        setSort(newSort);
    };

    return (
        <>
            <RightPanel
                returnToFallback={PositionsPaths.POSITION({ organizationId, eventId, positionId })}
                size="md"
            >
                <RightPanelHeader>
                    <Flex direction="column" gap="1" width={1}>
                        {isLoading ? (
                            <>
                                <Skeleton borderRadius="$1" height={28} width={1} />
                            </>
                        ) : (
                            <Box>{data.event.positionSlot.nameOrRange}</Box>
                        )}
                    </Flex>
                </RightPanelHeader>

                <RightPanelBody>
                    <Spacer height="5" />

                    <Flex align="center" gap="2">
                        <Box>{translate('liste_des_membr_89037')}</Box>

                        {data.event ? (
                            missingResources > 0 ? (
                                <Badge color="warning">
                                    {translate('_1_membre_manq_31014', missingResources)}
                                </Badge>
                            ) : (
                                <Badge color="success">{translate('complet_51600')}</Badge>
                            )
                        ) : null}
                    </Flex>

                    <Spacer height="2" />

                    <Table>
                        <HeaderRow>
                            <HeaderCellSort
                                attribute={VolunteersRegistrationsSortAttributes.Name}
                                setSort={_setSort}
                                sort={sort}
                            >
                                {translate('nom_08311')}
                            </HeaderCellSort>
                            <HeaderCell>{translate('status_06428')}</HeaderCell>
                            <HeaderCell width={100} />
                        </HeaderRow>

                        {isLoading ? (
                            <>
                                <RowSkeleton bx={true} />
                                <RowSkeleton bx={true} />
                                <RowSkeleton bx={true} />
                            </>
                        ) : (
                            data.event.volunteersRegistrations.nodes.map((vr) => (
                                <PositionPositionSlotVolunteerRow
                                    key={vr.id}
                                    reload={reload}
                                    volunteerRegistration={vr}
                                />
                            ))
                        )}

                        <PaginationRow>
                            <PaginationCell
                                numberOfPages={numberOfPages}
                                offset={offset}
                                setOffset={setOffset}
                                totalCount={totalCount}
                            />
                        </PaginationRow>
                    </Table>

                    <Spacer height="5" />
                </RightPanelBody>

                <RightPanelFooter>
                    <Flex gap="3" justify="end">
                        <Button
                            onClick={() => {
                                history.push(
                                    HeaventPaths.POSITION_SLOT_ASSIGN_VOLUNTEERS(
                                        organizationId,
                                        eventId,
                                        positionId,
                                        positionSlotId
                                    )
                                );
                            }}
                        >
                            {translate('affecter_des_me_30349')}
                        </Button>

                        <Button
                            color="white"
                            onClick={() =>
                                history.goBack(
                                    PositionsPaths.POSITION_SLOT({
                                        organizationId,
                                        eventId,
                                        positionId,
                                        positionSlotId
                                    })
                                )
                            }
                        >
                            {translate('fermer_62479')}
                        </Button>
                    </Flex>
                </RightPanelFooter>
            </RightPanel>

            <Switch>
                <Route
                    path={HeaventPaths.POSITION_SLOT_MEMBERS_USER(
                        ':organizationId',
                        ':eventId',
                        ':positionId',
                        ':positionSlotId',
                        ':userInfoId'
                    )}
                >
                    <UserOverlayEvent
                        deletePath={HeaventPaths.POSITION_SLOT_MEMBERS(
                            organizationId,
                            eventId,
                            positionId,
                            positionSlotId
                        )}
                        getBasePath={(userInfoId, isRouteComponent) =>
                            HeaventPaths.POSITION_SLOT_MEMBERS_USER(
                                isRouteComponent ? ':organizationId' : organizationId,
                                isRouteComponent ? ':eventId' : eventId,
                                isRouteComponent ? ':positionId' : positionId,
                                isRouteComponent ? ':positionSlotId' : positionSlotId,
                                userInfoId
                            )
                        }
                        reload={reload}
                    />
                </Route>
            </Switch>
        </>
    );
};
