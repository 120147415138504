import { FullPageContent } from 'common-front/src/components/mobileFullPage/fullPageContent';
import { FullPageHeader } from 'common-front/src/components/mobileFullPage/fullPageHeader';
import { FullPageHeaderText } from 'common-front/src/components/mobileFullPage/fullPageHeaderText';
import { MobileFullPage } from 'common-front/src/components/mobileFullPage/mobileFullPage';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { PositionSlotMembersCheckInListMobile } from './positionSlotMembersCheckInListMobile';

export const PositionSlotMembersCheckInMobile = () => {
    const translate = useTranslate();

    return (
        <MobileFullPage>
            <FullPageHeader>
                <FullPageHeaderText>{translate('pointer_des_mem_09244')}</FullPageHeaderText>
            </FullPageHeader>
            <FullPageContent css={{ margin: '0', overflow: 'auto' }}>
                <PositionSlotMembersCheckInListMobile />
            </FullPageContent>
        </MobileFullPage>
    );
};
