import { Page } from 'common-front/src/components/page/page';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { useTitle } from 'common-front/src/hooks/useTitle';
import { SortDirection } from 'common/src/generated/types';
import * as React from 'react';
import { useEventContext } from '../../events/show/eventContext';
import { useAccreditationsAssignmentsKpisQuery } from '../../generated/graphqlHooks';
import { AccreditationsAssignmentsContent } from './accreditationsAssignmentsContent';
import { AccreditationsAssignmentsHeader } from './accreditationsAssignmentsHeader';

export const AccreditationsAssignments = () => {
    const {
        params: { organizationId, eventId },
        translate
    } = useHeavent();
    const {
        event: { name: eventName },
        localEvent,
        updateLocalEvent
    } = useEventContext();
    useTitle(`${eventName} ${translate('accr_ditations_39450')}`);
    const { data, loader, reload } = useAccreditationsAssignmentsKpisQuery({
        organizationId,
        eventId,
        direction: localEvent?.['accredit.leftPanelDirection'] ?? SortDirection.Desc
    });

    React.useEffect(() => {
        (async () => {
            await Promise.all([
                updateLocalEvent({ 'accredit.filters.accreditationsCategoriesIds': null }),
                updateLocalEvent({ 'accredit.filters.accreditationsIds': null }),
                updateLocalEvent({ 'accredit.filters.date': null }),
                updateLocalEvent({ 'accredit.filters.tagsIds': null }),
                updateLocalEvent({ 'accredit.filters.segmentId': null }),
                updateLocalEvent({ 'accredit.filters.excludeFull': false })
            ]);
        })();
    }, []);

    return (
        loader || (
            <Page.Root>
                <AccreditationsAssignmentsHeader />

                <AccreditationsAssignmentsContent
                    event={data.event}
                    organization={data.organization}
                    reload={reload}
                />
            </Page.Root>
        )
    );
};
