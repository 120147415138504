import { CenteredContainer } from 'common-front/src/components/centeredContainer/centeredContainer';
import { FormWizard, FormWizardPage } from 'common-front/src/components/form/formWizard';
import { FullScreenPopup } from 'common-front/src/components/fullScreenPopup/fullScreenPopup';
import { Portal } from 'common-front/src/components/portal';
import { useVolunteersRegistrationsMassAssignMutation } from 'common-front/src/generated/graphqlHooks';
import { EventId, MassActionSelectedsInput, MassAssignStrategy } from 'common/src/generated/types';
import { IMassAssignValues, MassAssignInputService } from 'common/src/input/massAssignInput';
import { useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { FormRenderProps } from 'react-final-form';
import { MassAssignStep1 } from './massAssignStep1';
import { MassAssignStep2 } from './massAssignStep2';

interface IMassAssignModalProps {
    eventId: EventId;
    numberOfSelected: number;
    selecteds: MassActionSelectedsInput;

    onClose(): void;
    onSuccess(): void;
}

export const MassAssignModal = (props: IMassAssignModalProps) => {
    const translate = useTranslate();
    const massAssignInput = useService(MassAssignInputService);
    const { mutate } = useVolunteersRegistrationsMassAssignMutation();

    return (
        <Portal>
            <FullScreenPopup
                category={translate('affectation')}
                title={translate('affecter_les_me_77160')}
                onClose={props.onClose}
            >
                <CenteredContainer>
                    <FormWizard
                        initialValues={{
                            massAssign: {
                                selecteds: props.selecteds,
                                strategy: MassAssignStrategy.Add,
                                positionsSlotsIds: []
                            }
                        }}
                        isEdit={false}
                        onSubmit={async (values: IMassAssignValues) => {
                            await mutate({
                                eventId: props.eventId,
                                massAssign: values.massAssign
                            });

                            props.onSuccess();
                            props.onClose();
                        }}
                    >
                        <FormWizardPage
                            render={({ handleSubmit }) => (
                                <MassAssignStep1 handleSubmit={handleSubmit} />
                            )}
                            schema={massAssignInput.massAssignStep1Schema()}
                            title={translate('initialisation_38640')}
                        />

                        <FormWizardPage
                            render={(formProps: FormRenderProps<IMassAssignValues>) => (
                                <MassAssignStep2
                                    change={formProps.form.change}
                                    eventId={props.eventId}
                                    handleSubmit={formProps.handleSubmit}
                                    numberOfSelected={props.numberOfSelected}
                                    submitting={formProps.submitting}
                                    values={formProps.values}
                                />
                            )}
                            schema={massAssignInput.massAssignStep2Schema()}
                            title={translate('affectation_plural')}
                        />
                    </FormWizard>
                </CenteredContainer>
            </FullScreenPopup>
        </Portal>
    );
};
