import { Page } from 'common-front/src/components/page/page';
import { Button } from 'common-front/src/designSystem/components/button';
import { MetricCard } from 'common-front/src/designSystem/components/metricCard';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import {
    AccreditationsAssignmentsKpisQuery,
    SendAccreditationsEmailsInput
} from 'common/src/generated/types';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';
import { useEventContext } from '../../events/show/eventContext';
import { AccreditationsAssignmentsEmails } from './accreditationsAssignmentsEmails';
import { SendAccreditationsEmailsConfirmationModal } from './sendAccreditationsEmailsConfirmationModal';
import { SendAccreditationsEmailsModal } from './sendAccreditationsEmailsModal';

interface IAccreditationsAssignmentsContentProps {
    event: AccreditationsAssignmentsKpisQuery['event'];
    organization: AccreditationsAssignmentsKpisQuery['organization'];

    reload(): void;
}

export const AccreditationsAssignmentsContent = (props: IAccreditationsAssignmentsContentProps) => {
    const {
        history,
        params: { organizationId, eventId },
        translate
    } = useHeavent();
    const { setNumberOfWaitingAccreditations } = useEventContext();
    const [isSendEmailsModalOpen, setIsSendEmailsModalOpen] = React.useState(false);
    const [sendAccreditationsEmailsInput, setSendAccreditationsEmailsInput] =
        React.useState<SendAccreditationsEmailsInput | null>(null);
    const [numberOfEmails, setNumberOfEmails] = React.useState(0);
    const [isSendEmailsConfirmationModalOpen, setIsSendEmailsConfirmationModalOpen] =
        React.useState(false);

    React.useEffect(() => {
        setNumberOfWaitingAccreditations(props.event.waitingAccreditation.totalCount);
    }, [props.event]);

    return (
        <>
            <Page.Content>
                <Flex gap="3">
                    <MetricCard
                        button={
                            <Button
                                disabled={props.event.waitingAccreditation.totalCount === 0}
                                onClick={() => {
                                    if (props.event.waitingAccreditation.totalCount > 0) {
                                        history.push(
                                            HeaventPaths.ACCREDIT(
                                                organizationId,
                                                eventId,
                                                props.event.waitingAccreditation.nodes[0].userInfoId
                                            )
                                        );
                                    }
                                }}
                            >
                                {translate('accr_diter_les_48281')}
                            </Button>
                        }
                    >
                        <Box color="gray500" fontWeight="medium">
                            {translate('membres_en_atte_59037')}
                        </Box>

                        <Box font="gray900 displayMd medium">
                            {props.event.waitingAccreditation.totalCount}
                        </Box>
                    </MetricCard>

                    <MetricCard
                        button={
                            <Button
                                onClick={() => {
                                    setIsSendEmailsModalOpen(true);
                                }}
                            >
                                {translate('param_trer_et_e_31095')}
                            </Button>
                        }
                    >
                        <Box color="gray500" fontWeight="medium">
                            {translate('e_mail_de_confi_84099_plural')}
                        </Box>

                        <Box font="gray900 displayMd medium">
                            {props.event.emailsStats.numberOfWaiting}
                        </Box>
                    </MetricCard>
                </Flex>

                <Spacer height="9" />

                <Box font="gray900 textLg medium">{translate('historique_des_71419')}</Box>

                <Spacer height="4" />

                <Flex gap="6">
                    <MetricCard>
                        <Box color="gray500" fontWeight="medium">
                            {translate('nombre_d_envois_95106')}
                        </Box>

                        <Box font="gray900 displayMd medium">
                            {props.event.emailsStats.numberOfSent}
                        </Box>
                    </MetricCard>

                    <MetricCard>
                        <Box color="gray500" fontWeight="medium">
                            {translate('nombre_d_ouvert_84187')}
                        </Box>

                        <Box font="gray900 displayMd medium">
                            {props.event.emailsStats.numberOfOpen}
                        </Box>
                    </MetricCard>

                    <MetricCard>
                        <Box color="gray500" fontWeight="medium">
                            {translate('nombre_de_clics_64232')}
                        </Box>

                        <Box font="gray900 displayMd medium">
                            {props.event.emailsStats.numberOfClick}
                        </Box>
                    </MetricCard>
                </Flex>

                <Spacer height="6" />

                <AccreditationsAssignmentsEmails />
            </Page.Content>

            {isSendEmailsModalOpen && (
                <SendAccreditationsEmailsModal
                    emailSender={props.organization.emailTemplate?.emailSender}
                    eventId={eventId}
                    onClose={() => {
                        setIsSendEmailsModalOpen(false);
                    }}
                    onSuccess={(numberOfEmails, sendAccreditationsEmails) => {
                        setNumberOfEmails(numberOfEmails);
                        setSendAccreditationsEmailsInput(sendAccreditationsEmails);
                        setIsSendEmailsConfirmationModalOpen(true);
                    }}
                />
            )}

            {isSendEmailsConfirmationModalOpen && (
                <SendAccreditationsEmailsConfirmationModal
                    eventId={eventId}
                    numberOfEmails={numberOfEmails}
                    reload={props.reload}
                    sendAccreditationsEmails={sendAccreditationsEmailsInput!}
                    onClose={() => {
                        setIsSendEmailsConfirmationModalOpen(false);
                    }}
                />
            )}
        </>
    );
};
