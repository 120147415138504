import { HeaderCell } from 'common/src/designSystem/components/table/headerCell';
import { HeaderRow } from 'common/src/designSystem/components/table/headerRow';
import { RowSkeleton } from 'common/src/designSystem/components/table/rowSkeleton';
import { Table } from 'common/src/designSystem/components/table/table';
import {
    AccreditationsSlotId,
    DelegationAccreditationSlotMembersQuery,
    DelegationAccreditationSlotStatsFragment,
    MassAssignStrategy,
    UsersInfoId,
    VolunteerRegistrationDelegationAccreditationSlotFragment
} from 'common/src/generated/types';
import { useParams } from 'common/src/util/dependencies/dependencies';
import { isNonEmptyString } from 'common/src/util/string';
import { sortBy } from 'lodash-es';
import * as React from 'react';
import { RightPanel } from '../../designSystem/components/rightPanel/rightPanel';
import { RightPanelBody } from '../../designSystem/components/rightPanel/rightPanelBody';
import { RightPanelHeader } from '../../designSystem/components/rightPanel/rightPanelHeader';
import {
    executeDelegationAccreditationSlotMemberQuery,
    useDelegationAccreditationSlotMembersQuery,
    useVolunteersRegistrationsMassAccreditMutation
} from '../../generated/graphqlHooks';
import { useHeavent } from '../../hooks/useHeavent';
import { getToken } from '../../util/aws/cognito';
import { DelegationAccreditationSlotMember } from './delegationAccreditationSlotMember';
import { DelegationAccreditationSlotStats } from './delegationAccreditationSlotStats';

interface IDelegationAccreditationSlotRightPanelComponentProps {
    accreditationSlotId: AccreditationsSlotId;
    das: DelegationAccreditationSlotMembersQuery['event']['delegation']['accreditationSlot'];
    isWeezeventConnected: boolean;
}

const DelegationAccreditationSlotRightPanelComponent = (
    props: IDelegationAccreditationSlotRightPanelComponentProps
) => {
    const {
        params: { eventId, delegationId },
        translate
    } = useHeavent();
    const { mutate: massAccredit } = useVolunteersRegistrationsMassAccreditMutation();
    const name = React.useMemo(() => {
        const accreditationName = props.das.accreditation.name;
        const slotName = isNonEmptyString(props.das.accreditationSlot.name)
            ? ` - ${props.das.accreditationSlot.name}`
            : '';

        return `${accreditationName}${slotName}`;
    }, [props.das]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [stats, setStats] = React.useState<DelegationAccreditationSlotStatsFragment>(props.das);
    const [members, setMembers] = React.useState<
        VolunteerRegistrationDelegationAccreditationSlotFragment[]
    >(sortBy(props.das.volunteersRegistrations, (vr) => vr.userInfo.name));
    const [idToLoading, setIdToLoading] = React.useState<Record<UsersInfoId, boolean>>({});
    const reloadMember = React.useCallback(
        async (id: UsersInfoId) => {
            setIsLoading(true);
            setIdToLoading((ids) => ({ ...ids, [id]: true }));
            const { event } = await executeDelegationAccreditationSlotMemberQuery(
                {
                    eventId,
                    delegationId,
                    accreditationSlotId: props.accreditationSlotId,
                    userInfoId: id
                },
                await getToken()
            );

            setMembers((currentMembers) =>
                currentMembers.flatMap((member) => {
                    if (member.userInfo.id === id) {
                        const isNewMemberAccredited = event.member.accreditationsUsersInfos.some(
                            (aui) => aui.accreditationSlotId === props.accreditationSlotId
                        );
                        const isNewMemberWished = event.member.accreditationsSlots.some(
                            (as) => as.id === props.accreditationSlotId
                        );

                        return isNewMemberAccredited || isNewMemberWished ? [event.member] : [];
                    } else {
                        return [member];
                    }
                })
            );
            setStats(event.delegation.accreditationSlot);
            setIdToLoading((ids) => ({ ...ids, [id]: false }));
            setIsLoading(false);
        },
        [
            props.accreditationSlotId,
            delegationId,
            eventId,
            setIsLoading,
            setStats,
            setIdToLoading,
            setMembers
        ]
    );
    const addAccreditationSlot = React.useCallback(
        async (userInfoId: UsersInfoId, accreditationSlotId: AccreditationsSlotId) => {
            setIdToLoading((ids) => ({ ...ids, [userInfoId]: true }));

            await massAccredit({
                eventId,
                massAccredit: {
                    accreditationsSlotsIds: [accreditationSlotId],
                    selecteds: {
                        ids: [userInfoId]
                    },
                    strategy: MassAssignStrategy.Add
                }
            });
            await reloadMember(userInfoId);
        },
        [eventId, reloadMember, setIdToLoading]
    );
    const deleteAccreditationSlot = React.useCallback(
        async (userInfoId: UsersInfoId, accreditationSlotId: AccreditationsSlotId) => {
            setIdToLoading((ids) => ({ ...ids, [userInfoId]: true }));

            await massAccredit({
                eventId,
                massAccredit: {
                    accreditationsSlotsIds: [accreditationSlotId],
                    selecteds: {
                        ids: [userInfoId]
                    },
                    strategy: MassAssignStrategy.Delete
                }
            });
            await reloadMember(userInfoId);
        },
        [eventId, reloadMember, setIdToLoading]
    );

    return (
        <>
            <RightPanelHeader>{name}</RightPanelHeader>

            <RightPanelBody
                css={{
                    background: '$gray50',
                    gap: '$6',
                    padding: '$6'
                }}
            >
                <DelegationAccreditationSlotStats
                    isLoading={isLoading}
                    isWeezeventConnected={props.isWeezeventConnected}
                    stats={stats}
                />

                <Table>
                    <HeaderRow>
                        <HeaderCell>{translate('nom_du_membre_69353')}</HeaderCell>
                    </HeaderRow>

                    {members.map((member) => {
                        if (idToLoading[member.userInfo.id]) {
                            return <RowSkeleton key={member.id} bx={true} />;
                        } else {
                            return (
                                <DelegationAccreditationSlotMember
                                    key={member.id}
                                    accreditationSlotId={props.accreditationSlotId}
                                    addAccreditationSlot={addAccreditationSlot}
                                    deleteAccreditationSlot={deleteAccreditationSlot}
                                    member={member}
                                />
                            );
                        }
                    })}
                </Table>
            </RightPanelBody>
        </>
    );
};

interface IDelegationAccreditationSlotRightPanelProps {
    accreditationSlotId: AccreditationsSlotId;

    onClose(): void;
}

export const DelegationAccreditationSlotRightPanel = (
    props: IDelegationAccreditationSlotRightPanelProps
) => {
    const { eventId, delegationId } = useParams();
    const { data, loader, isLoading } = useDelegationAccreditationSlotMembersQuery({
        eventId,
        delegationId,
        accreditationSlotId: props.accreditationSlotId
    });

    return (
        <RightPanel size="md" onClose={props.onClose}>
            {isLoading ? (
                loader
            ) : (
                <DelegationAccreditationSlotRightPanelComponent
                    accreditationSlotId={props.accreditationSlotId}
                    das={data.event.delegation.accreditationSlot}
                    isWeezeventConnected={data.event.isWeezeventConnected}
                />
            )}
        </RightPanel>
    );
};
