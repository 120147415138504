import { DelegationIdPathParam } from '../pathsTypes';
import { buildPath, CommonPaths, commonSlugs, UserParams } from './commonPaths';
import { CommunityPaths } from './communityPaths';
import { EventParams, EventsPaths } from './eventsPaths';
import { FormParams, FormPaths } from './formPaths';
import { OrganizationParams } from './organizationsPaths';

const slugs = {
    ITEMS: {
        DELEGATIONS: {
            PLURAL: 'delegations',
            SINGLE: 'delegation'
        }
    },
    SECTIONS: {
        ACCREDITATIONS: 'accreditations',
        AVAILABILITIES: 'availabilities',
        REPORTING: 'reporting'
    }
} as const;

const itemPaths = {
    DELEGATIONS: slugs.ITEMS.DELEGATIONS.PLURAL,
    DELEGATION: (delegationId: DelegationIdPathParam) =>
        buildPath([slugs.ITEMS.DELEGATIONS.SINGLE, delegationId])
};

export type DelegationParams = OrganizationParams &
    Partial<EventParams> & {
        delegationId: DelegationIdPathParam;
    };

export type DelegationUserParams = UserParams<DelegationParams>;

export type DelegationFormParams = DelegationParams & FormParams;

export const DelegationsPaths = {
    //
    // CREATE
    //

    //
    // READ
    //

    DELEGATIONS: ({ eventId, ...params }: OrganizationParams & Partial<EventParams>) =>
        buildPath([
            eventId ? EventsPaths.EVENT({ ...params, eventId }) : CommunityPaths.COMMUNITY(params),
            itemPaths.DELEGATIONS
        ]),
    DELEGATION: ({ delegationId, eventId, ...params }: DelegationParams) =>
        buildPath([
            eventId ? EventsPaths.EVENT({ ...params, eventId }) : CommunityPaths.COMMUNITY(params),
            itemPaths.DELEGATION(delegationId)
        ]),

    // Backoffice space

    DELEGATION_DASHBOARD: (params: DelegationParams) =>
        buildPath([DelegationsPaths.DELEGATION(params), commonSlugs.SECTIONS.DASHBOARD]),
    DELEGATION_MEMBERS: (params: DelegationParams) =>
        buildPath([DelegationsPaths.DELEGATION(params), commonSlugs.SECTIONS.MEMBERS]),
    DELEGATION_ACCREDITATIONS: (params: DelegationParams) =>
        buildPath([DelegationsPaths.DELEGATION(params), slugs.SECTIONS.ACCREDITATIONS]),
    DELEGATION_REPORTING: (params: DelegationParams) =>
        buildPath([DelegationsPaths.DELEGATION(params), commonSlugs.SECTIONS.REPORTING]),
    DELEGATION_DOCUMENTS: (params: DelegationParams) =>
        buildPath([DelegationsPaths.DELEGATION(params), commonSlugs.SECTIONS.DOCUMENTS]),

    // Member overlay

    DELEGATION_MEMBER: ({ userInfoId, ...params }: UserParams<DelegationParams>) =>
        buildPath([
            DelegationsPaths.DELEGATION_MEMBERS(params),
            commonSlugs.SECTIONS.MEMBER,
            userInfoId
        ]),
    DELEGATION_MEMBER_INFORMATION: (params: UserParams<DelegationParams>) =>
        buildPath([DelegationsPaths.DELEGATION_MEMBER(params), commonSlugs.SECTIONS.INFORMATION]),
    DELEGATION_MEMBER_ACCREDITATIONS: (params: DelegationUserParams) =>
        buildPath([DelegationsPaths.DELEGATION_MEMBER(params), slugs.SECTIONS.ACCREDITATIONS]),
    DELEGATION_MEMBER_AVAILABILITIES: (params: DelegationUserParams) =>
        buildPath([DelegationsPaths.DELEGATION_MEMBER(params), slugs.SECTIONS.AVAILABILITIES]),

    // Delegation space

    MEMBER_CREATE: ({ formId, ...params }: DelegationFormParams) =>
        CommonPaths.CREATE(
            buildPath([
                DelegationsPaths.DELEGATION(params),
                commonSlugs.SECTIONS.MEMBER,
                FormPaths.FORM({ formId })
            ])
        ),
    MEMBER_EDIT: ({ formId, ...params }: DelegationFormParams & DelegationUserParams) =>
        CommonPaths.EDIT(
            buildPath([DelegationsPaths.DELEGATION_MEMBER(params), FormPaths.FORM({ formId })])
        ),
    DELEGATION_PROFILE: (params: DelegationParams) =>
        buildPath([DelegationsPaths.DELEGATION(params), commonSlugs.SECTIONS.PROFILE]),
    FIELDS_EDIT: (params: DelegationParams) =>
        CommonPaths.EDIT(
            buildPath([DelegationsPaths.DELEGATION(params), commonSlugs.ITEMS.FIELDS.PLURAL])
        )

    //
    // UPDATE
    //

    //
    // DELETE
    //
};
