import { Form } from 'common-front/src/components/form/form';
import {
    FieldType,
    FormId,
    TeamCode,
    UpdateUserFormFragment,
    UpdateUserInfosEventQuery,
    UpdateUserInfosOrganizationQuery
} from 'common/src/generated/types';
import { IUpdateUserInfoValues, UserInfoInputService } from 'common/src/input/userInfoInput';
import { useHistory, useParams, useService } from 'common/src/util/dependencies/dependencies';
import { Emptyable } from 'common/src/util/emptyable';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import { MembersPaths } from 'common/src/util/membersPaths';
import { CommunityPaths } from 'common/src/util/paths/communityPaths';
import { DelegationsPaths } from 'common/src/util/paths/delegationsPaths';
import { DateTime } from 'luxon';
import * as React from 'react';
import { useUserInfoUpdateMutation } from '../../generated/graphqlHooks';
import { UpdateUser } from './updateUser';

const TEXT_FIELD_TYPES = [FieldType.Address, FieldType.Text, FieldType.Textarea, FieldType.Time];

interface IUpdateUserFormProps {
    banner?: React.ReactNode;
    customFields: UpdateUserInfosOrganizationQuery['organization']['customFields']['nodes'];
    country: Emptyable<string>;
    formIdToInsertedAt: Record<FormId, DateTime>;
    formIdToTeamCode: Record<FormId, TeamCode>;
    forms: UpdateUserFormFragment[];
    from: 'app' | 'volunteers';
    showAllAndPrivate: boolean;
    showIsFilled: boolean;
    userInfo: Omit<UpdateUserInfosEventQuery['organization']['userInfo'], 'formsUsersInfos'>;

    getEditUserFormPath(formId: FormId): string;
}

export const UpdateUserForm = ({
    banner,
    customFields,
    country,
    formIdToInsertedAt,
    formIdToTeamCode,
    forms,
    from,
    getEditUserFormPath,
    showAllAndPrivate,
    showIsFilled,
    userInfo
}: IUpdateUserFormProps) => {
    const { organizationId, eventId, delegationId } = useParams();
    const history = useHistory();
    const userInfoInput = useService(UserInfoInputService);
    const { mutate: userInfoUpdate, isLoading } = useUserInfoUpdateMutation();
    const filledFormsIds = React.useMemo(
        () => Object.keys(formIdToInsertedAt).map((k) => parseInt(k, 10) as FormId),
        [formIdToInsertedAt]
    );
    const formKeyToTeamCode = React.useMemo(
        () =>
            Object.fromEntries(
                Object.entries(formIdToTeamCode).map(([key, value]) => [`f${key}`, value])
            ),
        [formIdToTeamCode]
    );

    return (
        <Form
            direction="column"
            height={1}
            initialValues={{
                filledFormsIds,
                formKeyToTeamCode,
                userInfo: userInfoInput.userInfoUpdateInputDefault(userInfo, customFields, country)
            }}
            render={({ form, handleSubmit, values }) => (
                <UpdateUser
                    banner={banner}
                    change={form.change}
                    customFields={customFields}
                    formIdToInsertedAt={formIdToInsertedAt}
                    forms={forms}
                    getEditUserFormPath={getEditUserFormPath}
                    handleSubmit={handleSubmit}
                    isLoading={isLoading}
                    returnPathFallback={
                        from === 'app'
                            ? eventId
                                ? HeaventPaths.VOLUNTEERS(organizationId, eventId)
                                : CommunityPaths.COMMUNITY_USERS({ organizationId })
                            : delegationId
                              ? DelegationsPaths.DELEGATION_MEMBERS({
                                    organizationId,
                                    eventId,
                                    delegationId
                                })
                              : MembersPaths.MEMBER_REGISTRATION({
                                    organizationId,
                                    eventId,
                                    userInfoId: userInfo.id
                                })
                    }
                    showAllAndPrivate={showAllAndPrivate}
                    showIsFilled={showIsFilled}
                    userInfo={userInfo}
                    values={values}
                />
            )}
            width={1}
            onSubmit={async (values: IUpdateUserInfoValues) => {
                const fields = values.userInfo.fields;

                // special case for deletion, instead of undefined set it to '' or null
                // this way it will really be deleted
                customFields.forEach((customField) => {
                    if (
                        TEXT_FIELD_TYPES.includes(customField.fieldType) &&
                        !fields[customField.slug]
                    ) {
                        fields[customField.slug] = '';
                    } else if (
                        customField.fieldType === FieldType.File &&
                        !fields[customField.slug]
                    ) {
                        fields[customField.slug] = null;
                    }
                });

                await userInfoUpdate({
                    organizationId,
                    eventId,
                    userId: userInfo.userId,
                    userInfo: {
                        id: userInfo.id,
                        fields: values.userInfo.fields
                    },
                    filledFormsIds: showIsFilled ? values.filledFormsIds : undefined,
                    formKeyToTeamCode: values.formKeyToTeamCode
                });

                history.goBack(
                    from === 'app'
                        ? HeaventPaths.VOLUNTEERS(organizationId, eventId)
                        : MembersPaths.MEMBER_REGISTRATION({
                              organizationId,
                              eventId,
                              userInfoId: userInfo.id
                          })
                );
            }}
        />
    );
};
