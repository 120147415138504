import { API_BASE_URL } from 'common-front/src/consts';
import { Button } from 'common-front/src/designSystem/components/button';
import { ICheckboxState } from 'common-front/src/designSystem/components/checkbox';
import { HorizontalTabs } from 'common-front/src/designSystem/components/horizontalTabs/horizontalTabs';
import { Tab } from 'common-front/src/designSystem/components/horizontalTabs/tab';
import { Label } from 'common-front/src/designSystem/components/input/label';
import { Select } from 'common-front/src/designSystem/components/select/select';
import { TabPanel } from 'common-front/src/designSystem/components/tabs/tabPanel';
import { Tabs } from 'common-front/src/designSystem/components/tabs/tabs';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { useSegmentsContext } from 'common-front/src/segments/segmentsContext';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { EventId, ExportFormat, OrganizationId } from 'common/src/generated/types';
import { Emptyable } from 'common/src/util/emptyable';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import { commonSlugs } from 'common/src/util/paths/commonPaths';
import { CommunityPaths } from 'common/src/util/paths/communityPaths';
import { UUID } from 'common/src/util/uuid';
import * as React from 'react';
import { Key } from '../../api/key';
import { useEventKeyQuery } from '../../generated/graphqlHooks';
import { useNotificationContext } from '../../notifications/notificationContext';
import { useOrganizationContext } from '../../organization/organizationContext';

interface IVolunteersExportOptionsProps {
    basePath: string;
    eventId: Emptyable<EventId>;
    filesState: ICheckboxState;
    isLoading: boolean;
    organizationId: OrganizationId;

    requestExport(format: ExportFormat, includeFiles: boolean, columns: string[]): Promise<UUID>;
}

export const VolunteersExportOptions = ({
    basePath,
    eventId,
    isLoading,
    filesState,
    organizationId,
    requestExport
}: IVolunteersExportOptionsProps) => {
    const { history, translate } = useHeavent();
    const { organization } = useOrganizationContext();
    const {
        data: { event },
        loader
    } = eventId ? useEventKeyQuery({ eventId }) : { data: { event: null }, loader: null };
    const { segmentId, columnsExport, isSaveVisible } = useSegmentsContext();
    const [format, setFormat] = React.useState(ExportFormat.Excel);
    const { checkVolunteersExport } = useNotificationContext();
    const paths = {
        exportBasic: `${basePath}/${commonSlugs.ACTIONS.EXPORT_BASIC}`,
        exportApi: `${basePath}/${commonSlugs.ACTIONS.EXPORT_API}`
    };
    const exportLink = event
        ? `${API_BASE_URL}export/organization/${organizationId}/event/${eventId}/volunteers/${segmentId}?key=${event?.key}`
        : `${API_BASE_URL}export/organization/${organizationId}/members/${segmentId}?key=${organization.key}`;

    return (
        loader || (
            <Tabs
                css={{
                    cursor: 'pointer',
                    marginTop: 'auto'
                }}
            >
                <Box font="gray800 textMd medium">{translate('parameters_de_votre_export')}</Box>

                <Spacer height="2" />

                <HorizontalTabs
                    selectedItem={
                        location.pathname.endsWith('export/basic') ? 'exportBasic' : 'exportApi'
                    }
                    onChange={(item) => {
                        if (item === 'exportBasic') {
                            history.replace(paths.exportBasic);
                        } else {
                            history.replace(paths.exportApi);
                        }
                    }}
                >
                    <Tab item="exportBasic">{translate('export_basique')}</Tab>
                    <Tab item="exportApi">{translate('export_via_lapi')}</Tab>
                </HorizontalTabs>

                <Spacer height="2" />

                <TabPanel path={paths.exportBasic}>
                    <Label>{translate('format_de_lexport')}</Label>

                    <Spacer height="1" />

                    <Flex gap="2">
                        <Flex direction="column" width={1}>
                            <Select
                                css={{
                                    flex: '2',
                                    height: '100%'
                                }}
                                value={format}
                                onChange={(newFormat: string) => {
                                    setFormat(newFormat as ExportFormat);
                                }}
                            >
                                <option value={ExportFormat.Excel}>Excel (.xslx)</option>
                                <option value={ExportFormat.Csv}>CSV (.csv)</option>
                            </Select>
                        </Flex>

                        <Button
                            css={{ width: '35%' }}
                            isLoading={isLoading}
                            textAlign="center"
                            onClick={async () => {
                                const jobId = await requestExport(
                                    format,
                                    filesState === 'checked',
                                    columnsExport
                                );

                                checkVolunteersExport(organizationId, eventId, jobId);

                                history.goBack(
                                    eventId
                                        ? HeaventPaths.VOLUNTEERS(organizationId, eventId)
                                        : CommunityPaths.COMMUNITY_USERS({ organizationId })
                                );
                            }}
                        >
                            {translate('recevoir_l_expo_53392')}
                        </Button>
                    </Flex>
                </TabPanel>

                <TabPanel path={paths.exportApi}>
                    {!isSaveVisible ? (
                        <>
                            <Key
                                buttonColor="primary"
                                css={{
                                    fontWeight: 500
                                }}
                                link={exportLink}
                                title={translate('lien_d_api_pour_98778')}
                            />

                            <Spacer height="6" />

                            <Key
                                buttonColor="primary"
                                css={{
                                    fontWeight: 500
                                }}
                                link={`=IMPORTDATA("${exportLink}")`}
                                title={translate('import_de_la_vu_17175')}
                            />
                        </>
                    ) : (
                        <Box css={{ padding: '$3' }}>{translate('you_must_save_your_view')}</Box>
                    )}
                </TabPanel>
            </Tabs>
        )
    );
};
