import { Checkbox, ICheckboxState } from 'common-front/src/designSystem/components/checkbox';
import { closeOtherDropdowns } from 'common-front/src/designSystem/components/dropdown/dropdown';
import { Content } from 'common-front/src/designSystem/components/tooltip/content';
import { Tooltip } from 'common-front/src/designSystem/components/tooltip/tooltip';
import { Trigger } from 'common-front/src/designSystem/components/tooltip/trigger';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { useSlugToCustomField } from 'common-front/src/hooks/useSlugToCustomField';
import { VolunteerCells } from 'common-front/src/volunteers/list/volunteerCells';
import { VolunteerPanels } from 'common-front/src/volunteers/list/volunteerPanels';
import { Box } from 'common/src/designSystem/components/box';
import { I } from 'common/src/designSystem/components/i';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { CellControls } from 'common/src/designSystem/components/table/cellControls';
import { Row } from 'common/src/designSystem/components/table/row';
import {
    EventId,
    OrganizationId,
    SegmentCustomFieldFragment,
    SegmentId,
    VolunteerRegistrationFragment,
    VolunteersRegistrationId
} from 'common/src/generated/types';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import { PossibleColumn } from 'common/src/vo/segment';
import * as React from 'react';
import { useEventContext } from '../../../events/show/eventContext';
import { VolunteerRowDropdown } from './volunteerRowDropdown';

interface IVolunteerRowProps {
    columns: PossibleColumn[];
    customFields: SegmentCustomFieldFragment[];
    eventId: EventId;
    organizationId: OrganizationId;
    segmentId: SegmentId;
    state: ICheckboxState;
    volunteerRegistration: VolunteerRegistrationFragment;

    reload(): void;
    reloadVolunteer(id: VolunteersRegistrationId): void;
    toggleRow(key: string, newState: ICheckboxState): void;
}

export const VolunteerRow = ({
    columns,
    customFields,
    eventId,
    organizationId,
    reload,
    reloadVolunteer,
    segmentId,
    state: checkBoxState,
    toggleRow,
    volunteerRegistration
}: IVolunteerRowProps) => {
    const { history, translate } = useHeavent();

    const { isEventAdmin } = useEventContext();

    const slugToCustomField = useSlugToCustomField(customFields);

    const reloadVolunteerCallback = React.useCallback(() => {
        reloadVolunteer(volunteerRegistration.id);
    }, [volunteerRegistration, reloadVolunteer]);

    const userInfo = volunteerRegistration.userInfo;
    const accreditationPanelPath = HeaventPaths.VOLUNTEERS_LIST_USER_PANEL_ACCREDITATIONS(
        organizationId,
        eventId,
        segmentId,
        userInfo.id
    );
    const availabilitiesPanelPath = HeaventPaths.VOLUNTEERS_LIST_USER_PANEL_AVAILABILITIES(
        organizationId,
        eventId,
        segmentId,
        userInfo.id
    );

    return (
        <>
            <Row
                css={{
                    background: checkBoxState === 'checked' ? '$primary100' : 'white',
                    cursor: 'pointer',
                    '&:hover': {
                        background: checkBoxState === 'checked' ? '$primary100' : '$gray100'
                    }
                }}
                onClick={() =>
                    history.push(
                        HeaventPaths.VOLUNTEERS_LIST_USER_INFORMATIONS(
                            organizationId,
                            eventId,
                            segmentId,
                            volunteerRegistration.userInfo.id
                        )
                    )
                }
                onMouseLeave={(e) => closeOtherDropdowns(e.target)}
            >
                {isEventAdmin && (
                    <Cell justify="center" width={48}>
                        <Checkbox
                            state={checkBoxState}
                            onClick={(newState, e) => {
                                e.nativeEvent.stopImmediatePropagation();
                                e.stopPropagation();

                                toggleRow(`ui${userInfo.id}`, newState);
                            }}
                        />
                    </Cell>
                )}

                {columns.map((field) => {
                    const customField = slugToCustomField.get(field.slug);

                    return (
                        <VolunteerCells
                            key={field.slug}
                            accreditationPanelPath={accreditationPanelPath}
                            availabilitiesPanelPath={availabilitiesPanelPath}
                            column={field}
                            customField={customField}
                            eventId={eventId}
                            reloadRow={reloadVolunteerCallback}
                            row={volunteerRegistration}
                        />
                    );
                })}

                {isEventAdmin && (
                    <CellControls justify="center">
                        <Tooltip>
                            <Trigger>
                                <Box>
                                    <I
                                        icon="pen"
                                        to={HeaventPaths.EDIT_USER(
                                            organizationId,
                                            eventId,
                                            volunteerRegistration.userInfo.id
                                        )}
                                    />
                                </Box>
                            </Trigger>

                            <Content placement="top">{translate('_diter_62574')}</Content>
                        </Tooltip>

                        <VolunteerRowDropdown
                            eventId={eventId}
                            organizationId={organizationId}
                            reload={reload}
                            volunteerRegistration={volunteerRegistration}
                        />
                    </CellControls>
                )}
            </Row>

            <VolunteerPanels
                accreditationPanelPath={accreditationPanelPath}
                availabilitiesPanelPath={availabilitiesPanelPath}
                canAccredit={isEventAdmin}
                eventId={eventId}
                organizationId={organizationId}
                reloadRow={reloadVolunteerCallback}
                returnPathFallback={HeaventPaths.VOLUNTEERS(organizationId, eventId)}
                showAvailabilitiesEditButton={true}
                volunteerRegistration={volunteerRegistration}
            />
        </>
    );
};
